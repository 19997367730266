import { PersonalizationsType } from "@/pages/smartLandingPage/drawer/types";

export const webmeticPersonalizations: PersonalizationsType = {
  hero: [],
  customers: [],
  features: [],
  stats: [],
  "how-it-works": [],
  benefits: [],
  cta: [],
  testimonials: [],
  faq: [],
  contact: [],
};
