import { forwardRef, useEffect, useRef, useState } from "react";
import { captureException } from "@sentry/react";
import Lottie from "lottie-react";
import feature_animation_black from "@/assets/images/webmetic-feature-animation-black.json";
import feature_animation_white from "@/assets/images/webmetic-feature-animation-white.json";
import { Link } from "react-router-dom";

const steps = [
  {
    step: 1,
    title: "Besucherkontext erkennen",
    description:
      "Automatische Analyse von Branche, Standort und Nutzerverhalten in Echtzeit",
  },
  {
    step: 2,
    title: "Inhalte dynamisch anpassen",
    description:
      "Überschriften, Call-to-Actions und Testimonials ändern sich je nach Besuchertyp",
  },
  {
    step: 3,
    title: "Relevante Erlebnisse ausspielen",
    description:
      "Mehr Engagement durch Inhalte, die perfekt auf den Besucher zugeschnitten sind",
  },
];
export const HowItWorks = forwardRef<HTMLElement | null>((_, ref) => {
  const [personalizationData, setPersonalizationData] = useState<{
    company_logo_url: string;
  } | null>(null);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (dataFetchedRef.current) return; // Prevent multiple fetches
      dataFetchedRef.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const isDebug = urlParams.get("debug") === "true";

      try {
        const endpoint = `https://hub.webmetic.de/wcd${isDebug ? "?debug=true" : ""}`;
        const response = await Promise.race([
          fetch(endpoint),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), 1000),
          ),
        ]);
        const data =
          response instanceof Response ? await response.json() : null;
        if (data) {
          setPersonalizationData(data);
        }
      } catch (error) {
        captureException(error);

        console.error("Error fetching personalization data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="how-it-works" className="mx-auto w-full" ref={ref}>
      <div className="mx-auto grid max-w-[70rem] gap-0 py-8 lg:grid-cols-12 lg:py-20">
        <div className="mr-28 place-self-center lg:col-span-7">
          <h1
            id="how-it-works-title"
            className="mb-1 max-w-2xl text-4xl font-extrabold leading-none tracking-tight"
          >
            In 3 Schritten zur smarten Nutzererfahrung
          </h1>
          <div id="how-it-works-steps">
            {steps.map((step, index) => (
              <div className="flex pt-8" key={index}>
                <div className="mr-5 flex h-9 w-9 shrink-0 items-center justify-center rounded-md bg-energetic-mint-50 text-black">
                  {step.step}
                </div>
                <div>
                  <h3 className="mb-4 text-xl font-extrabold text-neutral-900">
                    {step.title}
                  </h3>
                  <p className="font-normal text-neutral-500">
                    {step.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <Link
            id="how-it-works-button"
            to="/register"
            className="mt-8 inline-flex items-center justify-center rounded-md bg-energetic-mint-500 px-5 py-3 text-center text-base font-medium text-black hover:bg-energetic-mint-400"
            onClick={(e) => {
              e.preventDefault();
              window.open("/register", "_blank", "noopener,noreferrer");
            }}
          >
            Jetzt für Messe-München ausprobieren
          </Link>
        </div>
        <div className="hidden ring-4 ring-platin lg:col-span-5 lg:mt-0 lg:flex">
          <Lottie
            animationData={feature_animation_black}
            loop={true}
            className="dark:hidden"
            style={{ width: "100%", height: "auto" }}
          />
          <Lottie
            animationData={feature_animation_white}
            loop={true}
            className="hidden dark:block"
            style={{ width: "100%", height: "auto" }}
          />
          {personalizationData && personalizationData.company_logo_url && (
            <div className="relative">
              <div className="absolute bottom-[59px] left-1/2 hidden translate-x-1/3 transform items-center lg:flex">
                <div className="relative h-1 w-16 overflow-hidden bg-gradient-to-l from-black to-transparent dark:from-white"></div>
                <div className="flex h-24 w-24 items-center justify-center rounded-lg border-2 border-black bg-white dark:border-gray-500">
                  <div
                    id="company-logo"
                    className="h-20 w-20 flex-shrink-0 rounded bg-[length:90%] bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url('${personalizationData.company_logo_url}')`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});
