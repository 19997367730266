import { apiHandlers } from "@/api";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useSegmentMutationsProps {
  disableHubspotMutation: UseMutateFunction<
    AxiosResponse<unknown, any>,
    Error,
    {},
    unknown
  >;
  isPending: boolean;
}

export const useDisableHubSpotMutation = (): useSegmentMutationsProps => {
  const { mutate: disableHubspotMutation, isPending } = useMutation({
    mutationFn: apiHandlers.integrations.hubspot.disable,
  });

  return {
    disableHubspotMutation,
    isPending,
  };
};
