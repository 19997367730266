import { CompaniesDetailsSession, LocalCompaniesDetails } from "@/types";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import { DateTime } from "luxon";
import { getScoreLevel } from "./utils";

function LeadRating({
  companiesDetails,
  sessions,
}: {
  companiesDetails: LocalCompaniesDetails;
  sessions?: {
    results: CompaniesDetailsSession[];
    pagination: {
      total: number;
    };
    last_activity: string;
    total_interactions: number;
    total_pages_visited: number;
  };
}) {
  // Calculate the number of sessions
  const visits = sessions?.pagination.total;

  // Function to get German relative time
  const getGermanRelativeTime = (timestamp: string) => {
    if (!timestamp) return "N/A";

    return DateTime.fromISO(timestamp)
      .setLocale("de")
      .toRelative({ base: DateTime.now() });
  };

  return (
    <div className="flex items-center justify-between gap-2 rounded-md border border-gray-200 p-4 dark:border-gray-600 dark:bg-gray-700 dark:text-white">
      <div className="flex flex-1 items-center gap-2">
        <div className="w-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            className="dark:text-gray-300"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M29 28.137V9.5a5 5 0 00-10 0v18.637A8.48 8.48 0 0015.5 35a8.5 8.5 0 0017 0 8.48 8.48 0 00-3.5-6.863z"
            ></path>
            <circle cx="24" cy="35" r="3.5" fill="currentColor"></circle>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="3"
              d="M24 33.5L24 17.5"
            ></path>
          </svg>
        </div>
        <div>
          <div className="flex items-center gap-1">
            <p className="text-sm dark:text-gray-400">Lead-Score:</p>
            <Tooltip
              content="Der Lead-Score hängt von der Sitzungsdauer und der Anzahl der Besuche (Session) ab."
              trigger="hover"
              animation="duration-300"
              style="light"
            >
              <svg
                className="h-4 w-4 text-gray-800 dark:text-gray-300"
                aria-hidden="true"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </Tooltip>
          </div>
          <span
            className={clsx("min-h-[16px] text-sm", {
              "text-green-500":
                getScoreLevel(companiesDetails.lead_score) === "high",
              "text-orange-500":
                getScoreLevel(companiesDetails.lead_score) === "medium",
              "text-red-500":
                getScoreLevel(companiesDetails.lead_score) === "low",
            })}
          >
            {companiesDetails.lead_score}%
          </span>
        </div>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm dark:text-gray-400">Besuche:</p>
        </div>
        <span className="text-sm">{visits}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm dark:text-gray-400">Unterseiten:</p>
        </div>
        <span className="text-sm">{sessions?.total_pages_visited}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm dark:text-gray-400">Interaktionen:</p>
        </div>
        <span className="text-sm">{sessions?.total_interactions}</span>
      </div>
      <div className="flex-1">
        <div className="gap-1">
          <p className="text-sm dark:text-gray-400">Letzte Aktivität:</p>
        </div>
        <span className="text-sm">
          {getGermanRelativeTime(sessions?.last_activity ?? "")}
        </span>
      </div>
    </div>
  );
}

export default LeadRating;
