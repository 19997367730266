//import logo from "@/assets/ci/webmetic-logo-white.svg";
import { Badge } from "@/pages/smartLandingPage/drawer/Badge";
import { Context } from "@/pages/smartLandingPage/drawer/Context";
import { Personalizations } from "@/pages/smartLandingPage/drawer/Personalizations";
import { Visitor } from "@/pages/smartLandingPage/visitors";
import { webmeticContext } from "@/pages/smartLandingPage/drawer/webmeticContext";
import { messeMuenchenContext } from "@/pages/smartLandingPage/drawer/messeMuenchenContext";
import { webmeticPersonalizations } from "@/pages/smartLandingPage/drawer/webmeticPersonalizations";
import { messeMuenchenPersonalizations } from "@/pages/smartLandingPage/drawer/messeMuenchenPersonalizations";
import { wuerthContext } from "@/pages/smartLandingPage/drawer/wuerthContext";
import { freelanceContext } from "@/pages/smartLandingPage/drawer/freelanceContext";
import { wuerthPersonalizations } from "@/pages/smartLandingPage/drawer/wuerthPersonalizations";
import { freelancePersonalizations } from "@/pages/smartLandingPage/drawer/freelancePersonalizations";
import { useEffect, useState } from "react";
import { Chevron } from "@/assets/icons";
import { cn } from "@/lib/utils.ts";

const getContextByVisitor = (visitor: Visitor) => {
  switch (visitor.value) {
    case "webmatic":
      return webmeticContext;
    case "messe-muenchen":
      return messeMuenchenContext;
    case "wuerth":
      return wuerthContext;
    case "freelance":
      return freelanceContext;
    default:
      return webmeticContext;
  }
};

const getWebsitePersonalization = (visitor: Visitor) => {
  switch (visitor.value) {
    case "webmatic":
      return webmeticPersonalizations;
    case "messe-muenchen":
      return messeMuenchenPersonalizations;
    case "wuerth":
      return wuerthPersonalizations;
    case "freelance":
      return freelancePersonalizations;
    default:
      return webmeticPersonalizations;
  }
};

export const SLPDrawer = ({
  visitor,
  activeSection,
  openPersonalization = false,
  closedPersonalization,
}: {
  visitor: Visitor;
  activeSection: string | null;
  openPersonalization: boolean;
  closedPersonalization: (value: boolean) => void;
}) => {
  const context = getContextByVisitor(visitor);
  const contextCount = Object.values(context).flat().length;
  const websitePersonalization = getWebsitePersonalization(visitor);
  const websitePersonalizationCount = Object.values(
    websitePersonalization,
  ).flat().length;

  useEffect(() => {
    if (openPersonalization) {
      setActivePanel("personalization");
    }
  }, [openPersonalization]);

  useEffect(() => {
    setActivePanel("context");
    closedPersonalization(true);
  }, [visitor]);

  const [activePanel, setActivePanel] = useState<
    null | "context" | "personalization"
  >(null);

  const togglePanel = (panelIndex: "context" | "personalization") => {
    setActivePanel(activePanel === panelIndex ? null : panelIndex);
    closedPersonalization(true);
  };

  return (
    <aside className="fixed right-1 top-0 h-full w-[340px] max-w-[340px] overflow-y-auto border-l border-stone bg-black">
      <div className="w-full pl-6">
        <h2 className="my-3 max-w-72 py-2.5 text-base font-bold uppercase text-white">
          Personalisierte Änderungen:
        </h2>
      </div>
      <div className="border-y border-stone">
        <h2>
          <button
            type="button"
            className="flex w-full items-center justify-between gap-1 px-4 py-3 text-base font-medium text-white"
            onClick={() => togglePanel("context")}
          >
            <span className="flex-1 text-left">🔍 Erkannter Kontext</span>
            <Chevron orientation={activePanel === "context" ? "up" : "down"} />
            <Badge content={contextCount + ""} className="mr-2" />
          </button>
        </h2>
        <div
          className={cn(
            "duration-400 overflow-hidden transition-[max-height] ease-in-out",
            activePanel === "context" ? "max-h-[10000px]" : "max-h-0",
          )}
        >
          <div className="px-4">
            <Context context={context} />
          </div>
        </div>
        <h2>
          <button
            type="button"
            className="flex w-full items-center justify-between gap-1 border-t border-stone px-4 py-3 text-base font-medium text-white"
            onClick={() => togglePanel("personalization")}
          >
            <span className="flex-1 text-left">
              ⚡ Personalisierungs-Trigger
            </span>
            <Chevron
              orientation={activePanel === "personalization" ? "up" : "down"}
            />
            <Badge
              content={websitePersonalizationCount + ""}
              className="mr-2"
            />
          </button>
        </h2>
        <div
          className={cn(
            "duration-400 overflow-hidden transition-[max-height] ease-in-out",
            activePanel === "personalization" ? "max-h-[10000px]" : "max-h-0",
          )}
        >
          <div className="px-4">
            <Personalizations
              websitePersonalization={websitePersonalization}
              activeSection={activeSection}
            />
          </div>
        </div>
      </div>
    </aside>
  );
};
