import { apiHandlers } from "@/api";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { HUBSPOT_SETTINGS } from "./../../../constants/queryKeys";

export const useHubSpotSettings = (
  enabled: boolean,
): UseQueryResult<any, Error> => {
  return useQuery({
    queryKey: [HUBSPOT_SETTINGS],
    queryFn: () => {
      return apiHandlers.integrations.hubspot.getSettings().then((res) => {
        return res.data;
      });
    },
    gcTime: 0,
    enabled,
  });
};
