export const AdsClick = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7 16.7344C8.1 16.651 6.75 16.0344 5.65 14.8844C4.55 13.7344 4 12.351 4 10.7344C4 9.06771 4.58333 7.65104 5.75 6.48438C6.91667 5.31771 8.33333 4.73438 10 4.73438C11.6167 4.73438 13 5.28438 14.15 6.38438C15.3 7.48438 15.9167 8.83437 16 10.4344L13.9 9.80937C13.6833 8.90938 13.2167 8.17188 12.5 7.59688C11.7833 7.02188 10.95 6.73438 10 6.73438C8.9 6.73438 7.95833 7.12604 7.175 7.90938C6.39167 8.69271 6 9.63437 6 10.7344C6 11.6844 6.2875 12.5177 6.8625 13.2344C7.4375 13.951 8.175 14.4177 9.075 14.6344L9.7 16.7344ZM10.9 20.6844C10.75 20.7177 10.6 20.7344 10.45 20.7344H10C8.61667 20.7344 7.31667 20.4719 6.1 19.9469C4.88333 19.4219 3.825 18.7094 2.925 17.8094C2.025 16.9094 1.3125 15.851 0.7875 14.6344C0.2625 13.4177 0 12.1177 0 10.7344C0 9.35104 0.2625 8.05104 0.7875 6.83437C1.3125 5.61771 2.025 4.55938 2.925 3.65937C3.825 2.75937 4.88333 2.04688 6.1 1.52188C7.31667 0.996875 8.61667 0.734375 10 0.734375C11.3833 0.734375 12.6833 0.996875 13.9 1.52188C15.1167 2.04688 16.175 2.75937 17.075 3.65937C17.975 4.55938 18.6875 5.61771 19.2125 6.83437C19.7375 8.05104 20 9.35104 20 10.7344V11.1844C20 11.3344 19.9833 11.4844 19.95 11.6344L18 11.0344V10.7344C18 8.50104 17.225 6.60938 15.675 5.05938C14.125 3.50938 12.2333 2.73438 10 2.73438C7.76667 2.73438 5.875 3.50938 4.325 5.05938C2.775 6.60938 2 8.50104 2 10.7344C2 12.9677 2.775 14.8594 4.325 16.4094C5.875 17.9594 7.76667 18.7344 10 18.7344H10.3L10.9 20.6844ZM18.525 21.2344L14.25 16.9594L13 20.7344L10 10.7344L20 13.7344L16.225 14.9844L20.5 19.2594L18.525 21.2344Z"
        fill="#00E6A0"
      />
    </svg>
  );
}
