import { verifyMe } from "@/helpers/verify-me.ts";
import { redirect } from "react-router-dom";

export function dashboardLoader() {
  const user = verifyMe()!;
  if (!user) return redirect("/login");
  return null;
}

export function authenticatedLoader() {
  const user = verifyMe()!;
  if (user) return redirect("/dashboard");
  return null;
}
