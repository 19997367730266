import { CompanyDetailsContext } from "@/contexts";
import { useCompanySessions } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import { captureException, setContext } from "@sentry/react";
import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CompaniesContent from "./company-content";
import CompanySessions from "./company-sessions";
import LeadRating from "./lead-ratings";

interface CompanyDetailsProps {
  company_id: string;
  from_date?: string;
  to_date?: string;
}

export const CompanyDetailsComponent = ({
  company_id,
  from_date,
  to_date,
}: CompanyDetailsProps) => {
  const { selectedCompany, queryId } = useContext(CompanyDetailsContext);
  const params = useParams();
  const { page, handleChangePage } = usePagination();

  const { data: sessions, isLoading } = useCompanySessions(
    {
      company_id: selectedCompany?.company_id,
      domain: params.domain ?? "",
      page,
      query_id: queryId,
    },
    true,
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!sessions || !sessions.results.length) {
      const timeout = setTimeout(() => {
        captureException(new Error("No sessions found."));
        setContext("searchDetails", {
          company_id,
          query_id: queryId,
          domain: params.domain ?? "",
        });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [sessions]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [company_id, from_date, to_date]);

  useEffect(() => {
    handleChangePage(1);
  }, [selectedCompany]);

  return (
    <div ref={containerRef} className="h-full w-full dark:bg-gray-900">
      {selectedCompany && (
        <div className="flex h-full w-full flex-col gap-4 border-l bg-white px-4 py-2 dark:border-gray-600 dark:bg-gray-900">
          <CompaniesContent companiesDetails={selectedCompany} />
          <LeadRating companiesDetails={selectedCompany} sessions={sessions} />
          <CompanySessions
            page={page}
            handleChangePage={handleChangePage}
            sessions={sessions}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};
