import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";

const Footer = () => {
  return (
    <footer className="mt-auto border-t border-gray-200 bg-white dark:border-0 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="mb-4 text-center text-sm text-gray-500 dark:text-gray-400 sm:mb-0">
            © {new Date().getFullYear()} by Webmetic GmbH
          </p>
          <div className="flex flex-wrap items-center justify-center gap-4 px-4 text-sm sm:text-base">
            <a
              href="https://hub.webmetic.de/docs"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block"
            >
              <span className="transition-colors duration-300 dark:text-gray-300">
                API
              </span>
              <span className="absolute bottom-0 left-0 h-0.5 w-0 bg-current group-hover:animate-underline-grow dark:bg-gray-300"></span>
            </a>
            <a
              href="https://t.webmetic.de/opt-out/"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-block"
            >
              <span className="transition-colors duration-300 dark:text-gray-300">
                Opt-Out
              </span>
              <span className="absolute bottom-0 left-0 h-0.5 w-0 bg-current group-hover:animate-underline-grow dark:bg-gray-300"></span>
            </a>
            <Link
              to="/datenschutzerklaerung"
              className="group relative inline-block"
            >
              <span className="transition-colors duration-300 dark:text-gray-300">
                Datenschutzerklärung
              </span>
              <span className="absolute bottom-0 left-0 h-0.5 w-0 bg-current group-hover:animate-underline-grow dark:bg-gray-300"></span>
            </Link>
            <Link to="/agb" className="group relative inline-block">
              <span className="transition-colors duration-300 dark:text-gray-300">
                AGB
              </span>
              <span className="absolute bottom-0 left-0 h-0.5 w-0 bg-current group-hover:animate-underline-grow dark:bg-gray-300"></span>
            </Link>
            <Link to="/impressum" className="group relative inline-block">
              <span className="transition-colors duration-300 dark:text-gray-300">
                Impressum
              </span>
              <span className="absolute bottom-0 left-0 h-0.5 w-0 bg-current group-hover:animate-underline-grow dark:bg-gray-300"></span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
