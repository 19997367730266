import { CompanyDetailsContext, IntegrationsContext } from "@/contexts";
import { useLinkCompanyLexwareMutation, useLinkCompanyMutation } from "@/hooks";
import { LocalCompaniesDetails } from "@/types";
import { captureException } from "@sentry/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";

const updateSelectedCompanyIntegration = (
  selectedCompany: any,
  integrationKey: string,
  syncEnabled: boolean,
  setSelectedCompany: Function,
) => {
  if (!selectedCompany) return;

  const updatedCompany = {
    ...selectedCompany,
    integrations: {
      ...selectedCompany.integrations,
      [integrationKey]: {
        sync_enabled: syncEnabled,
      },
    },
  };

  setSelectedCompany(updatedCompany);
};

export const useCompanyLinking = ({
  companiesDetails,
}: {
  companiesDetails: LocalCompaniesDetails;
}) => {
  const { isHubSpotEnabled, isLexWareEnabled } =
    useContext(IntegrationsContext);
  const { linkCompanyMutation } = useLinkCompanyMutation();
  const { linkCompanyLexWareMutation } = useLinkCompanyLexwareMutation();
  const { selectedCompany, setSelectedCompany } = useContext(
    CompanyDetailsContext,
  );
  const params = useParams();

  const handleSyncCompany = async () => {
    try {
      await linkCompanyMutation({
        domain: params.domain!,
        company_id: companiesDetails.company_id,
        enable: true,
      });
      updateSelectedCompanyIntegration(
        selectedCompany,
        "hubspot",
        true,
        setSelectedCompany,
      );
    } catch (error) {
      captureException(error);
      console.log("Error syncing company:", error);
    }
  };

  const handleLexwareSyncCompany = async () => {
    try {
      await linkCompanyLexWareMutation({
        domain: params.domain!,
        company_id: companiesDetails.company_id,
        enable: true,
      });
      updateSelectedCompanyIntegration(
        selectedCompany,
        "lexware",
        true,
        setSelectedCompany,
      );
    } catch (error) {
      captureException(error);
      console.log("Error syncing company:", error);
    }
  };

  const handleUnlinkCompany = async () => {
    try {
      await linkCompanyMutation({
        domain: params.domain!,
        company_id: companiesDetails.company_id,
        sync_enabled: false,
        enable: false,
      });
      updateSelectedCompanyIntegration(
        selectedCompany,
        "hubspot",
        false,
        setSelectedCompany,
      );
    } catch (error) {
      captureException(error);
      console.log("Error unlinking company:", error);
    }
  };

  const handleUnlinkLexwareCompany = async () => {
    try {
      await linkCompanyLexWareMutation({
        domain: params.domain!,
        company_id: companiesDetails.company_id,
        sync_enabled: false,
        enable: false,
      });
      updateSelectedCompanyIntegration(
        selectedCompany,
        "lexware",
        false,
        setSelectedCompany,
      );
    } catch (error) {
      captureException(error);
      console.log("Error unlinking company:", error);
    }
  };

  return {
    isHubSpotEnabled,
    isLexWareEnabled,
    handleSyncCompany,
    handleUnlinkCompany,
    handleLexwareSyncCompany,
    handleUnlinkLexwareCompany,
  };
};
