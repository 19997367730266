import React from "react";
import { Modal, Button } from "flowbite-react";

const SuccessModal = ({ show, onClose }) => (
  <Modal show={show} size="md" popup onClose={onClose}>
    <Modal.Header />
    <Modal.Body>
      <div className="text-center">
        <div className="mb-4 flex justify-center">
          <div className="dark:bg-grey-900 flex h-24 w-24 items-center justify-center rounded-full bg-grey-100 p-2">
            <svg
              className="h-20 w-20 text-success dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <h3 className="h6-style mb-3">Konto wurde erfolgreich angelegt.</h3>
        <p className="mb-5 text-sm dark:text-white">
          <strong>Sie erhalten jetzt zwei E-Mails:</strong>
          <br />
          1. Ihr Passwort zum Dashboard
          <br />
          2. Anleitung zur Integration von Webmetic
        </p>
        <div className="flex justify-center">
          <button className="btn" onClick={onClose}>
            Fenster schließen
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export default SuccessModal;
