import { useSegmentMutations } from "@/hooks";
import { SEGMENTS_LIST } from "@/constants";
import { useQueryClient } from "@tanstack/react-query";
import { Segment } from "@/types/segment";
import analytics from "@/analytics";

export const useSegmentManagement = () => {
  const queryClient = useQueryClient();
  const { createSegmentMutation, updateSegmentMutation } =
    useSegmentMutations();

  const updateSegmentList = () => {
    queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
  };

  const handleSaveSegment = async (
    values: any,
    isEditMode: boolean,
    segmentToEdit: Segment | null,
  ) => {
    if (isEditMode && segmentToEdit) {
      return updateSegmentMutation(
        { id: segmentToEdit.id, data: values },
        {
          onSuccess: () => {
            updateSegmentList();

            // Track segment update
            analytics.track("segment_changed", {
              segment_id: segmentToEdit.id,
              segment_label: values.label || segmentToEdit.label,
              previous_query_complexity: segmentToEdit.conditions?.length || 0,
              new_query_complexity: values.conditions?.length || 0,
              // Removed company count fields to simplify
            });
          },
        },
      );
    }
    return createSegmentMutation(values, {
      onSuccess: () => {
        updateSegmentList();

        // Track segment creation
        analytics.track("segment_created", {
          segment_label: values.label || "",
          query_complexity: values.conditions?.length || 0,
          condition_count: values.conditions?.length || 0,
          // Removed company count field to simplify
          target_domain: values.domain || "",
        });
      },
    });
  };

  return { handleSaveSegment };
};
