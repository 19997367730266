export const ChevronSort = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.99979 15.7564L0.57666 11.3333L1.17124 10.7387L4.99979 14.5352L8.82833 10.7387L9.42291 11.3333L4.99979 15.7564ZM1.17604 5.25645L0.57666 4.66666L4.99979 0.24353L9.42291 4.66666L8.82354 5.25645L4.99979 1.46478L1.17604 5.25645Z" fill="white"/>
    </svg>

  )
}
