import { useEffect, useRef, useState } from "react";

export const useClickOutside = (
  buttonRef?: React.RefObject<HTMLButtonElement> | null,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef) {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node) &&
          buttonRef?.current &&
          !buttonRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }

        return;
      }

      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return {
    isOpen,
    setIsOpen,
    containerRef,
  };
};
