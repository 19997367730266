import { BlockProps } from "@/components/content/Blocks/types.ts"
import { Images296X411, ImagesWithHeadingAndDescription } from "@/types"
import { RichText } from "@/components/content"
import { getCmsImageUrl } from "@/utils/cms.ts"

// Flowbite block https://flowbite.com/blocks/marketing/contact/#contact-section-with-address-location

export const ImagesWithHeadingAndDescriptionBlock = ({ block }: BlockProps) => {
  const blockFields: ImagesWithHeadingAndDescription =
    block as ImagesWithHeadingAndDescription
  const image1: Images296X411 = blockFields.image1 as Images296X411
  const image2: Images296X411 = blockFields.image2 as Images296X411

  return (
    <section className="bg-platin dark:bg-graphite">
      <div className="mx-auto max-w-screen-xl items-center gap-16 px-4 py-8 lg:grid lg:grid-cols-2 lg:px-6 lg:py-16">
        <div className="font-light text-black dark:text-white sm:text-lg">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-black dark:text-white">
            {blockFields.title}
          </h2>
          <RichText root={blockFields.text.root} />
        </div>
        <div className="mt-8 grid grid-cols-2 gap-4">
          {image1?.sizes?.card && (
            <img
              className="w-full rounded-lg"
              src={getCmsImageUrl(image1.sizes.card.url)}
              alt={image1.alt ?? ""}
            />
          )}
          {image2?.sizes?.card && (
            <img
              className="mt-4 w-full rounded-lg lg:mt-10"
              src={getCmsImageUrl(image2.sizes.card.url)}
              alt={image2.alt ?? ""}
            />
          )}
        </div>
      </div>
    </section>
  )
}
