import arrow from "../../assets/icons/arrow-down.svg";

interface PaginationProps {
  totalPages?: number;
  selectedPage?: number;
  onPageSelect?: (page: number) => void;
}

export const Pagination = ({
  totalPages = 1,
  selectedPage = 1,
  onPageSelect,
}: PaginationProps) => {
  if (!onPageSelect) return null;

  const handleNext = () => {
    if (selectedPage < totalPages) {
      onPageSelect(selectedPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePrev = () => {
    if (selectedPage > 1) {
      onPageSelect(selectedPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const getPaginationRange = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (selectedPage <= 3) {
      pages.push(1, 2, 3, "...", totalPages);
    } else if (selectedPage >= totalPages - 2) {
      pages.push(1, "...", totalPages - 2, totalPages - 1, totalPages);
    } else {
      pages.push(
        1,
        "...",
        selectedPage - 1,
        selectedPage,
        selectedPage + 1,
        "...",
        totalPages,
      );
    }

    return pages;
  };

  return (
    <div className="mt-4 flex justify-center">
      <div className="flex items-center gap-3">
        <button
          className="rounded-[8px] px-[15px] py-[5px]"
          disabled={selectedPage === 1}
          onClick={handlePrev}
        >
          <img
            src={arrow}
            alt="arrow"
            width={20}
            height={20}
            className="rotate-90"
          />
        </button>
        <div className="flex items-center gap-2">
          {getPaginationRange().map((item, index) => (
            <button
              key={index}
              className={`${
                selectedPage === item
                  ? "bg-webmetic_dark text-white dark:bg-webmetic_light"
                  : ""
              } h-[25px] w-[25px] rounded-full text-[13px] transition-all hover:bg-webmetic_light hover:text-gray-800 dark:hover:bg-webmetic_dark`}
              onClick={() => typeof item === "number" && onPageSelect(item)}
              disabled={item === "..."}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          className="rounded-[8px] px-[15px] py-[5px]"
          disabled={selectedPage === totalPages}
          onClick={handleNext}
        >
          <img
            src={arrow}
            alt="arrow"
            width={20}
            height={20}
            className="rotate-[-90deg]"
          />
        </button>
      </div>
    </div>
  );
};
