import { SendIcon } from "@/pages/smartLandingPage/webmetic/icons/SendIcon";
import { FormEvent, forwardRef, useState } from "react";
import axios from "axios";
import { InfoModal } from "@/components/layouts/Modals/InfoModal.tsx";

export const Contact = forwardRef<HTMLElement | null>((_, ref) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyName, setCompanyName] = useState("Freelance.de");
  const [email, setEmail] = useState("@freelance.de");
  const [contactPerson, setContactPerson] = useState("");
  const [website, setWebsite] = useState("https://freelance.de");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const API_KEY = import.meta.env.internal_api_key;
  const successMessage = "Nachricht erfolgreich gesendet!";
  const errorMessage =
    "Es gab einen Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut.";

  const handleSubmit = async (e: FormEvent) => {
    console.log('AAAAAAAAAAAAAAA');
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Push event to data layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "contact_form",
        formType: "contact",
      });

      // Prepare the request body
      const senderCompany = `Firmenname: ${companyName}`;
      const senderEmail = `E-Mail: ${email}`;
      const senderContactPerson = `Ansprechpartner: ${contactPerson}`;
      const senderWebsite = `Website: ${website}`;
      const requestBody = {
        mail: {
          to_email: "ms@webmetic.de",
        },
        content: {
          subject: "Dynamische Landing Page - Kontaktformular",
          content: [
            senderCompany,
            senderEmail,
            senderContactPerson,
            senderWebsite,
          ].join("<br />"),
        },
      };

      // Send request to your FastAPI endpoint
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/email/send-generic`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": API_KEY,
          },
        },
      );

      if (response.status === 200) {
        setModalMessage(successMessage);
        setShowModal(true);
        setCompanyName("");
        setEmail("");
        setContactPerson("");
        setWebsite("");
      } else {
        setModalMessage(errorMessage);
        setShowModal(true);
      }
    } catch (error) {
      setModalMessage(errorMessage);
      setShowModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="w-full bg-platin" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] px-4 py-8 lg:py-16">
        <h2
          id="contact-title"
          className="mb-4 text-center text-4xl font-extrabold tracking-tight text-gray-900"
        >
          Erleben Sie Webmetic in Ihrer persönlichen Live-Demo
        </h2>
        <p
          id="contact-text"
          className="mb-8 text-center font-normal text-black sm:text-lg"
        >
          Vereinbaren Sie jetzt einen Termin - wir freuen uns auf Sie!
        </p>
        <form
          onSubmit={handleSubmit}
          className="mx-auto max-w-[51.25rem] space-y-8"
        >
            <div id="form-company" className="w-full">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-black"
              >
                Firmenname:
              </label>
              <input
                type="text"
                id="name"
                value={companyName}
                className="block w-full rounded-lg border border-stone bg-white p-2.5 text-sm text-black shadow-sm focus:border-energetic-mint-500 focus:ring-energetic-mint-500"
                placeholder="e.g. John Dowry"
                required
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          <div id="form-contact-person">
            <label
              htmlFor="contact-person"
              className="mb-2 block text-sm font-medium text-black"
            >
              Ansprechpartner:
            </label>
            <input
              type="text"
              id="contact-person"
              className="block w-full rounded-lg border border-stone bg-white p-3 text-sm text-black shadow-sm focus:border-energetic-mint-500 focus:ring-energetic-mint-500"
              placeholder="Erika Mustermann"
              required
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
            />
          </div>
            <div id="form-email" className="w-full">
              <label
                htmlFor="email"
                className="mb-2 block text-sm font-medium text-black"
              >
                E-Mail:
              </label>
              <input
                type="email"
                id="email"
                className="block w-full rounded-lg border border-stone bg-white p-2.5 text-sm text-black shadow-sm focus:border-energetic-mint-500 focus:ring-energetic-mint-500"
                placeholder="e.g. name@mail.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          <div id="form-website">
            <label
              htmlFor="website"
              className="mb-2 block text-sm font-medium text-black"
            >
              Website:
            </label>
            <input
              type="text"
              id="website"
              className="block w-full rounded-lg border border-stone bg-white p-3 text-sm text-black shadow-sm focus:border-energetic-mint-500 focus:ring-energetic-mint-500"
              placeholder="e.g. Company XYZ"
              required
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="flex items-center justify-center gap-1.5 rounded-lg bg-energetic-mint-500 px-5 py-3 text-center text-sm font-medium text-black hover:bg-energetic-mint-400 sm:w-fit"
          >
            {isSubmitting ? "Wird gesendet..." : "ABSENDEN"}
            <SendIcon />
          </button>
        </form>
      </div>
      <InfoModal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
      />
    </section>
  );
});
