import { PersonalizationsType } from "@/pages/smartLandingPage/drawer/types";

export const freelancePersonalizations: PersonalizationsType = {
  hero: [
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Mehr Umsätze - dank KI-gestützter smart Landing Pages",
      new: "Freelancer und Projekte matchen - effizient, smart und treffsicher",
      element: "hero-title",
    },
    {
      category: [
        "Firmenname kurz → freelance.de",
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Ihre Besucher erwarten relevante Inhalte - und sehen doch immer dieselbe generische Seite.",
      new: "Stellen Sie sicher, dass freelance.de die erste Adresse ist, sowohl für Freelancer als auch für Unternehmen, die Projekte zu vergeben haben.",
      element: "hero-text1",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Personalisierte Landing Pages berücksichtigen Branche, Standort und Verhalten Ihrer Besucher, und sorgen so für mehr Interaktion und höheren Umsatz.",
      new: "Mit personalisierten Landing Pages, die sich dynamisch an Branche, Standort und Erfahrung anpassen.\n\nFür mehr Registrierungen und bessere Matches.",
      element: "hero-text2",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      new: "Freelancer bei der Arbeit",
      element: "hero-image",
    },
  ],
  customers: [
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      new: "Freelance-Logos (Upwork, Fiverr, Wework etc.)",
      element: "customer-logos",
    },
  ],
  features: [
    {
      category: ["Sprach-Stil → Fachlich/technisch, Direkt"],
      old: "Wie statische Landing Pages Ihrem Unternehmen schaden",
      new: "Wie statische Landing Pages Ihr Wachstum bremsen",
      element: "features-title",
    },
    {
      category: [
        "Hauptbranche → Freiberufler- und Projektplattform",
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Generisch konvertiert nicht\nIhre Interessenten kommen aus unterschiedlichen Ländern und Branchen und haben ganz individuelle Bedürfnisse - die eine einzige Landing Page gar nicht abdecken kann.\nResultat: Wenig Relevanz, hohe Absprungraten und verlorene Neukunden",
      new: "Unspezifische Projektanzeigen schrecken ab\nEin IT-Experte auf Senior-Level erhält dieselben Projektangebote wie ein Junior-Grafikdesigner - und muss sich erst mühsam passende Projekte suchen.\nResultat:\n\nSpezialisten finden nicht die richtigen Angebote und springen ab\nRecruiter erhalten unpassende Bewerbungen → längere Besetzungszeiten\nHochqualifizierte Freelancer wechseln zu Plattformen mit gezielteren Angeboten",
      element: "feature1",
    },
    {
      category: [
        "Firmenname kurz → freelance.de",
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Potenzial bleibt ungenutzt\nJeder Klick kostet Ihr Unternehmen bares Geld, aber Ihre Conversion-Rates bleiben stets im niedrigen einstelligen Bereich.\nResultat: Ohne zielgerichtetes Messaging bleibt Ihr Marketing-ROI weit unter seinen Möglichkeiten",
      new: "Marketing-Budget wird ineffizient genutzt\nFreelance.de investiert in Google Ads, LinkedIn-Kampagnen und Newsletter, um Freelancer und Unternehmen zu gewinnen.\n\nDoch wenn alle auf dieselbe Landingpage geleitet werden, geht Potenzial verloren.\nResultat:\n\nWerbekosten steigen, während die Conversion-Rate niedrig bleibt\nUnternehmen finden keine relevanten Freelancer und verlassen die Plattform\nWichtige Daten über Freelancer-Interessen werden nicht genutzt",
      element: "feature2",
    },
    {
      category: [
        "Hauptbranche → Freiberufler- und Projektplattform",
        "Schlüsselbegriffe → Freelancer-Plattform, Projektausschreibung, Expertennetzwerk",
      ],
      old: "Die Konkurrenz freut sich\nDie personalisierte Web-Erfahrung wird zum Standard - wenn Sie stehen bleiben, zieht die Konkurrenz dynamisch an Ihnen vorbei.\nResultat: Liefern Sie Nutzern keine maßgeschneiderten Inhalte, verlieren Sie Ihre potenziellen Kunden",
      new: "Sie verpassen wertvolle Vermittlungen\nDie Vielfalt an Projekten, Branchen und Freelancern kann ohne smartes Konzept zur Herausforderung statt zur Chance werden.\nResultat:\n\nUnternehmen müssen mühsam durch irrelevante Freelancer-Profile klicken → weniger Vermittlungen\nHochqualifizierte Freelancer finden keine passenden Projekte und wechseln zur Konkurrenz\nWettbewerber mit besseren Filteroptionen gewinnen mehr Nutzer",
      element: "feature3",
    },
  ],
  "how-it-works": [
    {
      category: ["Firmenname kurz → freelance.de"],
      old: "Jetzt ausprobieren",
      new: "Jetzt für Freelance.de ausprobieren",
      element: "how-it-works-button",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Analysieren\nWebmetic identifiziert Branche und Standort der Firma und wertet das Nutzerverhalten in Echtzeit aus",
      new: "Freelancer & Unternehmen verstehen\nIdentifikation von Skills, Branchen & Erfahrungen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Anpassen\nJe nach Besucher ändern sich Headlines, CTAs und beliebige weitere Inhalte automatisch",
      new: "Inhalte dynamisch anpassen\nPersonalisierte Projektangebote & Freelancer-Profile",
      element: "how-it-works-steps",
    },
    {
      category: ["USP → Effizientes Matching"],
      old: "Akquirieren\nMehr Relevanz führt zu mehr Engagement bei Ihren potenziellen Neukunden",
      new: "Relevante Matches anzeigen\nKI-gestützte Empfehlungen für optimale Vermittlung",
      element: "how-it-works-steps",
    },
  ],
  benefits: [
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Entfesseln Sie das Potenzial Ihrer Website mit individualisiertem Content",
      new: "Mehr Matches - schnellere Vermittlungen",
      element: "benefits-title",
    },
    {
      category: [
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Passender Content für jeden Besucher\nJe nach Branche, Interesse und Standort erwarten den User maßgeschneiderte Inhalte, er fühlt sich gesehen und verstanden.\nHöheres Engagement und niedrigere Absprungrate",
      new: "Höhere Relevanz\nJeder Freelancer sieht nur die Projekte, die zu seinen Skills und Erfahrungen passen\nGezieltere, schnellere Vermittlungen",
      element: "benefit1",
    },
    {
      category: ["USP → Effizientes Matching"],
      old: "Gesteigerte Conversion-Rates\nCTAs, Angebote und Bilder passen sich automatisch den Nutzerbedürfnissen an und überzeugen durch Relevanz.\nGesteigerte Anmeldungen und Conversion-Rates",
      new: "Effizientes Matching\nMehr qualifizierte Bewerbungen erhöhen die Trefferchancen.\nWeniger Abmeldungen und höhere Kundenzufriedenheit",
      element: "benefit2",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Sinnvolles Retargeting und Upsells\nSprechen Sie Bestandskunden gezielt mit Content an, der ganz auf ihre Vorlieben zugeschnitten ist.\nStärkere Kundenbindung & höhere Verkaufszahlen",
      new: "Langfristige Erfolge\nEffizientes Matching führt zu erfolgreichen Projekten und wiederkehrenden Aufträgen.\nStärkere Kundenbindung und Plattform-Wachstum",
      element: "benefit3",
    },
    {
      category: ["Sprach-Stil → Fachlich/technisch, Direkt"],
      old: "Automatisierte, smarte Optimierung\nLassen Sie das Nutzerverhalten in Echtzeit analysieren und den Inhalt Ihrer Landing Pages dafür perfektionieren.\nErfolgsversprechende Entscheidungen und nachhaltiges Wachstum",
      new: "Zielführendes Reporting\nEchtzeit-Analysen zeigen, welche Angebote und Freelancer am besten performen.\nBessere Entscheidungen und höhere Vermittlungsraten",
      element: "benefit4",
    },
  ],
  cta: [
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Personalisierung, von der Ihre Konkurrenz nur träumen kann",
      new: "Erleben Sie die Power personalisierter Freelancer-Vermittlung",
      element: "cta-title",
    },
    {
      category: [
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Branchenrelevante News\n– Ihr Besucher sieht Content, der für seine Industrie aktuell und wichtig ist",
      new: "Branchenbezogene Inhalte\nFreelancer und Firmen sehen nur relevante Projekte und Kandidaten",
      element: "cta-feature1",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Verhaltensbasierte Anpassungen\n– Überschriften, CTAs und Bilder",
      new: "Verhaltensbasierte Anpassung\nDynamische Jobvorschläge und personalisierte Ansprache",
      element: "cta-feature2",
    },
    {
      category: ["USP → Effizientes Matching"],
      old: "Dynamischer Social Proof\n– Ihr Unternehmen glänzt mit Rezensionen, Studien und Erfolgsstorys",
      new: "Bessere Vermittlungsquoten\nWeniger Absprünge, schnellere Bewerbungsprozesse",
      element: "cta-feature3",
    },
    {
      category: ["Tonalität → Kompetent, Partnerschaftlich, Vertrauenswürdig"],
      old: "KI-gestützte Empfehlungen\n– Ihre Web-Inhalte werden ausgewertet und Sie erhalten Optimierungsvorschläge",
      new: "Datengestützte Optimierung\nEchtzeit-Analysen für maximale Effizienz",
      element: "cta-feature4",
    },
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      new: "Coworking Space mit Freelancern",
      element: "cta-image",
    },
  ],
  stats: [],
  faq: [],
  testimonials: [
    {
      category: ["Hauptbranche → Freiberufler- und Projektplattform"],
      old: "Mit Webmetic konnten wir unsere Vertriebspipeline deutlich optimieren. Die Identifikation relevanter Unternehmen ermöglicht es uns, gezielter auf potenzielle Kunden zuzugehen und die Abschlussrate signifikant zu steigern.",
      new: "Dank Webmetic erhalten wir nun viel gezieltere Bewerbungen. Unsere Vermittlungen laufen schneller, und die Qualität der Matches ist deutlich gestiegen.",
      element: "testimonials-testimonial",
    },
    {
      category: ["Land → Deutschland"],
      old: "Susan Wright, Referenzkunde",
      new: "Julia Maier, Head of Talent Acquisition - Upwork Deutschland",
      element: "testimonial-author",
    },
    {
      category: ["Tonalität → Kompetent, Partnerschaftlich, Vertrauenswürdig"],
      new: "Professionelle Person im Freelancer-Kontext",
      element: "testimonial-image",
    },
  ],
  contact: [
    {
      category: ["Firmenname kurz → freelance.de"],
      new: "Freelance.de",
      element: "form-company",
      segment: "ctas_forms",
    },
    {
      category: ["E-Mail → support@freelance.de"],
      new: "@freelance.de",
      element: "form-email",
      segment: "ctas_forms",
    },
    {
      category: "Website → freelance.de",
      new: "https://freelance.de",
      element: "form-website",
    },
  ],
  other: [
    {
      category: [
        "Sprach-Stil → Fachlich/technisch, Direkt",
        "Primäre Zielgruppe → Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
      ],
      old: "Steigern Sie Ihre Website-Performance mit intelligenten Landing Pages",
      new: "Optimieren Sie Ihre Freelancer-Plattform mit datenbasierter Personalisierung.",
    },
    {
      category: [
        "Tonalität → Kompetent, Partnerschaftlich, Vertrauenswürdig",
        "Hauptbranche → Freiberufler- und Projektplattform",
      ],
      old: "Wir helfen Ihnen, mehr aus Ihrer Website herauszuholen",
      new: "Steigern Sie Ihre Vermittlungsquote durch passgenaues Matching und personalisierte Projektangebote.",
    },
    {
      category: ["Anrede → Formell"],
      old: "Buch dir deine Demo",
      new: "Vereinbaren Sie Ihre persönliche Demo",
    },
  ],
};
