import {
  AdsClick,
  CurrencyExchange,
  AccountCircle,
  SignalCellularAlt,
} from "@/pages/smartLandingPage/webmetic/benefit_icons";
import { ArrowRight } from "@/assets/icons";
import { forwardRef } from "react";

const benefits = [
  {
    icon: AdsClick,
    title: "Passender Content für jeden Besucher",
    description:
      "Je nach Branche, Interesse und Standort erwarten den User maßgeschneiderte Inhalte, er fühlt sich gesehen und verstanden.",
    result: "Höheres Engagement und niedrigere Absprungrate",
  },
  {
    icon: CurrencyExchange,
    title: "Gesteigerte Conversion-Rates",
    description:
      "CTAs, Angebote und Bilder passen sich automatisch den Nutzerbedürfnissen an und überzeugen durch Relevanz.",
    result: "Gesteigerte Anmeldungen und Conversion-Rates",
  },
  {
    icon: SignalCellularAlt,
    title: "Sinnvolles Retargeting und Upsells",
    description:
      "Sprechen Sie Bestandskunden gezielt mit Content an, der ganz auf ihre Vorlieben zugeschnitten ist.",
    result: "Stärkere Kundenbindung & höhere Verkaufszahlen",
  },
  {
    icon: AccountCircle,
    title: "Automatisierte, smarte Optimierung",
    description:
      "Lassen Sie das Nutzerverhalten in Echtzeit analysieren und den Inhalt Ihrer Landing Pages dafür perfektionieren.",
    result: "Erfolgsversprechende Entscheidungen und nachhaltiges Wachstum",
  },
];
export const Benefits = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="benefits" className="bg-pulse text-white" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-left sm:py-16">
        <p id="benefits-intro" className="mb-6 text-lg text-energetic-mint-500">
          IHRE VORTEILE
        </p>
        <h2
          id="benefits-title"
          className="mb-6 max-w-3xl text-4xl font-semibold tracking-tight text-white"
        >
          Entfesseln Sie das Potenzial Ihrer Website mit individualisiertem
          Content
        </h2>
        <p id="benefits-text" className="mb-12">
          Überzeugend, vom ersten Klick an: Dynamische Landing Pages holen Ihre
          Besucher mit individueller Ansprache und relevanten Inhalte ab. Und
          steigern so Engagement und Interaktionsrate Ihrer Interessenten.
        </p>
        <div
          id="benefits-list"
          className="mb-6 md:grid md:grid-cols-2 md:gap-5 lg:flex lg:flex-nowrap"
        >
          {benefits.map((benefit, index) => (
            <div
              className="flex flex-col gap-5 rounded-md bg-graphite p-8 text-left text-white lg:min-w-[27%]"
              key={index}
              id={`benefit${index + 1}`}
            >
              <benefit.icon></benefit.icon>
              <h3 className="text-xl font-semibold">{benefit.title}</h3>
              <p className="pr-4 text-sm font-normal">{benefit.description}</p>
              <p className="text-sm font-bold">
                <ArrowRight className="ml-0 mr-1 inline-block h-4 w-4" />
                {benefit.result}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});
