import { ContextsType } from "@/pages/smartLandingPage/drawer/types";

export const freelanceContext: ContextsType = {
  unternehmensbasis: [
    { type: "Firmenname", data: "freelance.de GmbH" },
    { type: "Firmenname kurz", data: "freelance.de" },
    { type: "Mitarbeiteranzahl", data: "100-250" },
  ],
  kontakt_standort: [
    { type: "Adresse", data: "Nymphenburger Str. 70" },
    { type: "PLZ", data: "80335" },
    { type: "Stadt", data: "München" },
    { type: "Bundesland", data: "Bayern" },
    { type: "Land", data: "Deutschland" },
    { type: "Telefon", data: "+498921537822" },
    { type: "Fax", data: "+498921537823" },
    { type: "E-Mail", data: "support@freelance.de" },
    { type: "Website", data: "freelance.de" },
  ],
  management_rechtliches: [
    { type: "Geschäftsführer", data: "Robin Gollbach, Simon Gravel" },
    { type: "USt-IdNr.", data: "DE249514681" },
    { type: "Steuernummer", data: "143/172/50513" },
    { type: "Registernummer", data: "HRB 163159" },
    { type: "Registergericht", data: "Amtsgericht München" },
  ],
  geschaeftstaetigkeit: [
    { type: "Hauptbranche", data: "Freiberufler- und Projektplattform" },
    { type: "NACE-Codes", data: "63.12, 78.10, 82.99" },
    {
      type: "Kurzbeschreibung",
      data: "Deutschlands größte Plattform für Freelancer und Projektanbieter. Vermittelt hochqualifizierte Experten für anspruchsvolle Projekte und bietet digitale Matching-Lösungen für effiziente Projektbesetzung.",
    },
  ],
  online_praesenz: [
    { type: "Facebook", data: "https://facebook.com/freelance.de" },
    { type: "Instagram", data: "https://instagram.com/freelancede" },
    { type: "LinkedIn", data: "https://linkedin.com/company/freelance-de" },
    { type: "XING", data: "https://xing.com/pages/freelance-de" },
    { type: "Twitter", data: "https://twitter.com/FreelanceDE" },
  ],
  marke_kommunikation: [
    {
      type: "Sprach-Stil",
      data: "Fachlich/technisch, Direkt",
    },
    {
      type: "Tonalität",
      data: "Kompetent, Partnerschaftlich, Vertrauenswürdig",
    },
    { type: "Anrede", data: "Formell" },
    { type: "Brand-Konsistenz", data: "Hoch" },
    { type: "Content-Dichte", data: "Ausgewogen" },
  ],
  zielgruppen_marketing: [
    {
      type: "Primäre Zielgruppe",
      data: "Hochqualifizierte Freelancer und Unternehmen mit Projektbedarf im DACH-Raum",
    },
    {
      type: "Sekundäre Zielgruppe",
      data: "Personaldienstleister und Agenturen mit Expertensuche",
    },
    {
      type: "Schlüsselbegriffe",
      data: "Freelancer-Plattform, Projektausschreibung, Expertennetzwerk, Remote-Arbeit, IT-Projekte, faire Honorare",
    },
    {
      type: "Call-to-Actions",
      data: "Projekt ausschreiben, Freelancer finden, Kostenlos registrieren, Jetzt bewerben",
    },
    {
      type: "USP",
      data: "Größtes Freelancer-Netzwerk im DACH-Raum, Effizientes Matching, Transparente Gebührenstruktur",
    },
    {
      type: "Logo",
      data: '<img src="https://webmetic.de/brand/freelance_de/freelance_de_dark_logo.png" alt="freelance.de Logo">',
      html: true,
    },
  ],
  nutzerdaten: [
    { type: "Nutzerstatus", data: "aktiv" },
    { type: "Besuchsanzahl", data: "27" },
    { type: "Letzter Besuch", data: "2025-03-25" },
    {
      type: "Besuchte Unterseiten",
      data: "Projekte, FAQ",
    },
    {
      type: "Interessensgebiete",
      data: "IT-Entwicklung",
    },
    { type: "Interaktionsrate", data: "sehr hoch" },
    { type: "Konversionsphase", data: "Entscheidungsphase" },
    { type: "Letzte Interaktion", data: "Projektausschreibung" },
    {
      type: "Nutzer-Präferenzen",
      data: "DE, Light Mode, Push-Benachrichtigungen",
    },
  ],
};
