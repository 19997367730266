import { useEffect, useState } from "react";
import { cn } from "@/lib/utils.ts";
import { driver } from "driver.js";
import {
  PersonalizationsType,
  PersonalizationType,
} from "@/pages/smartLandingPage/drawer/types";

const PersonalizationCard = ({
  item,
  onClick,
}: {
  item: PersonalizationType;
  onClick: () => void;
}) => {
  let categories: string[] = [];
  if (Array.isArray(item.category)) {
    categories = item.category;
  } else {
    categories.push(item.category);
  }

  return (
    <div
      className="relative cursor-pointer rounded-lg bg-graphite p-2 transition-all duration-300 hover:border-platin hover:border-opacity-50 hover:bg-opacity-70 hover:shadow-lg"
      onClick={onClick}
      style={{ border: "1px solid transparent" }}
    >
      <div className="flex flex-wrap gap-2">
        {categories.map((category, idx) => (
          <span
            key={idx}
            className="rounded-lg bg-platin px-2.5 py-1.5 text-xs font-normal text-black transition-colors hover:bg-platin/90"
          >
            {category}
          </span>
        ))}
      </div>

      {(item.old || item.new) && (
        <div className="rounded-md p-2">
          {item.old && (
            <div className="mb-2">
              <div className="mb-1 text-xs font-medium text-stone">Vorher:</div>
              <div
                className="text-sm font-medium text-white line-through opacity-70"
                dangerouslySetInnerHTML={{ __html: item.old }}
              />
              {/*{item.old}*/}
              {/*</div>*/}
            </div>
          )}

          {item.new && (
            <div>
              <div className="mb-1 text-xs font-medium text-white">
                Nachher:
              </div>
              <div
                className="text-sm font-medium text-white"
                dangerouslySetInnerHTML={{ __html: item.new }}
              />
              {/*{item.new}</div>*/}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const Personalizations = ({
  websitePersonalization,
  activeSection,
}: {
  websitePersonalization: PersonalizationsType;
  activeSection: string | null;
}) => {
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [selectedPersonalizations, setSelectedPersonalizations] = useState<
    PersonalizationType[]
  >([]);

  useEffect(() => {
    setIsChanging(true);

    const timer = setTimeout(() => {
      const activePersonalizations: PersonalizationType[] =
        activeSection && activeSection in websitePersonalization
          ? (websitePersonalization[
              activeSection as keyof PersonalizationsType
            ] ?? [])
          : [];
      setSelectedPersonalizations(activePersonalizations);
      setIsChanging(false);
    }, 200); // matches the animation duration

    return () => clearTimeout(timer);
  }, [activeSection, websitePersonalization]);

  const highlight = (id: string) => {
    if (id === "") return;
    const driverObj = driver();
    driverObj.highlight({
      element: "#" + id,
    });
  };

  return (
    <section>
      <div
        className={cn(
          "transition-opacity duration-200",
          isChanging ? "opacity-0" : "opacity-100",
        )}
      >
        <div className="grid grid-cols-1 gap-4">
          {selectedPersonalizations &&
            selectedPersonalizations.map((item, index) => (
              <PersonalizationCard
                key={index}
                item={item}
                onClick={() => highlight(item.element || "")}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Personalizations;
