import {
  HubSpotMessageResponse,
  SettingsHubSpotProps,
  apiHandlers,
} from "@/api";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type HubSpotSettingsMutationsReturn = {
  createSettingMutation: UseMutationResult<
    AxiosResponse<HubSpotMessageResponse>,
    Error,
    SettingsHubSpotProps,
    unknown
  >;
};

export const useHubSpotSettingsMutation =
  (): HubSpotSettingsMutationsReturn => {
    const mutation = useMutation<
      AxiosResponse<HubSpotMessageResponse>,
      Error,
      SettingsHubSpotProps
    >({
      mutationFn: apiHandlers.integrations.hubspot.settings,
    });

    return {
      createSettingMutation: mutation,
    };
  };
