import clsx from "clsx";
import { CheckIcon, ChevronDown, ChevronUp, SearchIcon } from "lucide-react";
import { ChangeEvent, useRef, useState } from "react";
import { SortPanel } from "./components/sort-panel/sort-panel";
import { useClickOutside } from "@/hooks";

type SearchInputProps = {
  isSegmentSearch: boolean;
  categoryList: { value: string; label: string }[];
  searchValue: string;
  onChangeSearch: (val: string) => void;
  dropDownValue?: string;
  onChangeDropdownValue: (val: string) => void;
  wrapClasses?: string;
  queryId?: string;
};

export const SearchInput = ({
  isSegmentSearch,
  categoryList,
  onChangeSearch,
  searchValue,
  dropDownValue,
  onChangeDropdownValue,
  wrapClasses,
  queryId,
}: SearchInputProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { containerRef, isOpen, setIsOpen } = useClickOutside(buttonRef);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  };

  const onSelectDropdown = (val: string) => {
    onChangeDropdownValue(val);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    if (isSegmentSearch) return;

    setIsOpen(!isOpen);
  };

  const getLabelByValue = (value?: string) => {
    const item = categoryList.find((entry) => entry.value === value);
    return item ? item.label : "All categories";
  };

  return (
    <div className={clsx("flex flex-col gap-2", wrapClasses)}>
      <div className="flex h-[40px]">
        <button
          id="dropdown-button"
          data-dropdown-toggle="dropdown"
          className={clsx(
            "z-10 flex w-[110px] flex-shrink-0 items-center justify-between rounded-s-md border border-gray-300 bg-gray-100 px-2 py-2 text-center text-sm text-gray-900 hover:bg-gray-200 focus:border-b-green_medium focus:border-l-green_medium focus:border-t-green_medium focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600",
            isOpen && "bg-gray-200 dark:bg-gray-600",
          )}
          type="button"
          onClick={toggleDropdown}
          disabled={isSegmentSearch}
          ref={buttonRef}
        >
          {isSegmentSearch ? "Segmentsuche" : getLabelByValue(dropDownValue)}
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>
        {isOpen ? (
          <div
            id="dropdown"
            className={`absolute top-[42px] z-10 w-[110px] divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700`}
            ref={containerRef}
          >
            <ul
              className="gap-1 rounded-md border p-1.5 text-sm text-gray-700 dark:border-gray-600 dark:text-gray-200"
              aria-labelledby="dropdown-button"
            >
              {categoryList.map(({ value, label }) => {
                const isSelected = getLabelByValue(dropDownValue) === label;
                return (
                  <li>
                    <button
                      type="button"
                      className="inline-flex w-full items-center gap-2 rounded-sm py-[7px] transition-all duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white"
                      onClick={() => onSelectDropdown(value)}
                    >
                      {isSelected && <CheckIcon height={15} />}
                      <span className={clsx(!isSelected && "ml-8")}>
                        {label}
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        <div className="relative w-full">
          <input
            type="search"
            id="search-dropdown"
            className="focus:shadow-ring-none block h-full w-full rounded-r-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 outline-none focus:border-gray-300 focus:border-b-green_medium focus:border-t-green_medium focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 focus:dark:border-gray-600 focus:dark:border-b-green-700 focus:dark:border-t-green-700 sm:text-sm"
            placeholder="Suche..."
            value={searchValue}
            onChange={onSearch}
          />
          <button
            type="submit"
            className="absolute end-0 top-0 h-full rounded-e-md border bg-webmetic_dark p-2.5 text-sm font-medium text-white transition duration-300 ease-in-out hover:bg-webmetic_light hover:text-gray-800 focus:outline-none focus:ring-1 dark:border-gray-600 dark:bg-webmetic_light dark:hover:bg-webmetic_dark"
          >
            <SearchIcon height={20} className="dark:text-white" />
            <span className="sr-only">Suche</span>
          </button>
        </div>
        <button
          onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          className="ml-2 transition-all duration-300 ease-in-out dark:text-white"
        >
          {isFiltersOpen ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
      {isFiltersOpen && <SortPanel queryId={queryId} />}
    </div>
  );
};
