import { FC } from "react"
import { RichTextNode } from "@/components/content/RichText/types.ts"
import { RichTextElement } from "@/components/content/RichText/RichTextElement.tsx"

interface RTChildrenProps {
  children: RichTextNode[] | undefined,
  className?: string,
}

export const RTChildren: FC<RTChildrenProps> = ({children, className = ''}: RTChildrenProps) => {
  if (!children) return null
  return (
    <>
      {children &&
        children.map((child, i) =>
          <RichTextElement key={i} node={child} className={className} />
        )}
    </>
  )
}
