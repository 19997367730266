import { HUBSPOT_SETTINGS } from "@/constants";
import { useAuth } from "@/provider/authProvider";
import { useQueryClient } from "@tanstack/react-query";
import { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetHubspotAuthLinkQuery } from ".";
import { useDisableHubSpotMutation } from "./api/mutations/useDisableHubSpotMutation";
import { useHubSpotSettingsMutation } from "./api/mutations/useHubspotSettingsMutation";
import { useHubSpotSettings } from "./api/queries/useHubspotSettings";
import analytics from "@/analytics";

interface SettingsOptions {}

export const useSettings = (): SettingsOptions => {
  const { isAuthenticated } = useAuth();

  const { data: settings } = useHubSpotSettings(isAuthenticated);

  const { createSettingMutation } = useHubSpotSettingsMutation();
  const { disableHubspotMutation } = useDisableHubSpotMutation();
  const { data: authLink } = useGetHubspotAuthLinkQuery(isAuthenticated);

  const handleClick = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (authLink) {
      e.preventDefault();
      window.location.href = authLink.auth_url;
    }
  };

  const { setValue, handleSubmit, register } = useForm();

  const [successfulSave, setSuccessfulSave] = useState(false);

  const queryClient = useQueryClient();

  const onSubmitSettings = (data: {
    recognized: boolean;
    pageVisits: boolean;
  }) => {
    createSettingMutation.mutate(
      {
        sync_to_existing: data.recognized,
        page_visit_sync: data.pageVisits,
      },
      {
        onSuccess: () => {
          setSuccessfulSave(true);
          
          // If this is the first time setting up, track as an integration added
          if (!settings?.enabled) {
            analytics.track("integration_hubspot_added", {
              page_visit_sync: data.pageVisits,
              sync_to_existing: data.recognized,
              expires_in_days: 365 // Default expiration, adjust if there's an actual value
            });
          }
        },
        onError: (error) => {
          console.log("ERROR", error);
        },
      },
    );
  };

  const disconnectHubSpot = () => {
    // Calculate days active
    const createdAt = settings?.created_at ? new Date(settings.created_at) : new Date();
    const currentDate = new Date();
    const daysActive = Math.floor((currentDate.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24));
    
    disableHubspotMutation(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [HUBSPOT_SETTINGS] });
          
          // Track HubSpot integration removal
          analytics.track("integration_hubspot_removed", {
            removal_date: new Date().toISOString(),
            removal_reason: "user_initiated",
            days_active: daysActive
          });
        },
        onError: (e) => {
          console.log("ERROR disconnecting", e);
        },
      },
    );
  };

  useEffect(() => {
    if (settings) {
      setValue("all", settings?.automatic_sync);
      setValue("recognized", settings?.sync_to_existing);
      setValue("pageVisits", settings?.page_visit_sync);
    }
  }, [settings, setValue]);

  useEffect(() => {
    if (!successfulSave) return;

    const timer = setTimeout(() => {
      setSuccessfulSave(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [successfulSave]);

  return {
    settings,
    handleClick,
    onSubmitSettings,
    disconnectHubSpot,
    register,
    setValue,
    handleSubmit,
    successfulSave,
    isPending: createSettingMutation.isPending,
    isError: createSettingMutation.isError,
  };
};
