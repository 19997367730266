import customer1 from "./customer_logos/customer1.png";
import customer2 from "./customer_logos/customer2.png";
import customer3 from "./customer_logos/customer3.png";
import customer4 from "./customer_logos/customer4.png";
import customer5 from "./customer_logos/customer5.png";
import customer6 from "./customer_logos/customer6.png";
import customer7 from "./customer_logos/customer7.png";
import customer8 from "./customer_logos/customer8.png";
import customer9 from "./customer_logos/customer9.png";
import { forwardRef } from "react";

export const Customers = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section className="mx-auto w-full max-w-[70rem]" id="customers" ref={ref}>
      <div className="mx-auto py-8 lg:py-16">
        <h2
          id="customers-title"
          className="mb-8 text-center text-3xl font-bold leading-tight tracking-tight text-neutral-900"
        >
          Über 15 Messeveranstalter setzen bereits auf Webmetic
        </h2>
        <div id="customer-logos" className="flex flex-wrap justify-center gap-x-10 gap-y-1">
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer1}
              alt="customer1"
              className="h-[100px] w-[178px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer2}
              alt="customer2"
              className="h-[60px] w-[231px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer3}
              alt="customer3"
              className="h-[60px] w-[138px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer4}
              alt="customer4"
              className="h-[60px] w-[154px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer5}
              alt="customer5"
              className="h-[91px] w-[182px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer6}
              alt="customer6"
              className="h-[56px] w-[276px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer7}
              alt="customer7"
              className="h-[90px] w-[180px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer8}
              alt="customer8"
              className="h-[68px] w-[243px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer9}
              alt="customer9"
              className="h-[90px] w-[145px]"
            />
          </a>
        </div>
        <p className="text-center text-sm">
          (Musterdarstellung von Referenzen)
        </p>
      </div>
    </section>
  );
});
