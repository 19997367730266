import { CheckIcon } from "@/pages/smartLandingPage/webmetic/icons/CheckIcon";
import image from "./images/cta.png";
import { forwardRef, useCallback } from "react";
import analytics from "@/analytics";

const features = [
  {
    title: "Branchenbezogene Inhalte ",
    description:
      "Freelancer und Firmen sehen nur relevante Projekte und Kandidaten",
  },
  {
    title: "Verhaltensbasierte Anpassung",
    description: "Dynamische Jobvorschläge und personalisierte Ansprache",
  },
  {
    title: "Bessere Vermittlungsquoten",
    description: "Weniger Absprünge, schnellere Bewerbungsprozesse",
  },
  {
    title: "Datengestützte Optimierung",
    description: "Echtzeit-Analysen für maximale Effizienz",
  },
];

export const CTA = forwardRef<HTMLElement | null>((_, ref) => {
  // Track demo button click
  const trackDemoClicked = useCallback(() => {
    analytics.track("smartpage_demo_clicked", {
      source: "freelance_cta",
    });
  }, []);

  return (
    <section id="cta" className="mx-auto w-full max-w-[70rem]" ref={ref}>
      <div className="mx-auto max-w-screen-xl items-center gap-10 px-4 py-8 lg:flex lg:p-16">
        <div className="text-black sm:text-lg">
          <h2
            id="cta-title"
            className="lg:p-r-4 mb-4 text-3xl font-extrabold tracking-tight text-black md:text-4xl"
          >
            Erleben Sie die Power personalisierter Freelancer-Vermittlung
          </h2>
          <p className="mb-4 font-normal" id="cta-text">
            Optimieren Sie Ihre Plattform mit dynamischen, auf Ihre Nutzer
            abgestimmten Inhalten – für mehr Vermittlungen, zufriedenere Kunden
            und langfristige Partnerschaften.
          </p>
          <div
            id="cta-features"
            className="grid gap-4 pb-8 sm:grid-cols-2 lg:grid-cols-1"
          >
            {features.map((feature, index) => (
              <div className="flex" key={index} id={`cta-feature${index + 1}`}>
                <div className="mr-2 shrink-0 pt-2">
                  <CheckIcon />
                </div>
                <div>
                  <p className="font-extrabold tracking-tight text-black">
                    {feature.title}
                  </p>
                  <p className="font-normal text-black">
                    {feature.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <a
            href="https://calendly.com/webmetic/kurze-besprechung"
            target="_blank"
            className="mr-3 inline-flex items-center justify-center rounded-md bg-energetic-mint-500 px-5 py-3 text-center text-base font-medium text-black hover:bg-energetic-mint-400"
            onClick={trackDemoClicked}
          >
            Demo vereinbaren
          </a>
        </div>
        <img
          id="cta-image"
          className="mx-auto mb-4 hidden sm:flex"
          src={image}
          alt="table professor illustration"
        />
      </div>
    </section>
  );
});
