import React, { useState, useEffect } from "react";
import {
  Drawer,
  Typography,
  IconButton,
  Textarea,
} from "@material-tailwind/react";
import { Clipboard } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDisableLexwareMutation,
  useLexWareAuthQuery,
  useLexWareSettings,
} from "@/hooks";
import { LexWareWelcome } from "@/pages/integrations/lexware/welcome";

import cross from "../../assets/icons/cross.svg";
import { LEXWARE_SETTINGS } from "@/constants";
import { LexWareSettings } from "@/pages/integrations/lexware/settings";

function LexWareDrawer({ open, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: authLink, isSuccess } = useLexWareAuthQuery();
  const { data: lexWareSettings } = useLexWareSettings(true);

  const handleClick = (e) => {
    if (authLink) {
      e.preventDefault();
      window.location.href = authLink.auth_url;
    }
  };

  const handleClose = () => {
    searchParams.delete("lexware");

    onClose();

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg dark:border-l-gray-600 dark:bg-gray-800 sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60 ",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-b-gray-600">
        <div className="flex flex-col gap-1">
          <Typography
            variant="h4"
            color="blue-gray"
            className="dark:text-green-400"
          >
            {lexWareSettings?.enabled ? "Lexware-Einstellung" : "Lexware"}
          </Typography>
        </div>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>

      {!lexWareSettings?.enabled ? (
        <LexWareWelcome handleClick={handleClick} />
      ) : (
        <LexWareSettings
          lexWareSettings={lexWareSettings}
          handleClick={handleClick}
        />
      )}
    </Drawer>
  );
}

export default LexWareDrawer;
