export interface Visitor {
  value: string;
  label: string;
}

export const visitors: Visitor[] = [
  { value: "webmetic", label: "Standard" },
  { value: "messe-muenchen", label: "Messe München" },
  { value: "wuerth", label: "Würth" },
  { value: "freelance", label: "Freelance.de" },
  // { value: 'kuka', label: 'www.kuka.de' },
  // { value: 'shopware', label: 'www.shopware.com/de' },
];
