import { SEGMENTS_LIST } from "@/constants";
import {
  useGetSegmentsListQuery,
  useSyncLexwareSegmentMutation,
  useSyncSegmentMutation,
} from "@/hooks";
import { integrationList } from "@/utils";
import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export enum Integrations {
  HUBSPOT = "hubspot",
  LEXWARE = "lexware",
}

export const useSegmentLinking = () => {
  const params = useParams();
  const queryClient = useQueryClient();

  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState(
    integrationList[0].value,
  );

  const { data: segments } = useGetSegmentsListQuery({
    domain: params.domain || "",
  });

  const {
    syncSegmentMutation,
    unlinkSegmentMutation,
    isPending,
    isError,
    isSuccess,
  } = useSyncSegmentMutation();

  const {
    syncLexwareSegmentMutation,
    unlinkLexwareSegmentMutation,
    isPending: isLexwarePending,
  } = useSyncLexwareSegmentMutation();

  const linkedHubspotSegments = useMemo(
    () =>
      segments?.filter((segment) =>
        segment.integration_link.includes(Integrations.HUBSPOT),
      ) ?? [],
    [segments],
  );

  const linkedLexwareSegments = useMemo(
    () =>
      segments?.filter((segment) =>
        segment.integration_link.includes(Integrations.LEXWARE),
      ) ?? [],
    [segments],
  );

  const syncSegment = async (
    id: string,
    integrationType: Integrations.HUBSPOT | Integrations.LEXWARE,
  ) => {
    try {
      const syncMutation =
        integrationType === Integrations.HUBSPOT
          ? syncSegmentMutation
          : syncLexwareSegmentMutation;

      await syncMutation({
        segment_id: id,
        enable: true,
      });

      queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
    } catch (error) {
      console.log(`Error syncing ${integrationType} segment:`, error);
      captureException(error);
    }
  };

  const unlinkSegment = async (
    id: string,
    integrationType: Integrations.HUBSPOT | Integrations.LEXWARE,
  ) => {
    try {
      const unlinkMutation =
        integrationType === Integrations.HUBSPOT
          ? unlinkSegmentMutation
          : unlinkLexwareSegmentMutation;

      await unlinkMutation({
        segment_id: id,
        enable: false,
      });

      queryClient.invalidateQueries({ queryKey: [SEGMENTS_LIST] });
    } catch (error) {
      console.log(`Error unlinking ${integrationType} segment:`, error);
      captureException(error);
    }
  };

  return {
    selectedSegment,
    selectedIntegration,
    setSelectedIntegration,
    setSelectedSegment,
    isPending,
    isError,
    isSuccess,
    linkedHubspotSegments,
    linkedLexwareSegments,
    syncSegment,
    unlinkSegment,
    isLexwarePending,
  };
};
