import { useLexWareSettings } from "@/hooks";
import { useHubSpotSettings } from "@/hooks/api/queries/useHubspotSettings";
import { useAuth } from "@/provider/authProvider";
import { ReactNode, createContext, useMemo } from "react";

export interface IntegrationsContextProps {
  isHubSpotEnabled: boolean;
  isLexWareEnabled: boolean;
}

export const IntegrationsContext = createContext<IntegrationsContextProps>({
  isHubSpotEnabled: false,
  isLexWareEnabled: false,
});

type IntegrationsProviderProps = {
  children: ReactNode;
};

export const IntegrationsProvider = ({
  children,
}: IntegrationsProviderProps) => {
  const { isAuthenticated } = useAuth();
  const { data: hubSpotSettings } = useHubSpotSettings(isAuthenticated);
  const { data: lexWareSettings } = useLexWareSettings(isAuthenticated);

  const isHubSpotEnabled = useMemo(
    () => hubSpotSettings?.enabled,
    [hubSpotSettings, isAuthenticated],
  );
  const isLexWareEnabled = useMemo(
    () => lexWareSettings?.enabled,
    [lexWareSettings, isAuthenticated],
  );

  return (
    <IntegrationsContext.Provider
      value={{
        isHubSpotEnabled,
        isLexWareEnabled,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};
