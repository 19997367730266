import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"

export const RTHorizontalRule: FC<RTElementProps> = () => {
  return (
    <>
      <hr />
    </>
  )
}
