import { Button } from "@/components/ui/button";
import { LocalCompaniesDetails } from "@/types";
import { useCompanyLinking } from "./hooks";

import hubspot from "../../../assets/icons/hubspot.svg";
import lexware from "../../../assets/icons/lexWare.jpeg";
import tick from "../../../assets/icons/tick.svg";

export const CompanyIntegrations = ({
  companiesDetails,
}: {
  companiesDetails: LocalCompaniesDetails;
}) => {
  const {
    isHubSpotEnabled,
    isLexWareEnabled,
    handleSyncCompany,
    handleUnlinkCompany,
    handleLexwareSyncCompany,
    handleUnlinkLexwareCompany,
  } = useCompanyLinking({ companiesDetails });

  return (
    <div className="flex gap-[8px]">
      <div className="group relative flex">
        {isHubSpotEnabled && (
          <Button
            asChild
            variant="ghost"
            size="icon"
            onClick={
              companiesDetails.integrations.hubspot.sync_enabled
                ? handleUnlinkCompany
                : handleSyncCompany
            }
            className="cursor-pointer"
          >
            <div className="relative">
              <img src={hubspot} alt="Hubspot" className="h-[28px] w-[28px]" />

              {companiesDetails.integrations.hubspot.sync_enabled && (
                <div className="absolute right-[0px] top-[0px] rounded-full bg-white">
                  <img src={tick} alt="Tick" className="h-[15px] w-[15px]" />
                </div>
              )}
            </div>
          </Button>
        )}

        <div
          role="tooltip"
          className="duration-600 invisible absolute right-[-40px] top-[-50px] z-10 mt-2 w-[60px] -translate-x-1/2 transform rounded-lg bg-gray-900 px-2 py-1 text-center text-[9px] font-medium leading-3 text-white opacity-0 transition-opacity delay-500 group-hover:visible group-hover:opacity-100 dark:bg-gray-700"
        >
          {!companiesDetails.integrations.hubspot.sync_enabled
            ? "Mit HubSpot verbinden"
            : "Mit HubSpot verbunden"}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

      <div className="group relative flex">
        {isLexWareEnabled && (
          <Button
            asChild
            variant="ghost"
            size="icon"
            className="cursor-pointer"
            onClick={
              companiesDetails.integrations.lexware.sync_enabled
                ? handleUnlinkLexwareCompany
                : handleLexwareSyncCompany
            }
          >
            <div className="relative">
              <img
                src={lexware}
                alt="LexWare"
                className="h-[28px] w-[28px] rounded-full"
              />

              {companiesDetails.integrations.lexware.sync_enabled && (
                <div className="absolute right-[0px] top-[0px] rounded-full bg-white">
                  <img src={tick} alt="Tick" className="h-[15px] w-[15px]" />
                </div>
              )}
            </div>
          </Button>
        )}

        <div
          role="tooltip"
          className="duration-600 invisible absolute right-[-40px] top-[-50px] z-10 mt-2 w-[60px] -translate-x-1/2 transform rounded-lg bg-gray-900 px-2 py-1 text-center text-[9px] font-medium leading-3 text-white opacity-0 transition-opacity delay-500 group-hover:visible group-hover:opacity-100 dark:bg-gray-700"
        >
          {!companiesDetails.integrations.lexware.sync_enabled
            ? "Mit Lexware verbinden"
            : "Mit Lexware verbunden"}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
};
