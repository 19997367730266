import image from "@/pages/smartLandingPage/messe-muenchen/images/testimonials.jpeg";
import { forwardRef } from "react";

export const Testimonials = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="testimonials" className="w-full bg-platin" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] items-center gap-24 px-4 py-8 lg:grid lg:grid-cols-2">
        <div className="px-16 text-black sm:text-lg">
          <h1
            id="testimonials-title"
            className="lg:p-r-4 mb-8 text-3xl font-semibold tracking-tight text-black md:text-4xl"
          >
            Was unsere Kunden sagen
          </h1>
          <div id="testimonials-testimonial" className="flex gap-0 pb-8">
            <p>"</p>
            <p>
              Mit Webmetic konnten wir die Ticketverkäufe und Anfragen für
              unsere Messen signifikant steigern. Durch personalisierte Landing
              Pages sehen Besucher jetzt genau die für sie relevanten Events und
              Aussteller. Unsere Conversion-Rate ist um 32% gestiegen!“
            </p>
          </div>
          <div id="testimonial-author">
            <p className="">Michael Huber</p>
            <p className="text-sm">
              Head of Digital Marketing - Messe Deutschland
            </p>
            <br />
            <p className="text-xs">(Musterdarstellung von Testimonial)</p>
          </div>
        </div>
        <img
          id="testimonial-image"
          className="mx-auto mb-4 hidden rounded-l-2xl sm:flex"
          src={image}
          alt="table professor illustration"
        />
      </div>
    </section>
  );
});
