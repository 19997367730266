import { BlockProps } from "@/components/content/Blocks/types.ts";
import { FaqAsAccordion } from "@/types";
import { RichText } from "@/components/content";
import {
  Accordion, CustomFlowbiteTheme, FlowbiteAccordionComponentTheme, FlowbiteAccordionTitleTheme
} from "flowbite-react";

// Flowbite block https://flowbite.com/blocks/marketing/faq/#faq-as-accordion

const accordionTheme: CustomFlowbiteTheme["accordion"] = {
  root: {
    base: "divide-y divide-stone border-stone dark:divide-gray-700 dark:border-gray-700"
  }
}

const accordionTitleTheme: Partial<FlowbiteAccordionTitleTheme> = {
  base: "flex w-full items-center justify-between p-5 text-left font-medium text-gray-500 first:rounded-t-lg last:rounded-b-lg dark:text-gray-400 text-lg",
  open: {
    on: "bg-platin dark:bg-graphite text-black dark:text-white",
    off: "text-gray-500 dark:text-gray-400"
  },
};

const accordionContetntTheme: FlowbiteAccordionComponentTheme = {
  base: "p-5 first:rounded-t-lg last:rounded-b-lg dark:bg-graphite"
}

export const FaqAsAccordionBlock = ({ block }: BlockProps) => {
  const blockFields: FaqAsAccordion = block as FaqAsAccordion;
  const paragraphClasses = 'mt-0 mb-2 dark:text-white'
  return (
    <section className="bg-platin dark:bg-graphite">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-16 lg:px-6">
        <h2 className="mb-6 text-center text-3xl font-extrabold tracking-tight text-black dark:text-white lg:mb-8 lg:text-4xl">
          {blockFields.title}
        </h2>
        <div className="mx-auto max-w-screen-md">
          <Accordion flush={true} theme={accordionTheme}>
            {blockFields.questions.map((question, index) => (
              <Accordion.Panel key={index}>
                <Accordion.Title theme={accordionTitleTheme}>
                  <span>{question.question}</span>
                </Accordion.Title>
                <Accordion.Content theme={accordionContetntTheme}>
                  <RichText root={question.answer.root} classes={paragraphClasses} />
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};
