/**
 * Utility function to clean tracking parameters from URL
 * This helps improve user experience by removing tracking parameters from the visible URL
 * after they have been saved for analytics purposes.
 */
export const cleanUrlParameters = (): void => {
  // If there's no search params, return early
  if (!window.location.search) return;
  
  const urlParams = new URLSearchParams(window.location.search);
  let hasTrackingParams = false;
  
  // List of parameters to remove (focused on B2B tracking)
  const paramsToRemove = [
    // UTM parameters (standard)
    'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content',
    
    // B2B Ad platform parameters
    'gclid',        // Google Ads
    'msclkid',      // Microsoft/Bing Ads
    'li_fat_id',    // LinkedIn Ads
    'fbclid',       // Facebook Ads (as mentioned in PARTNER.md)
    'dclid',        // Google Display & Video 360
    
    // HubSpot parameters
    'hsa_cam', 'hsa_grp', 'hsa_mt', 'hsa_src', 'hsa_ad', 
    'hsa_acc', 'hsa_net', 'hsa_kw', 'hsa_ver',
    
    // Other B2B marketing platforms
    'ef_id',        // Adobe/Marketo
    
    // Analytics/attribution
    '_ga',          // Google Analytics
    
    // Custom parameters (from PARTNER.md)
    'sn',           // Custom source parameter
    'pn',           // Custom partner parameter
    'lgs'           // Lead generation source parameter
  ];
  
  // Remove all tracking parameters in our list
  paramsToRemove.forEach(param => {
    if (urlParams.has(param)) {
      urlParams.delete(param);
      hasTrackingParams = true;
    }
  });
  
  // Also remove any parameter with these prefixes
  const prefixesToRemove = ['utm_', 'hsa_'];
  
  for (const key of Array.from(urlParams.keys())) {
    if (prefixesToRemove.some(prefix => key.startsWith(prefix))) {
      urlParams.delete(key);
      hasTrackingParams = true;
    }
  }
  
  // Only update history if we removed parameters
  if (hasTrackingParams) {
    // Construct new URL without the tracking parameters
    const newUrl = urlParams.toString() 
      ? `${window.location.pathname}?${urlParams.toString()}` 
      : window.location.pathname;
    
    // Replace current URL without reloading the page
    window.history.replaceState({}, document.title, newUrl);
  }
};