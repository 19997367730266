export function getScoreLevel(percentage: number) {
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100.");
  }

  if (percentage <= 33) {
    return "low";
  } else if (percentage <= 66) {
    return "medium";
  } else {
    return "high";
  }
}
