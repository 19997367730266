import React from "react";
import tick from "../../../../assets/icons/tick.svg";
import { useDisableLexwareMutation } from "@/hooks";
import { LEXWARE_SETTINGS } from "@/constants";
import { useQueryClient } from "@tanstack/react-query";
import analytics from "@/analytics";

export const LexWareSettings = ({ lexWareSettings, handleClick }) => {
  const { disableLexwareMutation } = useDisableLexwareMutation();
  const queryClient = useQueryClient();

  const disconnectLexWare = () => {
    // Get the active days for the integration
    const createdAt = lexWareSettings?.created_at ? new Date(lexWareSettings.created_at) : new Date();
    const currentDate = new Date();
    const daysActive = Math.floor((currentDate.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24));
    
    disableLexwareMutation(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [LEXWARE_SETTINGS] });
          
          // Track Lexware integration removal
          analytics.track("integration_lexoffice_removed", {
            removal_date: new Date().toISOString(),
            removal_reason: "user_initiated",
            days_active: daysActive
          });
        },
        onError: (e) => {
          console.log("ERROR disconnecting", e);
        },
      },
    );
  };

  return (
    <div className="flex h-full w-full justify-center py-[20px]">
      <div className="w-full rounded-[4px] py-[10px] lg:py-[20px]">
        <p className="mb-[16px] px-4 text-[20px] font-bold">
          LexWare-Integration
        </p>
        <p className="px-4 font-medium">
          Diese Integration ist mit Ihrem Webmetic-Konto verbunden.{" "}
        </p>

        <div className="my-[16px] h-[1px] w-full bg-gray-300" />
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center gap-[8px]">
            {lexWareSettings?.enabled && (
              <>
                <img src={tick} alt="Connected" className="h-[30px] w-[30px]" />
                <p className="font-medium">Verbunden mit Lexware</p>
              </>
            )}
            {!lexWareSettings?.enabled && (
              <>
                <img
                  src={cross}
                  alt="Connected"
                  className="h-[30px] w-[30px]"
                />
                <p className="font-medium text-[#d32f2f]">Getrennt</p>
              </>
            )}
          </div>
          {lexWareSettings?.enabled ? (
            <button
              type="button"
              className="flex w-fit cursor-pointer items-center justify-center rounded-[4px] bg-[#EC3131] px-[8px] py-[6px] text-sm text-white transition-all hover:bg-red-400"
              onClick={disconnectLexWare}
            >
              <p>Trennen</p>
            </button>
          ) : (
            <button
              className="btn"
              type="submit"
              onClick={(e) => handleClick(e)}
            >
              Mit Lexware verbinden
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
