import { useLivePreview } from "@payloadcms/live-preview-react";
import { Page as PageType } from "@/types";
import { RichText } from "@/components/content";
import Layout from "@/pages/components/Layout";

type format = 'left' | 'start' | 'center' | 'right' | 'end' | 'justify' | ''

const initialPage = {
  id: '',
  page: null,
  name: '',
  content: {
    root: {
      type: '',
      children: [],
      direction: null,
      format: '' as format,
      indent: 0,
      version: 0,
    }
  },
  updatedAt: '',
  createdAt: '',
}

export const PagePreview = () => {
  const baseUrl = import.meta.env.VITE_APP_CMS
  const { data } = useLivePreview<PageType>({
    serverURL: baseUrl,
    depth: 3,
    initialData: initialPage
  })

  return (
    <Layout>
      <RichText root={data.content.root} />
    </Layout>
  )
}
