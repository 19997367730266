import { SyncSegmentHubSpotData, apiHandlers } from "@/api";
import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useSyncSegmentMutationsProps {
  syncLexwareSegmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    SyncSegmentHubSpotData,
    unknown
  >;
  unlinkLexwareSegmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    SyncSegmentHubSpotData,
    unknown
  >;
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  isUnlinking: boolean;
  isUnlinkError: boolean;
}

export const useSyncLexwareSegmentMutation =
  (): useSyncSegmentMutationsProps => {
    const {
      mutateAsync: syncLexwareSegmentMutation,
      isPending,
      isError,
      isSuccess,
    } = useMutation({
      mutationFn: apiHandlers.integrations.lexWare.syncSegment,
    });

    const {
      mutateAsync: unlinkLexwareSegmentMutation,
      isPending: isUnlinking,
      isError: isUnlinkError,
    } = useMutation({
      mutationFn: apiHandlers.integrations.lexWare.syncSegment,
    });

    return {
      syncLexwareSegmentMutation,
      isPending: isPending || isUnlinking,
      isError,
      isSuccess,
      unlinkLexwareSegmentMutation,
      isUnlinking,
      isUnlinkError,
    };
  };
