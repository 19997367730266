export const apiPaths = {
  auth: {
    login: () => "/api/auth/user_login",
  },
  company: {
    multiSearch: () => "/api/multi-mode-search",
    search: () => "/api/search_companies",
    details: () => "/api/company_details",
    sessions: (company_id?: string) => `/api/company/${company_id}/sessions`,
  },
  segment: {
    createSegment: () => "/api/segments/segment_create",
    getSegments: () => "/api/segments/segments_list",
    getSegmentById: (segment_id: string) =>
      `/api/segments/segment_get/${segment_id}`,
    updateSegment: (segment_id: string) =>
      `/api/segments/segment_update/${segment_id}`,
    deleteSegment: (segment_id: string) =>
      `/api/segments/segment_delete/${segment_id}`,
    segmentSearch: (segment_id: string) =>
      `/api/segments/segment_query/${segment_id}`,
  },
  exports: {
    exportSearchedCompaniesList: () => "/api/exports/spreadsheet",
  },
  integrations: {
    hubspot: {
      authUrl: () => "/api/integrations/hubspot/auth-url",
      confirm: () => `/api/integrations/hubspot/confirm`,
      getSettings: () => `/api/integrations/hubspot/settings/`,
      settings: () => `/api/integrations/hubspot/settings`,
      syncCompany: () => "/api/integrations/hubspot/company-sync",
      disableIntegration: () => "/api/integrations/hubspot/disable",
      syncSegment: () => "/api/integrations/hubspot/segment-sync",
    },
    lexWare: {
      authUrl: () => "/api/integrations/lexware/auth-url",
      confirm: () => `/api/integrations/lexware/confirm`,
      getSettings: () => `/api/integrations/lexware/settings`,
      disableIntegration: () => "/api/integrations/lexware/disable",
      syncCompany: () => "/api/integrations/lexware/company-sync",
      syncSegment: () => "/api/integrations/lexware/segment-sync",
    },
  },
} as const;
