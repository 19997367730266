import { forwardRef } from "react";

export const Features = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="features" className="bg-gray-50" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-center sm:py-16">
        <h2
          id="features-title"
          className="mx-auto mb-12 max-w-[40rem] text-4xl font-extrabold tracking-tight text-neutral-900"
        >
          Wie statische Landing Pages <br />
          Ihr Wachstum bremsen
        </h2>
        <div
          id="feature-list"
          className="space-y-8 md:grid md:grid-cols-2 md:gap-5 md:space-y-0 lg:grid-cols-3"
        >
          <div className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white" id="feature1">
            <p className="text-base">01</p>
            <h3 className="text-3xl font-normal">
              Generischer Content konvertiert nicht
            </h3>
            <p className="text-base font-light">
              Ein großer Messeveranstalter sieht dieselbe Startseite wie ein
              regionaler Handwerksbetrieb – obwohl sie völlig unterschiedliche
              Interessen haben.
            </p>
            <div>
              <p className="text-base font-light">Resultat:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Fachbesucher finden{" "}
                  <span className="font-bold">keine relevanten Inhalte</span>
                </li>
                <li className="mb-3.5">
                  Unternehmen{" "}
                  <span className="font-bold">verpassen potenzielle Leads</span>
                </li>
                <li>
                  <span className="font-bold">
                    Kürzere Verweildauer, weniger Conversions
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white" id="feature2">
            <p className="text-base">02</p>
            <h3 className="text-3xl font-normal">
              Ressourcen werden nicht effizient genutzt
            </h3>
            <p className="text-base font-light">
              Werbekosten zahlen sich nicht aus, wenn die unterschiedlichsten
              Kampagnen alle zur selben generischen Landing Page führen.
            </p>
            <div>
              <p className="text-base font-light">Resultat:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  <span className="font-bold">Höhere Werbekosten</span> bei
                  geringer Wirkung
                </li>
                <li className="mb-3.5">
                  <span className="font-bold">Besucher springen ab</span>, weil
                  Inhalte nicht auf sie zugeschnitten sind
                </li>
                <li>
                  <span className="font-bold">
                    Wertvolle Nutzerdaten bleiben ungenutzt
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white" id="feature3">
            <p className="text-base">03</p>
            <h3 className="text-3xl font-normal">
              Geschäftspotenzial geht verloren
            </h3>
            <p className="text-base font-light">
              Die Vielfalt an Interessenten und Veranstaltungen kann ohne
              smartes Konzept mehr Herausforderung als Chance sein.
            </p>
            <div>
              <p className="text-base font-light">Resultat:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Käufer kämpfen sich durch{" "}
                  <span className="font-bold">
                    irrelevante Inhalte → weniger Verkäufe
                  </span>
                </li>
                <li className="mb-3.5">
                  Aussteller fühlen sich nicht angesprochen →{" "}
                  <span className="font-bold">weniger Buchungen</span>
                </li>
                <li>Kürzere Verweildauer, weniger Conversions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
