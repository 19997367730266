import useUser from "@/hooks/useUser.ts";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Dashboard() {
  const user = useUser()!;
  const navigate = useNavigate();
  const location = useLocation();
  const hasNavigated = useRef(false);

  useEffect(() => {
    if (hasNavigated.current) return;

    const searchParams = new URLSearchParams(location.search);
    const menuParam = searchParams.get("menu");
    const hubspotParam = searchParams.get("hubspot");
    const lexWareParam = searchParams.get("lexware");

    if (user.website && user.website.length > 0) {
      const targetPath = new URLSearchParams();

      if (menuParam) targetPath.set("menu", menuParam);
      if (hubspotParam) targetPath.set("hubspot", hubspotParam);
      if (lexWareParam) targetPath.set("lexware", lexWareParam);

      const redirectUrl = `/dashboard/${user.website[0]}/companies${
        targetPath.toString() ? `?${targetPath.toString()}` : ""
      }`;

      if (menuParam || hubspotParam || location.pathname === "/dashboard") {
        hasNavigated.current = true;
        navigate(redirectUrl, { replace: true });
      }
    }
  }, [user, location, navigate]);

  if (!user.website || user.website.length === 0) {
    return <div>No website associated with this user.</div>;
  }

  return null;
}

export default Dashboard;
