import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { Flowbite, Navbar, DarkThemeToggle } from "flowbite-react";
import { useAuth } from "../../provider/authProvider";
import IconWhite from "../../assets/ci/webmetic-icon-white.svg";
import IconBlack from "../../assets/ci/webmetic-icon-black.svg";
import LogoWhite from "../../assets/ci/webmetic-logo-white.svg";
import LogoBlack from "../../assets/ci/webmetic-logo-black.svg";
import analytics from "@/analytics";

const customTheme = {
  navbar: {
    link: {
      base: "lg:hover:text-primary-700 block border-b border-gray-100 md:border-0 py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 hover:text-webmetic_dark dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:dark:hover:bg-transparent lg:dark:hover:text-white",
      active: {
        on: "text-primary-700",
        off: "text-primary-700",
      },
    },
    collapse: {
      base: "w-full md:block md:w-auto",
      list: "mt-4 flex flex-col md:mt-0 md:flex-row lg:space-x-8 md:text-sm md:font-medium",
      hidden: {
        on: "hidden",
        off: "",
      },
    },
  },
};

const Header = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const updateFavicon = useCallback(() => {
    const themeMode = localStorage.getItem("flowbite-theme-mode");
    const favicon = document.getElementById("site-icon");
    if (favicon) {
      favicon.href =
        themeMode === "light"
          ? "../../src/assets/ci/webmetic-icon-white.png"
          : "../../src/assets/ci/webmetic-icon-black.png";
    }
  }, []);
  const handleClick = useCallback((source) => {
    analytics.track("register_modal_opened", {
      source: source,
    });

    navigate(`/register`);
  }, []);
  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === "#register") {
        setIsModalOpen(true);
        analytics.track("register_modal_opened", {
          source: "deep_link",
        });
      }
    };

    // Check hash on mount and when it changes
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);
  return (
    <section>
      <div className="border-gray-200 bg-white px-2 drop-shadow-sm dark:border-0 dark:bg-gray-800 sm:px-4">
        <Flowbite theme={{ theme: customTheme }}>
          <Navbar fluid rounded className="mx-auto max-w-screen-xl lg:px-6">
            <Navbar.Brand href="/">
              <img
                src={LogoBlack}
                className="mr-3 h-6 dark:hidden sm:h-8"
                alt="Webmetic Logo"
              />
              <img
                src={LogoWhite}
                className="mr-3 hidden h-6 dark:block sm:h-8"
                alt="Webmetic Logo"
              />
            </Navbar.Brand>
            <div className="flex md:order-2">
              <div className="mr-2 hidden sm:block">
                <DarkThemeToggle />
              </div>
              <Link
                to={!isAuthenticated ? "/login" : "/dashboard"}
                className="btn-alt mr-2 hidden sm:block"
              >
                {!isAuthenticated ? "Login" : "Dashboard"}
              </Link>
              {!isAuthenticated && (
                <button
                  onClick={() => handleClick("header_button")}
                  className="btn font mr-2 hidden sm:block"
                >
                  Kostenlos Anmelden
                </button>
              )}
              <div className="md:hidden">
                <Navbar.Toggle />
              </div>
            </div>
            <Navbar.Collapse>
              <Navbar.Link href="/">Start</Navbar.Link>
              <Navbar.Link href="/preise">Preise</Navbar.Link>
              <Navbar.Link href="/funktionen">Funktionen</Navbar.Link>
              <Navbar.Link href="/smart-landing-page" target="_blank">Smart Page</Navbar.Link>
              <Navbar.Link href="/datenschutz">Datenschutz</Navbar.Link>
              <Navbar.Link href="/kontakt">Kontakt</Navbar.Link>
              {/*<Navbar.Link href="/about">Über uns</Navbar.Link>
              <Navbar.Link href="/help-center">Help Center</Navbar.Link>
              <Navbar.Link href="/blog">Blog</Navbar.Link>*/}
              <Navbar.Link className="sm:hidden" href="/login">
                Login
              </Navbar.Link>
              <Navbar.Link
                className="sm:hidden"
                onClick={() => handleClick("header_mobile_button")}
              >
                Kostenlos anmelden
              </Navbar.Link>
            </Navbar.Collapse>
          </Navbar>
        </Flowbite>
      </div>
      <div className="flex items-center gap-2"></div>
    </section>
  );
};

export default Header;
