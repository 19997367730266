import { CompanySessionsParams, apiHandlers } from "@/api";
import { COMPANY_SESSIONS } from "@/constants";
import { CompaniesDetailsSession } from "@/types";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useCompanySessions = (
  params: CompanySessionsParams,
  enabled: boolean,
): UseQueryResult<
  {
    results: CompaniesDetailsSession[];
    pagination: { total: number };
    last_activity: string;
    total_interactions: number;
    total_pages_visited: number;
  },
  Error
> => {
  return useQuery({
    queryKey: [
      COMPANY_SESSIONS,
      params.company_id,
      params.domain,
      params.query_id,
      params.page,
    ],
    queryFn: () => {
      return apiHandlers.company.sessions(params).then((res) => {
        return res.data;
      });
    },

    enabled,
    refetchOnWindowFocus: false,
  });
};
