export const SignalCellularAlt = () => {
  return (
    <svg
      width={15}
      height={17}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.7344V10.7344H3V16.7344H0ZM6 16.7344V5.73438H9V16.7344H6ZM12 16.7344V0.734375H15V16.7344H12Z"
        fill="#00E6A0"
      />
    </svg>
  )
}
