import { apiHandlers } from "@/api";
import { LEXWARE_AUTH_LINK } from "@/constants";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useLexWareAuthQuery = (): UseQueryResult<any> => {
  return useQuery<any>({
    queryKey: [LEXWARE_AUTH_LINK],
    queryFn: async () => {
      const response = await apiHandlers.integrations.lexWare.getAuthUrl();
      return response.data;
    },
  });
};
