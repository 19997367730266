import { getData, HTTP_CLIENT } from "./crudHandlers";

import { AxiosResponseSuccess } from ".";
import { cmsPaths } from "@/api/cmsPaths.ts";
import { BlogPost, Page } from "@/types";

export const cmsHandlers = {
  blogPosts: {
    getAll: (): AxiosResponseSuccess<{ docs: BlogPost[] }> => {
      return getData<{ docs: BlogPost[] }>(
        cmsPaths.blogPosts.get(),
        {
          params: {
            depth: 3,
            'select[content]': false,
          }
        },
        HTTP_CLIENT.Cms,
      )
    },
    get: (id: string): AxiosResponseSuccess<BlogPost> => {
      return getData<BlogPost>(
        cmsPaths.blogPosts.get() + `/${id}`,
        {
          params: {
            depth: 3,
          }
        },
        HTTP_CLIENT.Cms,
      )
    }
  },
  pages: {
    get: (page: string): AxiosResponseSuccess<{docs: Page[]}> => {
      return getData<{docs: Page[]}>(
        cmsPaths.pages.get(),
        {
          params: {
            depth: 3,
            'where[page][equals]': page,
          }
        },
        HTTP_CLIENT.Cms,
      )
    }

  }
};
