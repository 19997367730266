import { BlockProps } from "@/components/content/Blocks/types.ts";
import { DefaultRelatedArticles, Images276X180 } from "@/types";
import { Link } from "react-router-dom";
import { getCmsImageUrl } from "@/utils/cms.ts";

// Flowbite block https://flowbite.com/blocks/publisher/related-articles/#default-related-articles

type ArticleType = NonNullable<DefaultRelatedArticles["articles"]>[number]

export const Article = ({ article }: { article: ArticleType }) => {
  const image = article.image as Images276X180;
  const imagePath = image.sizes?.image?.url;
  return (
    <article className="max-w-xs">
      {imagePath && <Link to={article["link-path"]}>
        <img src={getCmsImageUrl(imagePath)}
             className="mb-5 rounded-lg" alt={image.alt ?? ''} />
      </Link>}
      <h2 className="mb-2 text-xl font-bold leading-tight text-black dark:text-white">
        <Link to={article["link-path"]}>{article.title}</Link>
      </h2>
      <p className="mb-4 text-black dark:text-white">{article.lead}</p>
      <Link to={article["link-path"]}
         className="inline-flex items-center font-medium underline underline-offset-4 text-energetic-mint-600 dark:text-energetic-mint-500 hover:no-underline">
        { article["link-text"] }
      </Link>
    </article>
  )
}

export const DefaultRelatedArticlesBlock = ({ block }: BlockProps) => {
  const blockFields: DefaultRelatedArticles = block as DefaultRelatedArticles;
  const articles = blockFields.articles;
  if (!articles) return null;
  return (
    <aside aria-label="Related articles" className="py-8 lg:py-24 bg-platin dark:bg-graphite antialiased">
      <div className="px-4 mx-auto max-w-screen-xl">
        <h2 className="mb-8 text-2xl font-bold text-black dark:text-white">{blockFields.title}</h2>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          { articles.map((article, index) => <Article article={article} key={index} />)}
        </div>
      </div>
    </aside>
  );
};
