export const Customer3 = () => {
  return (
    <svg width="277" height="120" viewBox="0 0 277 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="277" height="120" fill="#FAFAFA"/>
      <path d="M68.3945 60C60.5109 60 54.1184 53.6075 54.1184 45.7239H68.3945V60Z" fill="#A3A3A3"/>
      <path d="M82.6703 60C82.6703 52.1164 76.2778 45.7239 68.3943 45.7239V60H82.6703Z" fill="#A3A3A3"/>
      <path d="M68.3945 74.2754C60.5109 74.2754 54.1184 67.8829 54.1184 59.9993H68.3945V74.2754Z" fill="#D4D4D4"/>
      <path d="M82.6703 74.2754C82.6703 66.3918 76.2778 59.9993 68.3943 59.9993V74.2754H82.6703Z" fill="#D4D4D4"/>
      <path d="M107.836 67.9008C112.355 67.9008 115.035 65.1053 115.035 60.4702C115.035 55.8496 112.355 53.0686 107.938 53.0686H102.81V67.9008H107.836ZM105.497 65.576V55.3933H107.786C110.798 55.3933 112.37 57.0736 112.37 60.4702C112.37 63.8813 110.798 65.576 107.706 65.576H105.497Z" fill="#737373"/>
      <path d="M122.193 68.1181C124.786 68.1181 126.567 66.8507 127.031 64.917L124.583 64.6418C124.228 65.5833 123.359 66.0758 122.229 66.0758C120.534 66.0758 119.412 64.9604 119.39 63.0557H127.139V62.2518C127.139 58.3482 124.793 56.6318 122.055 56.6318C118.869 56.6318 116.79 58.971 116.79 62.4039C116.79 65.8947 118.84 68.1181 122.193 68.1181ZM119.397 61.2886C119.477 59.8691 120.527 58.6741 122.092 58.6741C123.598 58.6741 124.612 59.7749 124.626 61.2886H119.397Z" fill="#737373"/>
      <path d="M137.946 59.717C137.584 57.834 136.077 56.6318 133.47 56.6318C130.79 56.6318 128.965 57.9499 128.972 60.0067C128.965 61.629 129.965 62.7008 132.101 63.1426L133.999 63.5409C135.02 63.7655 135.498 64.1783 135.498 64.8084C135.498 65.5688 134.672 66.1409 133.426 66.1409C132.224 66.1409 131.442 65.6195 131.218 64.62L128.661 64.8663C128.987 66.9086 130.703 68.1181 133.434 68.1181C136.215 68.1181 138.177 66.6769 138.185 64.5694C138.177 62.9833 137.156 62.0128 135.056 61.5565L133.158 61.151C132.029 60.8975 131.58 60.5064 131.587 59.8618C131.58 59.1086 132.413 58.5872 133.506 58.5872C134.716 58.5872 135.353 59.2462 135.556 59.9777L137.946 59.717Z" fill="#737373"/>
      <path d="M140.143 67.9008H142.764V56.7766H140.143V67.9008ZM141.461 55.1978C142.294 55.1978 142.974 54.5605 142.974 53.7783C142.974 52.9889 142.294 52.3516 141.461 52.3516C140.621 52.3516 139.94 52.9889 139.94 53.7783C139.94 54.5605 140.621 55.1978 141.461 55.1978Z" fill="#737373"/>
      <path d="M150.094 72.3041C153.1 72.3041 155.316 70.9281 155.316 68.0819V56.7766H152.73V58.6162H152.585C152.187 57.8123 151.354 56.6318 149.392 56.6318C146.821 56.6318 144.8 58.6451 144.8 62.3025C144.8 65.9309 146.821 67.7415 149.384 67.7415C151.289 67.7415 152.18 66.7203 152.585 65.9019H152.716V68.0095C152.716 69.6462 151.63 70.3198 150.138 70.3198C148.559 70.3198 147.864 69.5738 147.545 68.922L145.184 69.4941C145.662 71.0512 147.262 72.3041 150.094 72.3041ZM150.116 65.6847C148.385 65.6847 147.472 64.3376 147.472 62.288C147.472 60.2674 148.37 58.7827 150.116 58.7827C151.803 58.7827 152.73 60.1805 152.73 62.288C152.73 64.41 151.789 65.6847 150.116 65.6847Z" fill="#737373"/>
      <path d="M160.421 61.3827C160.421 59.7749 161.391 58.8479 162.774 58.8479C164.129 58.8479 164.94 59.7387 164.94 61.2234V67.9008H167.561V60.8178C167.569 58.1527 166.048 56.6318 163.752 56.6318C162.086 56.6318 160.942 57.4284 160.435 58.6669H160.305V56.7766H157.799V67.9008H160.421V61.3827Z" fill="#737373"/>
      <path d="M174.947 68.1181C177.54 68.1181 179.322 66.8507 179.785 64.917L177.337 64.6418C176.983 65.5833 176.113 66.0758 174.984 66.0758C173.289 66.0758 172.166 64.9604 172.145 63.0557H179.894V62.2518C179.894 58.3482 177.547 56.6318 174.81 56.6318C171.623 56.6318 169.545 58.971 169.545 62.4039C169.545 65.8947 171.594 68.1181 174.947 68.1181ZM172.152 61.2886C172.232 59.8691 173.282 58.6741 174.846 58.6741C176.352 58.6741 177.366 59.7749 177.381 61.2886H172.152Z" fill="#737373"/>
      <path d="M181.908 67.9008H184.53V61.361C184.53 59.9488 185.594 58.9493 187.036 58.9493C187.477 58.9493 188.028 59.029 188.252 59.1014V56.6897C188.013 56.6463 187.6 56.6173 187.311 56.6173C186.036 56.6173 184.971 57.3415 184.566 58.6307H184.45V56.7766H181.908V67.9008Z" fill="#737373"/>
      <path d="M198.582 59.717C198.22 57.834 196.714 56.6318 194.107 56.6318C191.427 56.6318 189.602 57.9499 189.609 60.0067C189.602 61.629 190.601 62.7008 192.738 63.1426L194.635 63.5409C195.656 63.7655 196.134 64.1783 196.134 64.8084C196.134 65.5688 195.309 66.1409 194.063 66.1409C192.861 66.1409 192.079 65.6195 191.854 64.62L189.298 64.8663C189.624 66.9086 191.34 68.1181 194.07 68.1181C196.851 68.1181 198.814 66.6769 198.821 64.5694C198.814 62.9833 197.793 62.0128 195.693 61.5565L193.795 61.151C192.665 60.8975 192.216 60.5064 192.224 59.8618C192.216 59.1086 193.049 58.5872 194.143 58.5872C195.352 58.5872 195.99 59.2462 196.192 59.9777L198.582 59.717Z" fill="#737373"/>
      <path d="M203.401 61.3827C203.401 59.7749 204.4 58.8479 205.805 58.8479C207.181 58.8479 207.992 59.7242 207.992 61.2234V67.9008H210.614V60.8178C210.614 58.1309 209.093 56.6318 206.783 56.6318C205.074 56.6318 203.987 57.4067 203.473 58.6669H203.343V53.0686H200.779V67.9008H203.401V61.3827Z" fill="#737373"/>
      <path d="M213.083 67.9008H215.704V56.7766H213.083V67.9008ZM214.401 55.1978C215.234 55.1978 215.914 54.5605 215.914 53.7783C215.914 52.9889 215.234 52.3516 214.401 52.3516C213.561 52.3516 212.88 52.9889 212.88 53.7783C212.88 54.5605 213.561 55.1978 214.401 55.1978Z" fill="#737373"/>
      <path d="M218.196 72.0724H220.818V66.1482H220.927C221.34 66.9593 222.201 68.0964 224.113 68.0964C226.735 68.0964 228.698 66.0178 228.698 62.3532C228.698 58.6451 226.677 56.6318 224.106 56.6318C222.143 56.6318 221.325 57.8123 220.927 58.6162H220.775V56.7766H218.196V72.0724ZM220.767 62.3387C220.767 60.1805 221.694 58.7827 223.382 58.7827C225.127 58.7827 226.025 60.2674 226.025 62.3387C226.025 64.4245 225.113 65.9454 223.382 65.9454C221.709 65.9454 220.767 64.4969 220.767 62.3387Z" fill="#737373"/>
    </svg>

  )
}
