import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "@/pages/components/Layout";
import { useConfirmHubspotAuthQuery } from "@/hooks/api/queries/useConfirmHubspotAuthQuery";
import { useEffect, useState } from "react";
import { Loader, Loader2, Loader2Icon } from "lucide-react";
import { Confirmation } from "@/components";
import { useConfirmLexWareQuery } from "@/hooks";

const LexWarePage = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const error = searchParams.get("error");
  const navigate = useNavigate();

  const [triggerQuery, setTriggerQuery] = useState(false);

  const {
    data,
    isLoading,
    error: queryError,
    isSuccess,
  } = useConfirmLexWareQuery({ code, error }, { enabled: triggerQuery });

  const handleClick = () => {
    setTriggerQuery(true);
    if (!data)
      return alert(
        `Authorization code is missing!, error: ${queryError.message}`,
      );
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/dashboard?lexware=true");
    }
  }, [isSuccess]);

  return (
    <Layout>
      <Confirmation
        error={queryError}
        isLoading={isLoading}
        handleClick={handleClick}
      />
    </Layout>
  );
};

export default LexWarePage;
