import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { cn } from "@/lib/utils.ts"
import { RTCodeParagraph } from "@/components/content/RichText/elements/RTCodeParagraph.tsx"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTParagraph: FC<RTElementProps> = ({node, className}: RTElementProps) => {
  if (node.textFormat == 16) {
    return <RTCodeParagraph node={node} />
  }

  const paragraphClasses = 'mb-6 mt-6 text-lg leading-6'
  return (
    <p className={cn(paragraphClasses, className)}>
      <RTChildren children={node.children} />
    </p>
  )
}
