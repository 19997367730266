import { LinkCompanyHubSpotData, apiHandlers } from "@/api";
import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useLinkCompanyMutationsProps {
  linkCompanyMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    LinkCompanyHubSpotData,
    unknown
  >;
  isPending: boolean;
  isError: boolean;
}

export const useLinkCompanyMutation = (): useLinkCompanyMutationsProps => {
  const {
    mutateAsync: linkCompanyMutation,
    isPending,
    isError,
  } = useMutation({
    mutationFn: apiHandlers.integrations.hubspot.syncCompany,
  });

  return {
    linkCompanyMutation,
    isPending,
    isError,
  };
};
