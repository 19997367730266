import {
  Accordion,
  CustomFlowbiteTheme,
  FlowbiteAccordionComponentTheme,
  FlowbiteAccordionTitleTheme,
} from "flowbite-react";
import { forwardRef } from "react";

const questions = [
  {
    question: "Wie funktioniert Webmetic genau?",
    answer:
      "Webmetic identifiziert Unternehmensbesucher auf Ihrer Website und ermöglicht die Echtzeit-Personalisierung von Inhalten. Unsere DSGVO-konforme Technologie erkennt automatisch, welche Firmen Ihre Seite besuchen, und passt Inhalte entsprechend an – ohne Cookies und ohne Einwilligungspflicht.",
  },
  {
    question: "Welche Arten von Personalisierung sind möglich?",
    answer:
      "Mit Webmetic können Sie branchen-spezifische Inhalte anzeigen, Unternehmen namentlich ansprechen, maßgeschneiderte Lösungen präsentieren, relevante Testimonials einblenden, standortbezogene Angebote erstellen und CTAs individualisieren. Die Personalisierung erfolgt in Echtzeit und kann auf allen Webseiten implementiert werden.",
  },
  {
    question: "Brauchen wir dafür eigene Ressourcen aus der IT-Abteilung?",
    answer:
      "Nein, wir gestalten den gesamten Prozess als individuelles Projekt. Die technische Integration erfolgt über einen einfaches Data-Layer. Anders als bei Self-Service-Tools übernehmen wir die komplette Umsetzung der Personalisierungsstrategie für Sie. Unser Team in München begleitet Sie von der Konzeption bis zur Umsetzung und sorgt für eine reibungslose Implementierung ohne eigene IT-Ressourcen.",
  },
  {
    question: "Wie lange dauert die Implementierung?",
    answer:
      "Webmetic kann in wenigen Tagen eingerichtet werden. Wir übernehmen Setup, Datenintegration und Personalisierung, sodass Sie direkt durchstarten können.",
  },
  {
    question: "Welche Ergebnisse können wir erwarten?",
    answer:
      "Nach unseren bisherigen Erfahrungen können Sie mit einer deutlichen Verbesserung Ihrer Marketing-KPIs rechnen. Personalisierte Landing Pages führen typischerweise zu höheren Conversion-Raten, längerer Verweildauer und besserer Lead-Qualität. Die genauen Ergebnisse hängen von Ihrer spezifischen Situation ab, weshalb wir gerne eine individuelle Potenzialanalyse für Sie durchführen.",
  },
];

const accordionTheme: CustomFlowbiteTheme["accordion"] = {
  root: {
    base: "divide-y divide-stone border-stone",
  },
};

const accordionTitleTheme: Partial<FlowbiteAccordionTitleTheme> = {
  base: "flex w-full items-center justify-between p-4 text-left font-medium text-black first:rounded-t-lg last:rounded-b-lg text-base",
  open: {
    on: "text-black",
    off: "text-black",
  },
};

const accordionContetntTheme: FlowbiteAccordionComponentTheme = {
  base: "p-4 pt-0 border-none first:rounded-t-lg last:rounded-b-lg",
};

export const FAQ = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section
      id="faq"
      className="mx-auto w-full max-w-[70rem] gap-24 py-28 lg:grid lg:grid-cols-2"
      ref={ref}
    >
      <h1 id="faq-title" className="text-4xl font-bold">
        Häufig gestellte Fragen
      </h1>
      <div id="faq-questions" className="mx-auto max-w-screen-md">
        <Accordion flush={true} theme={accordionTheme}>
          {questions.map((question, index) => (
            <Accordion.Panel key={index}>
              <Accordion.Title theme={accordionTitleTheme}>
                <span>{question.question}</span>
              </Accordion.Title>
              <Accordion.Content theme={accordionContetntTheme}>
                <p className="text-sm font-normal text-carbon">
                  {question.answer}
                </p>
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      </div>
    </section>
  );
});
