import { BlockProps } from "@/components/content/Blocks/types.ts";
import { Images584X421, ImageWithCtaButton } from "@/types";
import { getCmsImageUrl } from "@/utils/cms.ts";
import { Link } from "react-router-dom";
import { ArrowRight } from "@/assets/icons";

// Flowbite block https://flowbite.com/blocks/marketing/cta/#image-with-cta-button

export const ImageWithCtaButtonBlock = ({ block }: BlockProps) => {
  const blockFields: ImageWithCtaButton = block as ImageWithCtaButton;
  const dartThemeImage = (blockFields["image-dark"] as Images584X421).sizes?.image?.url
  const lightThemeImage = (blockFields["image-light"] as Images584X421).sizes?.image?.url
  if (!dartThemeImage || !lightThemeImage) return null;
  return (
    <section className="bg-platin dark:bg-graphite">
      <div
        className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img className="w-full dark:hidden"
             src={getCmsImageUrl(lightThemeImage)}
             alt={(blockFields["image-light"] as Images584X421).alt ?? ''} />
        <img className="w-full hidden dark:block"
             src={getCmsImageUrl(dartThemeImage)}
             alt={(blockFields["image-dark"] as Images584X421).alt ?? ''} />
        <div className="mt-4 md:mt-0">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            { blockFields.title }
          </h2>
          <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            {blockFields.text}
          </p>
          <Link to={blockFields["cta-button-link"]}
             className="inline-flex items-center text-black bg-energetic-mint-500 hover:bg-energetic-mint-400 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
            { blockFields["cta-button-label"]}
            <ArrowRight />
          </Link>
        </div>
      </div>
    </section>
  )
};
