import { captureException } from "@sentry/react";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import feature_animation_black from "../../assets/images/webmetic-feature-animation-black.json";
import feature_animation_white from "../../assets/images/webmetic-feature-animation-white.json";

const Usps = () => {
  const [personalizationData, setPersonalizationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (dataFetchedRef.current) return; // Prevent multiple fetches
      dataFetchedRef.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const isDebug = urlParams.get("debug") === "true";

      try {
        const endpoint = `https://hub.webmetic.de/wcd${isDebug ? "?debug=true" : ""}`;
        const response = await Promise.race([
          fetch(endpoint),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), 1000),
          ).catch((error) => {
            if (error.message === "Timeout") {
              console.warn("Request timed out");
            }
            return null;
          }),
        ]);
        const data = await response.json();
        if (data) {
          setPersonalizationData(data);
        }
      } catch (error) {
        captureException(error);

        console.error("Error fetching personalization data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="bg-gray-50 pb-8 antialiased dark:bg-gray-900 md:pb-16">
      <div className="mx-auto max-w-screen-xl px-4 py-6 text-center sm:py-12 lg:px-6">
        <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          Unser Alleinstellungsmerkmal
        </h2>
        <p className="text-gray-500 dark:text-gray-400 sm:text-xl">
          Wir verbinden Datenquellen und Technologien, um Ihnen umfassende und
          tiefgehende Einblicke zu liefern.
        </p>
      </div>
      <div className="mx-auto max-w-screen-xl items-center gap-4 px-4 lg:grid lg:grid-cols-4 xl:gap-8 2xl:px-0">
        <div className="grid space-y-8 sm:grid-cols-1 sm:gap-8 sm:space-y-0">
          <div className="rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <svg
              className="mb-2 h-8 w-8 text-black dark:text-webmetic_light"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14"
              />
            </svg>
            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              Analytics
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Wir analysieren umfangreiche Nutzerdaten, um wertvolle Einblicke
              in Ihre Zielgruppe zu gewinnen.
            </p>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <svg
              className="mb-2 h-8 w-8 dark:text-webmetic_light"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 18V8a1 1 0 0 1 1-1h1.5l1.707-1.707A1 1 0 0 1 8.914 5h6.172a1 1 0 0 1 .707.293L17.5 7H19a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Z"
              />
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>

            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              Crawler
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Unsere Technologie erfasst und ergänzt relevante Daten aus
              verschiedenen Webquellen.
            </p>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <svg
              className="mb-2 h-8 w-8 dark:text-webmetic_light"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
                <path d="M16 18L16 20 4 20 4 8 16 8 16 14"></path>
                <path d="M8 6L8 4 20 4 20 16 8 16 8 10"></path>
              </g>
            </svg>
            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              Brand
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Wir ergänzen den bestehenden Datensatz durch markenspezifische
              Inhalte und visuelle Identitäten.
            </p>
          </div>
        </div>
        <div className="col-span-2 items-center justify-center border bg-white p-4 dark:bg-gray-800">
          <Lottie
            animationData={feature_animation_black}
            loop={true}
            className="dark:hidden"
            style={{ width: "100%", height: "auto" }}
          />
          <Lottie
            animationData={feature_animation_white}
            loop={true}
            className="hidden dark:block"
            style={{ width: "100%", height: "auto" }}
          />
          {personalizationData && personalizationData.company_logo_url && (
            <div className="relative">
              <div className="absolute bottom-[59px] left-1/2 hidden translate-x-1/3 transform items-center lg:flex">
                <div className="relative h-1 w-16 overflow-hidden bg-gradient-to-l from-black to-transparent dark:from-white"></div>
                <div className="flex h-24 w-24 items-center justify-center rounded-lg border-2 border-black bg-white dark:border-gray-500">
                  <div
                    id="company-logo"
                    className="h-20 w-20 flex-shrink-0 rounded bg-[length:90%] bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url('${personalizationData.company_logo_url}')`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="grid space-y-8 sm:grid-cols-1 sm:gap-8 sm:space-y-0">
          <div className="rounded-lg border-2 border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <div className="mb-2 flex items-center space-x-2">
              <svg
                className="h-8 w-8 dark:text-webmetic_light"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 50 50"
                fill="currentColor"
              >
                <path d="M24 24V4H5a1 1 0 00-1 1v19h20zM26 24h20V5a1 1 0 00-1-1H26v20zM26 26v20h19a1 1 0 001-1V26H26zM24 26H4v19a1 1 0 001 1h19V26z"></path>
              </svg>
              <svg
                className="h-8 w-8 text-black dark:text-webmetic_light"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.037 21.998a10.313 10.313 0 0 1-7.168-3.049 9.888 9.888 0 0 1-2.868-7.118 9.947 9.947 0 0 1 3.064-6.949A10.37 10.37 0 0 1 12.212 2h.176a9.935 9.935 0 0 1 6.614 2.564L16.457 6.88a6.187 6.187 0 0 0-4.131-1.566 6.9 6.9 0 0 0-4.794 1.913 6.618 6.618 0 0 0-2.045 4.657 6.608 6.608 0 0 0 1.882 4.723 6.891 6.891 0 0 0 4.725 2.07h.143c1.41.072 2.8-.354 3.917-1.2a5.77 5.77 0 0 0 2.172-3.41l.043-.117H12.22v-3.41h9.678c.075.617.109 1.238.1 1.859-.099 5.741-4.017 9.6-9.746 9.6l-.215-.002Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              Microsoft & Google Daten
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Wir integrieren API-Daten von Microsoft Bing und Google, um die
              Daten umfassend zu ergänzen.
            </p>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <svg
              className="mb-2 h-8 w-8 dark:text-webmetic_light"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 8v8m0-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6-2a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm0 0h-1a5 5 0 0 1-5-5v-.5"
              />
            </svg>

            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              AI-basierte LLM
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Mit Large Language Models aggregieren, transformieren und
              verknüpfen wir die Datensätze.
            </p>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800">
            <svg
              className="mb-2 h-8 w-8 dark:text-webmetic_light"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"
              />
            </svg>

            <h3 className="mb-2 text-xl font-semibold dark:text-white">
              API Endpunkt
            </h3>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Nutzen Sie unseren API-Endpunkt, um jederzeit auf den vollständig
              aggregierten Datensatz zuzugreifen.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Usps;
