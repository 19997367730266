import { BlockProps } from "@/components/content/Blocks/types.ts"
import { ContactSectionWithAddressLocation } from "@/types"
import { RichText } from "@/components/content"
import { Company, Address, Phone } from "@/assets/icons"
import { FormEvent, useState } from "react"
import axios from "axios"
import { InfoModal } from "@/components/layouts/Modals/InfoModal"

// Flowbite block https://flowbite.com/blocks/marketing/contact/#contact-section-with-address-location

export const ContactSectionWithAddressLocationBlock = ({block} : BlockProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const API_KEY = import.meta.env.internal_api_key
  const successMessage = 'Nachricht erfolgreich gesendet!'
  const errorMessage = 'Es gab einen Fehler beim Senden der Nachricht. Bitte versuchen Sie es später erneut.'

  const blockFields: ContactSectionWithAddressLocation =
    block as ContactSectionWithAddressLocation
  const termsClasses = 'mt-4 mb-0 text-sm text-gray-500'

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      // Push event to data layer
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "contact_form",
        formType: "contact",
      })

      // Prepare the request body
      const senderName = `Absender: ${firstName} ${lastName}`
      const senderEmail = `E-Mail: ${email}`
      const senderPhone = `Telefon: ${phoneNumber}`
      const content = `Inhalt: ${message}`
      const requestBody = {
        mail: {
          to_email: "support@webmetic.de",
        },
        content: {
          subject: "Kontakt Formular",
          content: [senderName, senderEmail, senderPhone, content].join("<br />"),
        },
      }

      // Send request to your FastAPI endpoint
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/email/send-generic`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": API_KEY,
          },
        },
      )

      if (response.status === 200) {
        setModalMessage(successMessage)
        setShowModal(true)
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneNumber("")
        setMessage("")
      } else {
        setModalMessage(errorMessage)
        setShowModal(true)
      }
    } catch (error) {
      setModalMessage(errorMessage)
      setShowModal(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <section className="bg-platin dark:bg-graphite">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="px-4 mx-auto max-w-screen-sm text-center lg:px-6 mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            {blockFields.title}
          </h2>
          <p className="font-light text-black dark:text-white sm:text-xl">
            {blockFields.text}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:gap-8 lg:grid-cols-3">
          <div className="col-span-2 mb-8 lg:mb-0">
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-black dark:text-white">
                  {blockFields["first-name-label"]}
                </label>
                <input type="text" id="first-name" value={firstName}
                       onChange={(e) => setFirstName(e.target.value)}
                       className="block p-3 w-full text-sm text-black bg-white rounded-lg border border-stone shadow-sm focus:ring-energetic-mint-500 focus:border-energetic-mint-500 dark:bg-carbon dark:placeholder-platin dark:text-white dark:focus:ring-energetic-mint-500 dark:focus:border-energetic-mint-500 dark:shadow-sm-light"
                       placeholder={blockFields["first-name-placeholder"]} required />
              </div>
              <div>
                <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-black dark:text-white">
                  {blockFields["last-name-label"]}
                </label>
                <input type="text" id="last-name" value={lastName}
                       onChange={(e) => setLastName(e.target.value)}
                       className="block p-3 w-full text-sm text-black bg-white rounded-lg border border-stone shadow-sm focus:ring-energetic-mint-500 focus:border-energetic-mint-500 dark:bg-carbon dark:placeholder-platin dark:text-white dark:focus:ring-energetic-mint-500 dark:focus:border-energetic-mint-500 dark:shadow-sm-light"
                       placeholder={blockFields["last-name-placeholder"]} required />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-black dark:text-white">
                  {blockFields["email-label"]}
                </label>
                <input type="email" id="email" value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       className="shadow-sm bg-white border border-stone text-black text-sm rounded-lg focus:ring-energetic-mint-500 focus:border-energetic-mint-500 block w-full p-2.5 dark:bg-carbon dark:placeholder-platin dark:text-white dark:focus:ring-energetic-mint-500 dark:focus:border-energetic-mint-500 dark:shadow-sm-light"
                       placeholder={blockFields["email-placeholder"]} required />
              </div>
              <div>
                <label htmlFor="phone-number"
                       className="block mb-2 text-sm font-medium text-black dark:text-white">
                  {blockFields["phone-label"]}
                </label>
                <input type="number" id="phone-number" value={phoneNumber}
                       onChange={(e) => setPhoneNumber(e.target.value)}
                       className="block p-3 w-full text-sm text-black bg-white rounded-lg border border-stone shadow-sm focus:ring-energetic-mint-500 focus:border-energetic-mint-500 dark:bg-carbon dark:placeholder-platin dark:text-white dark:focus:ring-energetic-mint-500 dark:focus:border-energetic-mint-500 dark:shadow-sm-light"
                       placeholder={blockFields["phone-placeholder"]} required />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block mb-2 text-sm font-medium text-black dark:text-gray-400">
                  {blockFields["message-label"]}
                </label>
                <textarea id="message" rows={6} value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          className="block p-2.5 w-full text-sm text-black bg-white rounded-lg shadow-sm border border-stone focus:ring-energetic-mint-500 focus:border-energetic-mint-500 dark:bg-carbon dark:placeholder-platin dark:text-white dark:focus:ring-energetic-mint-500 dark:focus:border-energetic-mint-500"
                          placeholder={blockFields["message-placeholder"]}></textarea>
                {blockFields.terms?.root && <RichText root={blockFields.terms.root} classes={termsClasses}/>}
              </div>
              <button type="submit" disabled={isSubmitting}
                      className="py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-energetic-mint-500 sm:w-fit hover:bg-energetic-mint-400 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800">
                {isSubmitting ? "Wird gesendet..." : blockFields["send-button"]}
              </button>
            </form>
          </div>
          <div className="grid grid-cols-1 col-span-1 gap-8 text-center sm:grid-cols-2 lg:grid-cols-1">
            <div>
              <div
                className="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg dark:bg-gray-800 lg:h-16 lg:w-16">
                <Company />
              </div>
              <p className="mb-2 text-xl font-bold text-black dark:text-white">
                {blockFields["company-information-label"]}:
              </p>
              <p className="text-black dark:text-white">
                {blockFields["company-name"]} <br />{blockFields["tax-id-text"]}
              </p>
            </div>
            <div>
              <div
                className="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg dark:bg-gray-800 lg:h-16 lg:w-16">
                <Address />
              </div>
              <p className="mb-2 text-xl font-bold text-black dark:text-white">
                {blockFields["address-label"]}
              </p>
              <p className="text-black dark:text-white">
                {blockFields["company-address"]} <br /> {blockFields["zip-code-text"]}
              </p>
            </div>
            <div>
              <div
                className="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg dark:bg-gray-800 lg:h-16 lg:w-16">
                <Phone />
              </div>
              <p className="mb-2 text-xl font-bold text-black dark:text-white">
                {blockFields["call-us-label"]}
              </p>
              <p className="mb-3 text-black dark:text-white">
                {blockFields["call-us-text"]}
              </p>
              <p className="font-semibold text-energetic-mint-600 dark:text-energetic-mint-500">
                {blockFields["phone-number"]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <InfoModal show={showModal} onClose={() => setShowModal(false)} message={modalMessage}/>
    </section>
  )
}
