import Layout from "@/pages/components/Layout";

import tick from "../../../../assets/icons/tick.svg";
import cross from "../../../../assets/icons/cross.svg";
import question_mark from "../../../../assets/icons/question.svg";

import { useSettings } from "@/hooks";
import { Dropdown } from "@/components/ui/dropdown";

const Settings = () => {
  const {
    settings,
    register,
    setValue,
    handleSubmit,
    handleClick,
    isPending,
    isError,
    successfulSave,
    onSubmitSettings,
    disconnectHubSpot,
  } = useSettings();

  return (
    <div className="flex h-full w-full justify-center py-[20px]">
      <div className="w-full rounded-[4px] py-[10px] lg:py-[20px]">
        <p className="mb-[16px] px-4 text-[20px] font-bold">
          HubSpot-Integration
        </p>
        <p className="px-4 font-medium">
          Diese Integration ist mit Ihrem Webmetic-Konto verbunden.{" "}
        </p>

        <div className="my-[16px] h-[1px] w-full bg-gray-300" />
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center gap-[8px]">
            {settings?.enabled && (
              <>
                <img src={tick} alt="Connected" className="h-[30px] w-[30px]" />
                <p className="font-medium">Verbunden mit HubSpot</p>
              </>
            )}
            {!settings?.enabled && (
              <>
                <img
                  src={cross}
                  alt="Connected"
                  className="h-[30px] w-[30px]"
                />
                <p className="font-medium text-[#d32f2f]">Getrennt</p>
              </>
            )}
          </div>
          {settings?.enabled ? (
            <button
              type="button"
              className="flex w-fit cursor-pointer items-center justify-center rounded-[4px] bg-[#EC3131] px-[8px] py-[6px] text-sm text-white transition-all hover:bg-red-400"
              onClick={disconnectHubSpot}
            >
              <p>Trennen</p>
            </button>
          ) : (
            <button
              className="btn"
              type="submit"
              onClick={(e) => handleClick(e)}
            >
              Mit HubSpot verbinden
            </button>
          )}
        </div>
        {settings?.enabled && (
          <>
            <div className="my-[16px] h-[1px] w-full bg-gray-300" />
            <p className="mb-[16px] px-4 text-[20px] font-bold">
              Einstellungen
            </p>
            <div className="my-[16px] h-[1px] w-full bg-gray-300" />

            <div className="mb-4 flex items-center gap-[24px] px-4">
              <p className="w-full md:max-w-[320px]">
                Automatische Synchronisierung erkannter Unternehmen in Ihrem
                HubSpot-Konto{" "}
              </p>

              <label className="me-5 inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  {...register("recognized")}
                  defaultChecked={settings?.sync_to_existing ?? false}
                  className="peer sr-only"
                />
                <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#A7E580] peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700 dark:peer-checked:bg-[#A7E580] rtl:peer-checked:after:-translate-x-full"></div>
              </label>
            </div>

            <div className="mb-4 flex items-center gap-[24px] px-4">
              <div className="relative flex w-full items-center gap-[4px] md:max-w-[320px]">
                <p>Seitenbesuche senden</p>
                <div className="group">
                  <img
                    src={question_mark}
                    alt="question mark"
                    className="h-[20px] w-[20px] cursor-pointer"
                  />

                  <div
                    role="tooltip"
                    className="duration-600 absolute left-[180px] top-[-70px] z-[100] mt-2 w-full max-w-[150px] -translate-x-1/2 transform rounded-lg bg-gray-900 px-2 py-1 text-[9px] font-medium leading-3 text-white opacity-0 transition-opacity group-hover:visible group-hover:opacity-100 dark:bg-gray-700"
                  >
                    Erstellen Sie Engagement Notes auf HubSpot für einzelne
                    Seiten Besuche auf Ihrer Website
                    <div
                      className="tooltip-arrow right-[70px]"
                      data-popper-arrow
                    ></div>
                  </div>
                </div>
              </div>

              <label className="me-5 inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  {...register("pageVisits")}
                  defaultChecked={settings?.page_visit_sync ?? false}
                  className="peer sr-only"
                />
                <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#A7E580] peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700 dark:peer-checked:bg-[#A7E580] rtl:peer-checked:after:-translate-x-full"></div>
              </label>
            </div>

            <div className="my-[16px] h-[1px] w-full bg-gray-300" />

            <div className="flex w-full justify-end px-4">
              <button
                type="submit"
                className={`mt-[20px] flex w-fit cursor-pointer items-center justify-center rounded-[4px] ${isError ? "bg-red-600 hover:bg-red-500" : "btn"} px-[8px] py-[12px] text-white transition-all disabled:bg-gray-300`}
                onClick={handleSubmit(onSubmitSettings)}
                disabled={isPending || !settings?.enabled}
              >
                {!isError ? (
                  <p>
                    {!successfulSave
                      ? "Einstellungen speichern"
                      : "Gespeichert"}
                  </p>
                ) : (
                  <p>Ups... Versuchen Sie es später.</p>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Settings;
