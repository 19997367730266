import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"

export const RTLineBreak: FC<RTElementProps> = () => {
  return (
    <>
      {"\n"}
    </>
  )
}
