import { InView } from "react-intersection-observer";
import CompanyListItem from "../../companies-list/components/companyListItem";
import CompanyListItemSkeleton from "../../companies-list/components/companyListItemSkeleton";
import { CompanyListItemWrapper } from "../../companies-list/components/company-list-item-wrapper";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext";

export const CompaniesList = ({
  flatList,
  total,
  hasNextPage,
  fetchNextPage,
  filtersList,
}: any) => {
  const { selectedCompany, setSelectedCompany } = useCompanyFilterContext();

  return (
    <div className="flex h-full w-64 min-w-64 flex-col dark:bg-gray-900 lg:w-96 lg:min-w-96 lg:overflow-y-auto lg:border-r dark:lg:border-r-gray-600 xl:left-72">
      {filtersList && (
        <div className="bg-gray-200 p-3.5 dark:bg-gray-600 dark:text-white">
          {total} Unternehmen mit dem aktuellen Filter
        </div>
      )}

      <div
        className="flex flex-1 grow flex-col gap-3 overflow-y-scroll bg-[#f0f2f5] pl-4 pr-3 pt-3 dark:bg-gray-900"
        id="company-list"
      >
        {flatList.map((company: any) => (
          <CompanyListItemWrapper
            key={company.company_id}
            onClick={() =>
              setSelectedCompany(
                selectedCompany?.company_id === company.company_id
                  ? null
                  : company,
              )
            }
            isActive={selectedCompany?.company_id === company.company_id}
          >
            <CompanyListItem company={company} />
          </CompanyListItemWrapper>
        ))}
        <InView
          onChange={(inView) => {
            if (inView && hasNextPage) fetchNextPage();
          }}
          disabled={!hasNextPage}
        >
          {hasNextPage && <CompanyListItemSkeleton />}
        </InView>
      </div>
    </div>
  );
};
