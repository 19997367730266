import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

// Initialize Customer.io with EU data center endpoint and In-App Plugin
const cioanalytics = AnalyticsBrowser.load(
  {
    cdnURL: "https://cdp-eu.customer.io",
    writeKey: import.meta.env.VITE_CIO_WRITE_KEY,
  },
  {
    integrations: {
      "Customer.io In-App Plugin": {
        siteId: import.meta.env.VITE_CIO_SITE,
      },
    },
    // page: {
    //   autoTrack: false,
    // },
  },
);

// Attach to window for debugging
if (typeof window !== "undefined") {
  window.cioanalytics = cioanalytics;
}

// Export the initialized instance to use throughout your application
export default cioanalytics;
