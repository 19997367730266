import CompanyProfileDrawer from "@/routes/account/CompanyProfileDrawer";
import ApiDetailsDrawer from "@/routes/account/ApiDetailsDrawer";
import SupportDrawer from "@/routes/account/SupportDrawer";
import UsersDrawer from "@/routes/account/UsersDrawer";
import ProfileDrawer from "@/routes/account/ProfileDrawer";
import AVContractDrawer from "@/routes/account/AVContractDrawer";
import TrackingCodeDrawer from "@/routes/account/TrackingCodeDrawer";
import WebsitesDrawer from "@/routes/account/WebsitesDrawer";
import HubSpotDrawer from "@/routes/account/HubSpotDrawer";
import LexWareDrawer from "@/routes/account/LexWareDrawer";

export const drawers = {
  support: SupportDrawer,
  apiDetails: ApiDetailsDrawer,
  settings: CompanyProfileDrawer,
  users: UsersDrawer,
  profile: ProfileDrawer,
  avContract: AVContractDrawer,
  trackingCode: TrackingCodeDrawer,
  websites: WebsitesDrawer,
  hubspot: HubSpotDrawer,
  lexWare: LexWareDrawer,
};

export const menuItems = [
  { key: "profile", label: "Profil" },
  { key: "settings", label: "Firmenprofil" },
  { key: "users", label: "Benutzer" },
  {
    key: "avContract",
    label: "AV-Vertrag",
    badge: "Neu",
  },
  { key: "apiDetails", label: "API" },
  { key: "trackingCode", label: "Trackingcode" },
  { key: "websites", label: "Websites" },

  {
    key: "integrations",
    label: "Integrations",
    options: [
      { key: "hubspot", label: "HubSpot" },
      { key: "lexWare", label: "Lexware" },
    ],
  },
  { key: "support", label: "Support" },
];
