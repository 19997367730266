import { BlockProps } from "@/components/content/Blocks/types.ts"
import { Images520X389, VisualImageWithHeading } from "@/types";
import { ArrowRight } from "@/assets/icons";
import { Link } from "react-router-dom";
import { getCmsImageUrl } from "@/utils/cms.ts";

// Flowbite block https://flowbite.com/blocks/marketing/hero/#visual-image-with-heading

export const VisualImageWithHeadingBlock = ({ block }: BlockProps) => {
  const blockFields: VisualImageWithHeading = block as VisualImageWithHeading
  const image: Images520X389 = blockFields.image as Images520X389
  return (
    <section className="bg-platin dark:bg-graphite">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1
            className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white heading-text">
            {blockFields.title}
          </h1>
          <p className="max-w-2xl mb-6 font-light text-black lg:mb-8 md:text-lg lg:text-xl dark:text-white">
            {blockFields.text}
          </p>
          <Link to={blockFields["cta-button-link"]}
             className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-black rounded-lg bg-energetic-mint-500 hover:bg-energetic-mint-400 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
            {blockFields["cta-button-label"]}
            <ArrowRight />
          </Link>
          <Link to={blockFields["more-button-link"]}
             className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-carbon dark:hover:bg-carbon dark:focus:ring-gray-800">
            {blockFields["more-button-label"]}
          </Link>
        </div>
        {image.sizes?.image && <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img src={getCmsImageUrl(image.sizes.image.url)} alt={image.alt ?? ""} />
        </div>}
      </div>
    </section>
  )
}
