import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTListItem: FC<RTElementProps> = ({node}: RTElementProps) => {
  return (
    <li className='mt-3 mb-3 pl-2 text-lg leading-6'>
      <RTChildren children={node.children} />
    </li>
  )
}
