import { DeleteIcon, EditIcon } from "@/assets/icons";
import { IconButton } from "@/components";
import { Segment } from "@/types/segment";
import { getDateRange } from "@/utils";
import clsx from "clsx";
import { format } from "date-fns";
import { MouseEvent, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import hubspot from "../../../../assets/icons/hubspot.svg";
import lexware from "../../../../assets/icons/lexWare.jpeg";
import analytics from "@/analytics";

import { IntegrationsContext } from "@/contexts";
import { IntegrationButton } from "./integration-button";
import {
  Integrations,
  useSegmentLinking,
} from "./link-segment-modal/useSegmentlinking";

interface SegmentCardProps {
  segment: Segment;
  onDelete: (id: string) => void;
  handleSetEditableSegment: (segment: Segment) => void;
}

export const SegmentCard = ({
  segment,
  onDelete,
  handleSetEditableSegment,
}: SegmentCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSelected = searchParams.get("segment_id") === segment.id;

  const {
    unlinkSegment,
    linkedHubspotSegments,
    linkedLexwareSegments,
    isLexwarePending,
    isPending,
    syncSegment,
  } = useSegmentLinking();

  const { isHubSpotEnabled, isLexWareEnabled } =
    useContext(IntegrationsContext);

  const handleSelectSegment = () => {
    const currentParams = Object.fromEntries(searchParams.entries());

    if (isSelected) {
      const { segment_id, from_date, to_date, ...rest } = currentParams;
      setSearchParams(rest);
      return;
    }

    const segmentPeriod = getDateRange(segment.period);
    setSearchParams({
      ...currentParams,
      segment_id: segment.id,
      from_date: format(segmentPeriod.from as Date, "yyyy-MM-dd"),
      to_date: format(segmentPeriod.to as Date, "yyyy-MM-dd"),
    });

    // Track segment viewed event
    analytics.track("segment_viewed", {
      segment_id: segment.id,
      segment_label: segment.label,
    });
  };

  const isHubspotLinked = linkedHubspotSegments.some(
    (el) => el.id === segment.id,
  );
  const isLexwareLinked = linkedLexwareSegments.some(
    (el) => el.id === segment.id,
  );

  return (
    <div
      className={clsx(
        "group flex min-h-[50px] w-full cursor-pointer items-center justify-between gap-1 rounded-md border border-gray-200 p-2 transition-all duration-200 dark:border-gray-600 dark:bg-gray-700 dark:text-white",
        isSelected ? "bg-blue-100" : "hover:bg-gray-100",
      )}
      onClick={handleSelectSegment}
    >
      <div className="text-xs font-semibold">{segment.label}</div>

      <div className="flex min-w-fit gap-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
        <IntegrationButton
          isEnabled={isHubSpotEnabled}
          isPending={isPending}
          isSegmentLinked={isHubspotLinked}
          onSync={() => syncSegment(segment.id, Integrations.HUBSPOT)}
          onUnlink={() => unlinkSegment(segment.id, Integrations.HUBSPOT)}
          iconSrc={hubspot}
        />
        <IntegrationButton
          isEnabled={isLexWareEnabled}
          isPending={isLexwarePending}
          isSegmentLinked={isLexwareLinked}
          onSync={() => syncSegment(segment.id, Integrations.LEXWARE)}
          onUnlink={() => unlinkSegment(segment.id, Integrations.LEXWARE)}
          iconSrc={lexware}
        />
        <IconButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            handleSetEditableSegment(segment);
          }}
          icon={<EditIcon />}
        />
        <IconButton
          onClick={() => {
            onDelete(segment.id);
          }}
          icon={<DeleteIcon />}
        />
      </div>
    </div>
  );
};
