import { LocalCompaniesDetails } from "@/types";
import { ReactNode, createContext, useState } from "react";

export interface CompanyDetailsContextProps {
  selectedCompany: LocalCompaniesDetails | null;
  queryId: string;
  setQueryId: (val: string) => void;
  setSelectedCompany: (company: LocalCompaniesDetails | null) => void;
}

export const CompanyDetailsContext = createContext<CompanyDetailsContextProps>({
  queryId: "",
  selectedCompany: null,
  setQueryId: () => {},
  setSelectedCompany: () => {},
});

type CompanyDetailsProviderProps = {
  children: ReactNode;
};

export const CompanyDetailsProvider = ({
  children,
}: CompanyDetailsProviderProps) => {
  const [selectedCompany, setSelectedCompany] =
    useState<LocalCompaniesDetails | null>(null);
  const [queryId, setQueryId] = useState("");

  return (
    <CompanyDetailsContext.Provider
      value={{
        queryId,
        setQueryId,
        selectedCompany,
        setSelectedCompany,
      }}
    >
      {children}
    </CompanyDetailsContext.Provider>
  );
};
