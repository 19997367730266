// components/WelcomeModal.tsx
import { useEffect, useState, useCallback } from "react";
import { CustomFlowbiteTheme, Modal } from "flowbite-react";
import analytics from "@/analytics";

const modalTheme: CustomFlowbiteTheme["modal"] = {
  content: {
    inner:
      "relative flex max-h-[90dvh] flex-col rounded bg-black shadow text-white",
  },
  header: {
    base: "flex items-start justify-between rounded-t pt-3 pr-5",
    close: {
      base: "ml-auto inline-flex items-center bg-transparent p-1.5 text-sm text-white hover:text-platin",
      icon: "h-6 w-6",
    },
  },
  body: {
    base: "flex-1 overflow-auto p-6 pt-0",
  },
  footer: {
    base: "flex items-center space-x-2 p-6 pt-2",
    popup: "",
  },
};

const SmartPageWelcomeModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Track modal open event
  const trackModalOpened = useCallback(() => {
    analytics.track("smartpage_modal_opened", {
      source: "auto_display",
    });
  }, []);

  // Track modal close event
  const trackModalClosed = useCallback((closeMethod: string) => {
    analytics.track("smartpage_modal_closed", {
      close_method: closeMethod,
    });
  }, []);

  // Track demo button click
  const trackDemoStarted = useCallback(() => {
    analytics.track("smartpage_demo_start", {
      source: "smartpage_modal",
    });
  }, []);

  // Track appointment button click
  const trackAppointmentClicked = useCallback(() => {
    analytics.track("smartpage_appointment_clicked", {
      source: "smartpage_modal",
    });
  }, []);

  // Handle modal close with tracking
  const handleCloseModal = useCallback(() => {
    trackModalClosed("close_button");
    setShowModal(false);
  }, [trackModalClosed]);

  // Handle demo start with tracking
  const handleDemoStart = useCallback(() => {
    trackDemoStarted();
    setShowModal(false);
  }, [trackDemoStarted]);

  // Handle appointment button click with tracking
  const handleAppointmentClick = useCallback(() => {
    trackAppointmentClicked();
    window.open("https://calendly.com/webmetic/kurze-besprechung", "_blank");
  }, [trackAppointmentClicked]);

  useEffect(() => {
    // Auto-show the modal on mount
    setShowModal(true);
    // Track modal open when it's displayed
    trackModalOpened();
  }, [trackModalOpened]);

  return (
    <Modal
      show={showModal}
      onClose={handleCloseModal}
      theme={modalTheme}
      size="4xl"
    >
      <div className="flex items-center justify-between px-6 pt-6">
        <h1 className="text-4xl">
          Erleben Sie die Zukunft der <br />
          B2B Website-Personalisierung
        </h1>
        <button
          aria-label="Close"
          className="ml-auto inline-flex items-center bg-transparent p-1.5 text-sm text-white hover:text-platin"
          type="button"
          onClick={handleCloseModal}
        >
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            aria-hidden="true"
            className="h-6 w-6"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <Modal.Body className="pt-2">
        <p className="mb-4 text-lg font-medium">
          Entdecken Sie, wie Webmetic jede Website intelligent macht:
        </p>
        <ul className="mb-4 space-y-2 text-base">
          <li className="flex items-start">
            <span className="mr-2 text-energetic-mint-500">✓</span>
            <span>
              <strong>Erkennt Unternehmen in Echtzeit</strong> – Besucher werden
              sofort identifiziert
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-energetic-mint-500">✓</span>
            <span>
              <strong>Dynamische Inhaltsanpassung</strong> – Texte, Bilder und
              CTAs werden branchenspezifisch
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-energetic-mint-500">✓</span>
            <span>
              <strong>100% DSGVO-konform</strong> – Funktioniert ohne Cookies
              oder Tracking
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 text-energetic-mint-500">✓</span>
            <span>
              <strong>Bewiesene Resultate</strong> – Bis zu 2x höhere
              Conversions
            </span>
          </li>
        </ul>
        <div className="mt-5 rounded-lg bg-graphite p-3">
          <p className="mb-2 font-medium text-energetic-mint-500">
            🔍 Jetzt selbst testen:
          </p>
          <p className="text-sm">
            Wählen Sie ein Unternehmen aus dem Dropdown-Menü und beobachten Sie,
            <br />
            wie sich die gesamte Website intelligent anpasst – genau wie bei
            Ihren echten Besuchern später.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="mr-4 inline-flex items-center justify-center rounded border border-energetic-mint-500 bg-black px-5 py-3 text-center text-base font-medium text-energetic-mint-500 hover:border-energetic-mint-400 hover:bg-graphite hover:text-energetic-mint-400"
          onClick={handleDemoStart}
        >
          Demo starten
          <svg
            className="-mr-1 ml-2 h-3.5 w-3.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          className="inline-flex items-center justify-center rounded bg-energetic-mint-500 px-5 py-3 text-center text-base font-medium text-black hover:bg-energetic-mint-400"
          onClick={handleAppointmentClick}
        >
          Persönliche Beratung vereinbaren
          <svg
            className="-mr-1 ml-2 h-3.5 w-3.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SmartPageWelcomeModal;
