import Layout from "@/pages/components/Layout"

export const Loading = () => {
  return (
    <Layout>
      <div
        className="flex h-full w-full items-center justify-center rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
        <div
          className="animate-pulse rounded-full bg-blue-200 px-3 py-1 text-center text-xs font-medium leading-none text-blue-800 dark:bg-blue-900 dark:text-blue-200">
          loading...
        </div>
      </div>
    </Layout>
  )
}
