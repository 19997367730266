import { useState } from "react";

import { LocalCompaniesDetails } from "@/types";
import { CompanyIntegrations } from "./company-integrations";
import { CompanySocials } from "./company-socials";

function CompaniesContent({
  companiesDetails,
}: {
  companiesDetails: LocalCompaniesDetails;
}) {
  const [showMore, setShowMore] = useState(false);

  const renderField = (
    label: string,
    content: React.ReactNode,
    id?: string,
  ) => {
    if (!content) return null;
    return (
      <div className="">
        <span className="text-sm font-bold dark:text-white">{label}</span>{" "}
        <span className="text-sm dark:text-white" id={id}>
          {content}
        </span>
      </div>
    );
  };

  const renderBlock = (fields: React.ReactNode) => {
    return <div className="mb-2 last:mb-0">{fields}</div>;
  };
  return (
    <>
      <div
        className="flex w-full flex-col gap-4 rounded-md border border-gray-200 p-4 md:flex-row"
        id="company-content"
      >
        <div className="flex flex-col gap-[8px]">
          {companiesDetails.company_logo_url && (
            <div
              id="company-logo"
              className="h-28 w-28 flex-shrink-0 rounded border border-gray-200 bg-white bg-[length:90%] bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${companiesDetails.company_logo_url})`,
              }}
            />
          )}

          <CompanyIntegrations companiesDetails={companiesDetails} />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex items-center justify-between gap-[10px]">
            <h2 className="text-xl font-bold dark:text-white" id="company-name">
              {companiesDetails.company_name}
            </h2>

            <CompanySocials companiesDetails={companiesDetails} />
          </div>
          {companiesDetails.address && (
            <div className="mb-3 text-sm dark:text-white">
              {companiesDetails.address} <br />
              {companiesDetails.postal_code} {companiesDetails.city}
            </div>
          )}

          {renderBlock(
            <>
              {renderField(
                "Website:",
                companiesDetails.company_url && (
                  <a
                    href={`https://${companiesDetails.company_url}`}
                    className="break-words text-blue-700 dark:text-blue-100"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {companiesDetails.company_url}
                  </a>
                ),
                "company-website",
              )}
              {renderField(
                "E-Mail:",
                companiesDetails.email_address && (
                  <a
                    href={`mailto:${companiesDetails.email_address}`}
                    className="break-words text-blue-700 dark:text-blue-100"
                  >
                    {companiesDetails.email_address}
                  </a>
                ),
                "company-email",
              )}
              {renderField(
                "Rufnummer:",
                companiesDetails.phone_number && (
                  <a
                    href={`tel:${companiesDetails.phone_number}`}
                    className="text-blue-700 hover:underline dark:text-blue-100"
                  >
                    {companiesDetails.phone_number.replace(/^(\+49)/, "$1 ")}
                  </a>
                ),
                "company-phone",
              )}
            </>,
          )}

          {renderBlock(
            renderField(
              "Branchen:",
              companiesDetails.nace_codes &&
                companiesDetails.nace_codes.length > 0 && (
                  <div className="mt-1 flex flex-wrap gap-2">
                    {Array.from(
                      new Set(
                        companiesDetails.nace_codes.map(
                          (code: { description_de: string }) =>
                            code.description_de,
                        ),
                      ),
                    ).map((industry, index) => (
                      <span
                        key={index}
                        className="rounded bg-webmetic_light-100 px-2.5 py-0.5 text-xs font-medium dark:bg-blue-900 dark:text-blue-300"
                      >
                        {industry}
                      </span>
                    ))}
                  </div>
                ),
              "company-industry",
            ),
          )}

          {renderBlock(
            renderField(
              "Kurzbeschreibung:",
              companiesDetails.short_description_de,
              "company-short-description",
            ),
          )}

          {(companiesDetails.vat_id ||
            companiesDetails.full_description_de) && (
            <>
              <button
                className={`flex cursor-pointer items-center gap-1 text-sm font-semibold text-blue-700 hover:underline dark:text-blue-100 ${showMore ? "hidden" : ""}`}
                onClick={() => setShowMore(true)}
              >
                Weitere Details
                <svg
                  aria-hidden="true"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="m19 9-7 7-7-7"
                  />
                </svg>
              </button>

              {showMore && (
                <>
                  {renderBlock(
                    <>
                      {renderField(
                        "Fax:",
                        companiesDetails.fax_number,
                        "company-fax",
                      )}
                      {renderField(
                        "Direktoren:",
                        companiesDetails.directors &&
                          companiesDetails.directors.join(", "),
                        "company-directors",
                      )}
                      {renderField(
                        "Handelsregister:",
                        (companiesDetails.registration_number ||
                          companiesDetails.registration_court) &&
                          `${companiesDetails.registration_number || ""} ${companiesDetails.registration_court ? `(${companiesDetails.registration_court})` : ""}`.trim(),
                        "company-registration",
                      )}
                      {renderField(
                        "USt-IdNr.:",
                        companiesDetails.vat_id,
                        "company-business-id",
                      )}
                    </>,
                  )}

                  {renderBlock(
                    renderField(
                      "Beschreibung:",
                      companiesDetails.full_description_de,
                      "company-description",
                    ),
                  )}

                  <button
                    className="mt-2 flex cursor-pointer items-center gap-1 text-sm font-semibold text-blue-700 hover:underline dark:text-blue-100"
                    onClick={() => setShowMore(false)}
                  >
                    Ausblenden
                    <svg
                      className="rotate-180"
                      aria-hidden="true"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CompaniesContent;
