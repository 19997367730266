import { useGetHubspotAuthLinkQuery } from "@/hooks";
import { useHubSpotSettings } from "@/hooks/api/queries/useHubspotSettings";
import Settings from "@/pages/integrations/hubspot/setting";
import { HubspotWelcome } from "@/pages/integrations/hubspot/welcome";
import { useAuth } from "@/provider/authProvider";
import { Drawer, IconButton, Typography } from "@material-tailwind/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function HubSpotDrawer({ open, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const { data: authLink, isSuccess } =
    useGetHubspotAuthLinkQuery(isAuthenticated);
  const { data: settings, isSuccess: isSettingsSuccess } =
    useHubSpotSettings(isAuthenticated);

  const [searchParams] = useSearchParams();

  const handleClick = (e) => {
    if (authLink) {
      e.preventDefault();
      window.location.href = authLink.auth_url;
    }
  };

  const handleClose = () => {
    searchParams.delete("hubspot");

    onClose();

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg dark:border-l-gray-600 dark:bg-gray-800 sm:w-full md:w-2/3 lg:w-1/2 2xl:w-1/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60 ",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-b-gray-600">
        <div className="flex flex-col gap-1">
          <Typography
            variant="h4"
            color="blue-gray"
            className="dark:text-green-400"
          >
            {settings?.enabled ? "HubSpot-Einstellung" : "HubSpot"}
          </Typography>
        </div>
        <IconButton variant="text" color="blue-gray" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>

      {settings?.enabled && isSettingsSuccess ? (
        <Settings />
      ) : (
        <HubspotWelcome handleClick={handleClick} />
      )}
    </Drawer>
  );
}

export default HubSpotDrawer;
