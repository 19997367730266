import { Customer1, Customer2, Customer3, Customer4, Customer5, Customer6, Customer7, Customer8 } from "./customer_logos";
import { forwardRef } from "react";

export const Customers  = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section className="max-w-[70rem] w-full mx-auto" id="customers" ref={ref}>
      <div className="py-8 lg:py-16 mx-auto">
        <h2
          id="customers-title"
          className="mb-8 text-3xl font-bold tracking-tight leading-tight text-center text-neutral-900"
        >
          100+ der weltweit besten Marken vertrauen auf Webmetic
        </h2>
        <div
          id="customer-logos"
          className="grid grid-cols-2 gap-0.5 text-gray-500 md:grid-cols-3 lg:grid-cols-4"
        >
          <a href="#" className="flex justify-center items-center">
            <Customer1 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer2 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer3 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer4 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer5 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer6 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer7 />
          </a>
          <a href="#" className="flex justify-center items-center">
            <Customer8 />
          </a>
        </div>
      </div>
    </section>
  )
})
