import { ContextsType } from "@/pages/smartLandingPage/drawer/types";

export const messeMuenchenContext: ContextsType = {
  unternehmensbasis: [
    { type: "Firmenname", data: "Messe München GmbH" },
    { type: "Firmenname kurz", data: "Messe München" },
    { type: "Mitarbeiteranzahl", data: "500-1000" },
  ],
  kontakt_standort: [
    { type: "Adresse", data: "Am Messesee 2" },
    { type: "PLZ", data: "81829" },
    { type: "Stadt", data: "München" },
    { type: "Bundesland", data: "Bayern" },
    { type: "Land", data: "Deutschland" },
    { type: "Telefon", data: "+498994920720" },
    { type: "Fax", data: "+498994920729" },
    { type: "E-Mail", data: "info@messe-muenchen.de" },
    { type: "Website", data: "messe-muenchen.de" },
  ],
  management_rechtliches: [
    { type: "Geschäftsführer", data: "Dr. Reinhard Pfeiffer, Stefan Rummel" },
    { type: "USt-IdNr.", data: "DE129424028" },
    { type: "Steuernummer", data: "143/161/90414" },
    { type: "Registernummer", data: "HRB 6311" },
    { type: "Registergericht", data: "Registergericht München" },
  ],
  geschaeftstaetigkeit: [
    {
      type: "Hauptbranche",
      data: "Messe-, Ausstellungs- und Kongressveranstalter",
    },
    { type: "NACE-Codes", data: "68.20, 70.10, 82.30" },
    {
      type: "Kurzbeschreibung",
      data: "Veranstalter von Messen und Ausstellungen, bietet Mietmöglichkeiten für Veranstaltungsräume, Eventplanung und Marketingdienstleistungen an.",
    },
  ],
  online_praesenz: [
    { type: "Facebook", data: "https://facebook.com/messemuenchen" },
    { type: "Instagram", data: "https://instagram.com/messe_muenchen" },
    { type: "LinkedIn", data: "https://linkedin.com/company/messemuenchen" },
    { type: "YouTube", data: "https://youtube.com/user/MesseMuenchen" },
    { type: "Twitter", data: "https://twitter.com/@messemuenchen" },
  ],
  marke_kommunikation: [
    { type: "Sprach-Stil", data: "Formal/professionell" },
    { type: "Tonalität", data: "Autoritativ, Vertrauenswürdig, Seriös" },
    { type: "Anrede", data: "Formal" },
    { type: "Brand-Konsistenz", data: "Hoch" },
    { type: "Content-Dichte", data: "Ausgewogen" },
  ],
  zielgruppen_marketing: [
    {
      type: "Primäre Zielgruppe",
      data: "Internationale Fachbesucher und Aussteller aus verschiedenen Branchen",
    },
    {
      type: "Sekundäre Zielgruppe",
      data: "Allgemeine Öffentlichkeit und potenzielle Mitarbeiter",
    },
    {
      type: "Schlüsselbegriffe",
      data: "Weltleitmessen, Internationales Netzwerk, Innovation, Nachhaltigkeit, Veranstaltungskalender",
    },
    {
      type: "Call-to-Actions",
      data: "Mehr erfahren, Stellenanzeigen, Ihre Messeteilnahme",
    },
    {
      type: "USP",
      data: "Internationalität/Netzwerk, Expertise/Wissen, Nachhaltigkeit/Ethik",
    },
    {
      type: "Logo",
      data: '<img src="https://webmetic.de/brand/messe-muenchen_de/messe-muenchen_de_dark_logo.png" alt="logo">',
      html: true,
    },
  ],
  nutzerdaten: [
    { type: "Nutzerstatus", data: "wiederkehrend" },
    { type: "Besuchsanzahl", data: "5" },
    { type: "Letzter Besuch", data: "2025-03-01" },
    {
      type: "Besuchte Unterseiten",
      data: "Veranstaltungen, Aussteller-Info, Locations",
    },
    {
      type: "Interessensgebiete",
      data: "Technologiemessen, Veranstaltungsplanung",
    },
    { type: "Interaktionsrate", data: "hoch" },
    { type: "Konversionsphase", data: "Überlegungsphase" },
    { type: "Letzte Interaktion", data: "Formular-Anfrage" },
    { type: "Nutzer-Präferenzen", data: "DE, Dark Mode, Newsletter-Abonnent" },
  ],
};
