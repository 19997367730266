import Layout from "@/pages/components/Layout";
import { useGetPageQuery } from "@/hooks/api/queries/useGetPageQuery.ts";
import { Loading, RichText, UnderConstruction } from "@/components/content";

export const Page = ({page}: {page: string}) => {
  const { data: pageContent, isError } = useGetPageQuery(page)

  if (isError) {
    return (
      <Layout>
        <UnderConstruction />
      </Layout>
    )
  }

  if (!pageContent) {
    return <Loading />
  }
  return (
    <Layout>
      <RichText root={pageContent.content.root} />
    </Layout>
  )
}
