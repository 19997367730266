import { ContextsType } from "@/pages/smartLandingPage/drawer/types";
export const wuerthContext: ContextsType = {
  unternehmensbasis: [
    { type: "Firmenname", data: "Adolf Würth GmbH & Co. KG" },
    { type: "Firmenname kurz", data: "Würth" },
    { type: "Mitarbeiteranzahl", data: "75.000-100.000" },
  ],
  kontakt_standort: [
    { type: "Adresse", data: "Reinhold-Würth-Straße 12-17" },
    { type: "PLZ", data: "74653" },
    { type: "Stadt", data: "Künzelsau-Gaisbach" },
    { type: "Bundesland", data: "Baden-Württemberg" },
    { type: "Land", data: "Deutschland" },
    { type: "Telefon", data: "+49794015-0" },
    { type: "Fax", data: "+49794015-1000" },
    { type: "E-Mail", data: "info@wuerth.com" },
    { type: "Website", data: "wuerth.de" },
  ],
  management_rechtliches: [
    {
      type: "Geschäftsführer",
      data: "Würth-Verwaltungsgesellschaft mbH, vertreten durch Rainer Bürkert, Torsten Elias, Robert Friedmann, Norbert Heckmann, Bernd Herrmann, Thomas Klenk, Jens Neumann, Ralf Schaich, Frank Schneider, Dr. Reiner Specht, Thomas Wahl",
    },
    { type: "USt-IdNr.", data: "DE 146 280 061" },
    { type: "Registernummer", data: "HRA 590261" },
    { type: "Registergericht", data: "Amtsgericht Stuttgart" },
  ],
  geschaeftstaetigkeit: [
    {
      type: "Hauptbranche",
      data: "Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
    },
    { type: "NACE-Codes", data: "46.74, 25.94, 47.52" },
    {
      type: "Kurzbeschreibung",
      data: "Weltmarktführer in der Entwicklung, der Herstellung und dem Vertrieb von Montage- und Befestigungsmaterial für Handwerk und Industrie.",
    },
  ],
  online_praesenz: [
    { type: "Facebook", data: "https://facebook.com/wuerth.germany" },
    { type: "Instagram", data: "https://instagram.com/wuerth_germany" },
    { type: "LinkedIn", data: "https://linkedin.com/company/wuerth-germany" },
    { type: "YouTube", data: "https://youtube.com/wuerth" },
    { type: "TikTok", data: "https://tiktok.com/@wuerth_germany" },
    { type: "Xing", data: "https://xing.com/pages/wuerth-germany" },
  ],
  marke_kommunikation: [
    { type: "Sprach-Stil", data: "Faktisch/technisch" },
    { type: "Tonalität", data: "Seriös, Vertrauenswürdig, Fachkundig" },
    { type: "Anrede", data: "Formal" },
    { type: "Brand-Konsistenz", data: "Hoch" },
    { type: "Content-Dichte", data: "Ausgewogen" },
  ],
  zielgruppen_marketing: [
    {
      type: "Primäre Zielgruppe",
      data: "Handwerk und Industrie (Gewerbetreibende)",
    },
    {
      type: "Sekundäre Zielgruppe",
      data: "Potenzielle Mitarbeiter, öffentliche Institutionen",
    },
    {
      type: "Schlüsselbegriffe",
      data: "Montage- und Befestigungsmaterial, Multi-Kanal-Strategie, Digitalisierung, Produktinnovation, E-Procurement",
    },
    {
      type: "Call-to-Actions",
      data: "Jetzt registrieren, Mehr erfahren, Online-Shop besuchen",
    },
    {
      type: "USP",
      data: "Qualität/Premium-Angebot, Expertise/Wissen, Service/Support",
    },
    {
      type: "Logo",
      data: '<img src="https://webmetic.de/brand/wuerth_de/wuerth_de_dark_logo.png" alt="logo">',
      html: true,
    },
  ],
  nutzerdaten: [
    { type: "Nutzerstatus", data: "wiederkehrend" },
    { type: "Besuchsanzahl", data: "12" },
    { type: "Letzter Besuch", data: "2025-03-24" },
    { type: "Besuchte Unterseiten", data: "Datenschutz, Kontakt, Impressum" },
    { type: "Interessensgebiete", data: "Lead Generierung" },
    { type: "Interaktionsrate", data: "mittel" },
    { type: "Konversionsphase", data: "Entscheidungsphase" },
    { type: "Letzte Interaktion", data: "Anfrage" },
    { type: "Nutzer-Präferenzen", data: "DE, Light Mode" },
  ],
};
