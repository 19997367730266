import { forwardRef } from "react";

export const Features = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="features" className="bg-gray-50" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-center sm:py-16">
        <h2
          id="features-title"
          className="mx-auto mb-12 max-w-[40rem] text-4xl font-extrabold tracking-tight text-neutral-900"
        >
          Wie statische Landing Pages Ihr Wachstum bremsen
        </h2>
        <div
          id="feature-list"
          className="space-y-8 md:grid md:grid-cols-2 md:gap-5 md:space-y-0 lg:grid-cols-3"
        >
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature1"
          >
            <p className="text-base">01</p>
            <h3 className="text-3xl font-normal">
              Unspezifische Projektanzeigen schrecken ab
            </h3>
            <p className="text-base font-light">
              Ein IT-Experte auf Senior-Level erhält dieselben Projektangebote
              wie ein Junior-Grafikdesigner - und muss sich erst mühsam passende
              Projekte suchen.
            </p>
            <div>
              <p className="text-base font-light">Resultat</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Spezialisten finden nicht die richtigen Angebote und springen ab
                </li>
                <li className="mb-3.5">
                  Recruiter erhalten unpassende Bewerbungen → längere
                  Besetzungszeiten
                </li>
                <li className="mb-3.5">
                  Hochqualifizierte Freelancer wechseln zu Plattformen mit
                  gezielteren Angeboten
                </li>
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature2"
          >
            <p className="text-base">02</p>
            <h3 className="text-3xl font-normal">
              Marketing-Budget wird ineffizient genutzt
            </h3>
            <p className="text-base font-light">
              Freelance.de investiert in Google Ads, LinkedIn-Kampagnen und
              Newsletter, um Freelancer und Unternehmen zu gewinnen.
              <br />
              <br />
              Doch wenn alle auf dieselbe Landingpage geleitet werden, geht
              Potenzial verloren.
            </p>
            <div>
              <p className="text-base font-light">Resultat</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Werbekosten steigen, während die Conversion-Rate niedrig
                  bleibt
                </li>
                <li className="mb-3.5">
                  Unternehmen finden keine relevanten Freelancer und verlassen
                  die Plattform
                </li>
                <li className="mb-3.5">
                  Wichtige Daten über Freelancer-Interessen werden nicht genutzt
                </li>
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature3"
          >
            <p className="text-base">03</p>
            <h3 className="text-3xl font-normal">
              Sie verpassen wertvolle Vermittlungen
            </h3>
            <p className="text-base font-light">
              Die Vielfalt an Projekten, Branchen und Freelancern kann ohne
              smartes Konzept zur Herausforderung statt zur Chance werden.
            </p>
            <div>
              <p className="text-base font-light">Resultat</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Unternehmen müssen mühsam durch irrelevante Freelancer-Profile
                  klicken → weniger Vermittlungen
                </li>
                <li className="mb-3.5">
                  Hochqualifizierte Freelancer finden keine passenden Projekte
                  und wechseln zur Konkurrenz
                </li>
                <li className="mb-3.5">
                  Wettbewerber mit besseren Filteroptionen gewinnen mehr Nutzer
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
