import { useAuth } from "@/provider/authProvider";
import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center text-center">
      <h1 className="text-4xl font-bold">404 - Seite nicht gefunden</h1>
      <p className="mb-[20px] mt-2 text-lg">
        Die gesuchte Seite existiert nicht.
      </p>
      {!isAuthenticated ? (
        <Link to="/" className="btn">
          Zur Startseite
        </Link>
      ) : (
        <Link to="/dashboard" className="btn">
          Zum Dashboard
        </Link>
      )}
    </div>
  );
};

export default NotFoundPage;
