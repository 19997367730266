import customer1 from "./customer_logos/customer1.png";
import customer2 from "./customer_logos/customer2.png";
import customer3 from "./customer_logos/customer3.png";
import customer4 from "./customer_logos/customer4.png";
import customer5 from "./customer_logos/customer5.png";
import customer6 from "./customer_logos/customer6.png";
import customer7 from "./customer_logos/customer7.png";
import { forwardRef } from "react";

export const Customers = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section className="mx-auto w-full max-w-[70rem]" id="customers" ref={ref}>
      <div className="mx-auto py-8 lg:py-16">
        <h2
          id="customers-title"
          className="mb-8 text-center text-3xl font-bold leading-tight tracking-tight text-neutral-900"
        >
          100+ der weltweit besten Marken vertrauen auf Webmetic
        </h2>
        <div id="customer-logos" className="flex flex-wrap justify-center gap-x-10 gap-y-1 px-10">
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer1}
              alt="customer1"
              className="h-[100px] w-[178px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer2}
              alt="customer2"
              className="h-[48px] w-[158px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer3}
              alt="customer3"
              className="h-[120px] w-[213px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer4}
              alt="customer4"
              className="h-[55px] w-[194px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer5}
              alt="customer5"
              className="h-[80px] w-[143px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer6}
              alt="customer6"
              className="h-[60px] w-[220px]"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src={customer7}
              alt="customer7"
              className="h-[59px] w-[170px]"
            />
          </a>
        </div>
        <p className="text-center text-sm">
          (Musterdarstellung von Referenzen)
        </p>
      </div>
    </section>
  );
});
