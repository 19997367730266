import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import AuthProvider from "./provider/authProvider";

import AppointmentConfirmationPage from "./pages/confirmation/termin-bestaetigung/";
import ContactPage from "./pages/contact";
import DataPrivacyPage from "./pages/dataprivacy";
import FeaturesPage from "./pages/features";
import LpDmexco from "./pages/landingpage";
import PricingPage from "./pages/pricing";
import StartPage from "./pages/startpage";

import Agb from "./pages/legal/agb";
import Datenschutzerklaerung from "./pages/legal/datenschutzerklaerung";
import Impressum from "./pages/legal/impressum";

import Login from "./pages/authentication/Login";
import SendPassword from "./pages/authentication/SendPassword";
import companiesFilterLoader from "./routes/companies/companies-filter/companies-filter.loader";
import CompaniesFilterView from "./routes/companies/companies-filter/companies-filter.view";
import CompaniesListView from "./routes/companies/companies-list/companies-list.view";
import CompanyDetails from "./routes/companies/company-details";
import Dashboard from "./routes/dashboard/dashboard.view";

// Settings
import { AppLayout } from "./components";
import {
  CompanyDetailsProvider,
  CompanyFilterProvider,
  IntegrationsProvider,
} from "./contexts";
import HubSpotPage from "./pages/integrations/hubspot";
import RegisterModal from "./pages/modal/RegisterModal";
import AccountSettings from "./routes/account/CompanyProfileDrawer";
import AccountSupport from "./routes/account/SupportDrawer";
import AccountApiDetails from "./routes/account/TrackingCodeDrawer";
import {
  authenticatedLoader,
  dashboardLoader,
} from "./routes/dashboard/dashboard.loader";
// import { BlogPage } from "@/pages/blog/Blog";
// import { BlogPostPage } from "@/pages/blog/BlogPostPage";
// import { BlogPostPreviewPage } from "@/pages/blog/BlogPostPreviewPage";
import { Page } from "@/components/content/Page/Page";
import { PagePreview } from "@/components/content/Page/PagePreview";
import { SmartLandingPage } from "@/pages/smartLandingPage/SmartLandingPage";
import LexWarePage from "./pages/integrations/lexware";
import NotFoundPage from "./pages/notFound";

const queryClient = new QueryClient({
  defaultOptions: {},
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
  },
  {
    path: "/dmexco",
    element: <LpDmexco />,
  },
  {
    path: "/kontakt-dev",
    element: <Page page="contact" />,
  },
  {
    path: "/kontakt",
    element: <ContactPage />,
  },
  {
    path: "/about",
    element: <Page page="about-us" />,
  },
  {
    path: "/help-center",
    element: <Page page="help-center" />,
  },
  {
    path: "/impressum",
    element: <Impressum />,
  },
  {
    path: "/datenschutzerklaerung",
    element: <Datenschutzerklaerung />,
  },
  {
    path: "/agb",
    element: <Agb />,
  },
  {
    path: "/preise",
    element: <PricingPage />,
  },
  {
    path: "/funktionen",
    element: <FeaturesPage />,
  },
  {
    path: "/datenschutz",
    element: <DataPrivacyPage />,
  },
  {
    path: "/termin-bestaetigung",
    element: <AppointmentConfirmationPage />,
  },
  {
    path: "/register",
    element: <RegisterModal />,
    loader: authenticatedLoader,
  },
  {
    path: "/page-preview",
    element: <PagePreview />,
  },
  {
    path: "/dynamic-landing-page",
    element: <Navigate to="/smart-landing-page" replace />,
  },
  {
    path: "/smart-landing-page",
    element: <SmartLandingPage />
  },
  // {
  //   path: "/blog",
  //   children: [
  //     { index: true, element: <BlogPage /> },
  //     {
  //       path: ":id",
  //       element: <BlogPostPage />,
  //     },
  //     {
  //       path: 'preview',
  //       element: <BlogPostPreviewPage />
  //     }
  //   ]
  // },
  {
    path: "/login",
    element: <Login />,
    loader: authenticatedLoader,
  },
  {
    path: "/send_password",
    element: <SendPassword />,
  },
  {
    path: "/integrations/hubspot/confirm",
    element: <HubSpotPage />,
    loader: dashboardLoader,
  },
  {
    path: "/integrations/lexware-office/confirm",
    element: <LexWarePage />,
    loader: dashboardLoader,
  },
  {
    path: "dashboard",
    loader: dashboardLoader,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: ":domain",
        element: <CompaniesFilterView />,
        loader: companiesFilterLoader(queryClient),
        children: [
          { index: true, element: <Navigate to="companies" replace /> },
          {
            path: "companies",
            element: <CompaniesListView />,
            children: [
              {
                path: ":company_details_id",
                element: <CompanyDetails />,
              },
            ],
          },
        ],
        // children: [
        //   { index: true, element: <Navigate to="companies" replace /> },
        //   {
        //     path: "companies",
        //     element: <CompaniesListView />,
        //     children: [
        //       {
        //         path: ":company_details_id",
        //         element: <CompanyDetails />,
        //         loader: companiesDetailsLoader(queryClient),
        //       },
        //     ],
        //   },
        // ],
      },
    ],
  },
  {
    path: "account",
    element: <AccountSettings />,
    children: [
      {
        path: "api",
        element: <AccountApiDetails />,
      },
      {
        path: "settings",
        element: <AccountSettings />,
      },
      {
        path: "support",
        element: <AccountSupport />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <NotFoundPage />,
  },
]);

function App() {
  return (
    //<ThemeProvider>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <IntegrationsProvider>
          <CompanyDetailsProvider>
            <CompanyFilterProvider>
              <AppLayout>
                <RouterProvider
                  router={router}
                  future={{
                    v7_relativeSplatPath: true,
                    v7_startTransition: true,
                  }}
                />
              </AppLayout>
            </CompanyFilterProvider>
          </CompanyDetailsProvider>
        </IntegrationsProvider>
      </QueryClientProvider>
    </AuthProvider>
    //</ThemeProvider>
  );
}

export default App;
