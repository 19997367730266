import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { getCmsImageUrl } from "@/utils/cms.ts"

export const RTImage: FC<RTElementProps> = ({node}: RTElementProps) => {
  const alt = node.value!.alt ?? ''
  const caption = node.value!.caption ?? ''
  return (
    <figure className='mb-8 mt-8'>
      <img
        src={getCmsImageUrl(node.value!.url)}
        alt={alt}
      />
      <figcaption className='mt-4 text-center text-base'>{caption}</figcaption>
    </figure>
  )
}
