import { PersonalizationsType } from "@/pages/smartLandingPage/drawer/types";

export const wuerthPersonalizations: PersonalizationsType = {
  hero: [
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "USP → Qualität/Premium-Angebot",
      ],
      old: "Mehr Umsätze - dank KI-gestützter smart Landing Pages",
      new: "Mehr Leads, mehr Umsätze - dank smarter, KI-gestützter Landing Pages",
      element: "hero-title",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Interessensgebiete → Lead Generierung",
      ],
      old: "Ihre Besucher erwarten relevante Inhalte - und sehen doch immer dieselbe generische Seite.",
      new: "Begeistern Sie Ihre Kunden mit individualisierter Ansprache und ganz auf sie zugeschnittenen Inhalten.",
      element: "hero-text1",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Interessensgebiete → Lead Generierung",
      ],
      old: "Personalisierte Landing Pages berücksichtigen Branche, Standort und Verhalten Ihrer Besucher.",
      new: "Dank personalisierter, smarter Landing Pages präsentieren Sie Ihre Produkte gezielt für jede Branche – von Elektrohandwerk über Maschinenbau bis hin zu Bauunternehmen. So erreichen Sie die richtigen Entscheider zur richtigen Zeit und steigern Ihren Umsatz.",
      element: "hero-text2",
      segment: "fachliche_ausrichtung",
    },
    {
      category:
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
      new: "Konstruktion",
      element: "hero-image",
      segment: "images",
    },
  ],
  customers: [
    {
      category: ["Land → Deutschland", "Stadt → Künzelsau-Gaisbach"],
      old: "Our customers worldwide trust us",
      new: "100+ der weltweit besten Marken vertrauen auf Webmetic",
      element: "customers-title",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category:
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
      new: "Wuerth-Logos (Hilti, Bosch, Makita etc.)",
      element: "customer-logos",
      segment: "images",
    },
  ],
  features: [
    {
      category: [],
      old: "Wie statische Landing Pages Ihrem Unternehmen schaden",
      new: "Wie statische Landing Pages Ihr Wachstum bremsen",
      element: "features-title",
    },
    {
      category: [],
      old: "Generisch konvertiert nicht...",
      new: "Niedrige Conversion-Rate",
      element: "feature1",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Ihre Interessenten kommen aus unterschiedlichen Ländern und Branchen und haben ganz individuelle Bedürfnisse - die eine einzige Landing Page gar nicht abdecken kann.",
      new: "Ein Bauleiter mit Großbestellung sieht dieselben Produkte wie ein Handwerksbetrieb, der nur ein einzelnes Werkzeug braucht.",
      element: "feature1",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "USP → Service/Support",
      ],
      old: "Resultat: Wenig Relevanz, hohe Absprungraten und verlorene Neukunden",
      new: "Resultat:\n\nIndustrie-Kunden finden keine passenden Produktlösungen → springen ab\nHandwerker erhalten zu viele irrelevante Optionen → brechen Kaufprozess ab\nPotenzielle Großkunden werden nicht gezielt angesprochen → gehen zur Konkurrenz",
      element: "feature1",
    },
    {
      category: [],
      old: "Potenzial bleibt ungenutzt...",
      new: "Ineffiziente Marketing-Ausgaben",
      element: "feature2",
    },
    {
      category: [
        "Firmenname → Würth",
        "Call-to-Actions → Online-Shop besuchen",
      ],
      old: "Jeder Klick kostet Ihr Unternehmen bares Geld, aber Ihre Conversion-Rates bleiben stets im niedrigen einstelligen Bereich.",
      new: "Würth investiert hohe Summen in Google Ads und B2B-Marketing, doch wenn alle auf dieselbe Seite geleitet werden, verpufft das Potenzial.",
      element: "feature2",
    },
    {
      category: [
        "Schlüsselbegriffe → Montage- und Befestigungsmaterial, Multi-Kanal-Strategie",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Resultat: Ohne zielgerichtetes Messaging bleibt Ihr Marketing-ROI weit unter seinen Möglichkeiten",
      new: "Resultat:\n\nHohe Werbekosten, aber niedrige Conversion-Rate\nInteressierte Besucher springen ab, weil Inhalte nicht spezifisch genug sind\nInsights über Besucherverhalten bleiben ungenutzt, statt zur Optimierung des digitalen Auftritts beizutragen",
      element: "feature2",
    },
    {
      category: [],
      old: "Die Konkurrenz freut sich...",
      new: "Verlorene Geschäftsabschlüsse",
      element: "feature3",
    },
    {
      category: [
        "Kurzbeschreibung → Weltmarktführer",
        "Schlüsselbegriffe → Produktinnovation",
      ],
      old: "Die personalisierte Web-Erfahrung wird zum Standard - wenn Sie stehen bleiben, zieht die Konkurrenz dynamisch an Ihnen vorbei.",
      new: "Die Kombination aus dem riesigen Sortiment und der vielfältigen Kundschaft birgt immense Chancen - kann aber ohne smartes Konzept zur Herausforderung werden.",
      element: "feature3",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "Konversionsphase → Entscheidungsphase",
      ],
      old: "Resultat: Liefern Sie Nutzern keine maßgeschneiderten Inhalte, verlieren Sie Ihre potenziellen Kunden",
      new: "Ergebnis:\n\nIrrelevante Angebote verlängern die Suche\n→ Erhöhtes Frustpotenzial und weniger Bestellungen\nKomplizierte Kaufprozesse bergen das Risiko von Abbrüchen\nWettbewerber mit besseren digitalen Erlebnissen gewinnen wertvolle Kunden",
      element: "feature3",
    },
  ],
  stats: [],
  "how-it-works": [
    {
      category: ["Firmenname → Würth", "Interaktionsrate → mittel"],
      old: "Gleich ausprobieren",
      new: "Jetzt für Würth ausprobieren",
      element: "how-it-works-button",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Analysieren",
      new: "Besucherabsicht erkennen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "Sekundäre Zielgruppe → Potenzielle Mitarbeiter, öffentliche Institutionen",
      ],
      old: "Webmetic identifiziert Branche und Standort der Firma und wertet das Nutzerverhalten in Echtzeit aus",
      new: "Identifikation, ob es sich um einen Industrie-Einkäufer, Handwerksbetrieb oder DIY-Kunden handelt",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Schlüsselbegriffe → Montage- und Befestigungsmaterial",
        "Schlüsselbegriffe → E-Procurement",
      ],
      old: "Anpassen",
      new: "Inhalte dynamisch anpassen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Besuchte Unterseiten → Datenschutz, Kontakt, Impressum",
      ],
      old: "Je nach Besucher ändern sich Headlines, CTAs und beliebige weitere Inhalte automatisch",
      new: "Produktangebote, CTA-Buttons und Bilder passend zur Branche und bisherigen Website-Interaktionen anzeigen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Konversionsphase → Entscheidungsphase",
        "Letzte Interaktion → Anfrage",
      ],
      old: "Akquirieren",
      new: "Relevante Erlebnisse ausspielen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Konversionsphase → Entscheidungsphase",
        "Call-to-Actions → Jetzt registrieren",
      ],
      old: "Mehr Relevanz führt zu mehr Engagement bei Ihren potenziellen Neukunden",
      new: "Je nach Nutzerverhalten werden die perfekten Inhalte ausgewählt, um mehr Abschlüsse zu generieren",
      element: "how-it-works-steps",
    },
    {
      category: [
        "USP → Qualität/Premium-Angebot",
        "Call-to-Actions → Mehr erfahren",
      ],
      old: "",
      new: "Fazit:\nIhre Kunden finden genau das, was sie suchen\n\n→ weniger Absprünge, mehr Umsatz!",
      element: "how-it-works-conclusion",
    },
  ],
  benefits: [
    {
      category: ["Firmenname → Würth", "Konversionsphase → Entscheidungsphase"],
      old: "Verbessern Sie Ihre Website",
      new: "Steigern Sie Ihre B2B-Verkäufe mit maßgeschneiderten Landing Pages",
      element: "benefits-title",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category: [
        "Konversionsphase → Entscheidungsphase",
        "Letzte Interaktion → Anfrage",
      ],
      old: "Überzeugend, vom ersten Klick an: Dynamische Landing Pages holen Ihre Besucher mit individueller Ansprache und relevanten Inhalte ab. Und steigern so Engagement und Interaktionsrate Ihrer Interessenten.",
      new: "Von der ersten Interaktion bis zur Bestellung: Unsere dynamischen Landing Pages sprechen Kunden individuell an, optimieren den Kaufprozess und erhöhen die Conversion-Rate.",
      element: "benefits-text",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "Besuchte Unterseiten → Datenschutz, Kontakt, Impressum",
      ],
      old: "Passender Content für jeden Besucher",
      new: "Ziehen Sie die richtigen Geschäftskunden an",
      element: "benefit1",
      segment: "zielgruppen",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "Schlüsselbegriffe → Montage- und Befestigungsmaterial",
      ],
      old: "Je nach Branche, Interesse und Standort erwarten den User maßgeschneiderte Inhalte, er fühlt sich gesehen und verstanden.",
      new: "Erreichen Sie gezielt Industrie-Einkäufer, Handwerksbetriebe und Bauunternehmen, die nach Ihren Produkten suchen – mit Inhalten, die auf ihre spezifischen Bedürfnisse zugeschnitten sind.",
      element: "benefit1",
    },
    {
      category: [
        "Interessensgebiete → Lead Generierung",
        "Letzte Interaktion → Anfrage",
      ],
      old: "Höheres Engagement und niedrigere Absprungrate",
      new: "Mehr qualifizierte Leads & Anfragen",
      element: "benefit1",
    },
    {
      category: [
        "Sekundäre Zielgruppe → Potenzielle Mitarbeiter, öffentliche Institutionen",
        "Besuchte Unterseiten → Datenschutz, Kontakt, Impressum",
      ],
      old: "Gesteigerte Conversion-Rates",
      new: "Maximieren Sie Ihre Umsätze",
      element: "benefit2",
      segment: "zielgruppen",
    },
    {
      category: [
        "Schlüsselbegriffe → Produktinnovation",
        "USP → Qualität/Premium-Angebot",
      ],
      old: "CTAs, Angebote und Bilder passen sich automatisch den Nutzerbedürfnissen an und überzeugen durch Relevanz.",
      new: "Präsentieren Sie passgenaue Produktempfehlungen basierend auf Unternehmensgröße, Branche und Kaufhistorie.",
      element: "benefit2",
    },
    {
      category: [
        "USP → Service/Support",
        "Call-to-Actions → Online-Shop besuchen",
      ],
      old: "Gesteigerte Anmeldungen und Conversion-Rates",
      new: "Höhere Warenkorbwerte & wiederkehrende Kunden",
      element: "benefit2",
    },
    {
      category: [
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
        "Letzte Interaktion → Anfrage",
      ],
      old: "Sinnvolles Retargeting und Upsells",
      new: "Gewinnen Sie mehr Geschäftskunden",
      element: "benefit3",
      segment: "zielgruppen",
    },
    {
      category: [
        "USP → Expertise/Wissen",
        "Tonalität → Seriös, Vertrauenswürdig, Fachkundig",
      ],
      old: "Sprechen Sie Bestandskunden gezielt mit Content an, der ganz auf ihre Vorlieben zugeschnitten ist.",
      new: "Heben Sie sich von der Konkurrenz ab und bieten Sie individuelle Angebote, die Unternehmen überzeugen, langfristig mit Ihnen zusammenzuarbeiten.",
      element: "benefit3",
    },
    {
      category: [
        "Schlüsselbegriffe → Multi-Kanal-Strategie",
        "Call-to-Actions → Jetzt registrieren",
      ],
      old: "Stärkere Kundenbindung & höhere Verkaufszahlen",
      new: "Mehr Aufträge & langfristige Kundenbeziehungen",
      element: "benefit3",
    },
    {
      category: [
        "USP → Service/Support",
        "Tonalität → Seriös, Vertrauenswürdig, Fachkundig",
      ],
      old: "Automatisierte, smarte Optimierung",
      new: "Nutzen Sie wertvolle Verkaufsdaten",
      element: "benefit4",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Nutzerstatus → wiederkehrend",
        "Schlüsselbegriffe → Digitalisierung",
      ],
      old: "Lassen Sie das Nutzerverhalten in Echtzeit analysieren und den Inhalt Ihrer Landing Pages dafür perfektionieren.",
      new: "Analysieren Sie Kunden-verhalten in Echtzeit und optimieren Sie Ihre Inhalte automatisiert – für bessere Conversions.",
      element: "benefit4",
    },
    {
      category: [
        "Schlüsselbegriffe → Digitalisierung",
        "Sprach-Stil → Faktisch/technisch",
      ],
      old: "Erfolgsversprechende Entscheidungen und nachhaltiges Wachstum",
      new: "Effizientere Kampagnen & nachhaltiges Wachstum",
      element: "benefit4",
    },
  ],
  cta: [
    {
      category: [
        "Call-to-Actions → Mehr erfahren",
        "Nutzerstatus → wiederkehrend",
        "Konversionsphase → Entscheidungsphase",
      ],
      old: "Personalisierung, von der Ihre Konkurrenz nur träumen kann",
      new: "Erleben Sie personalisierte Würth-Websites in Aktion",
      element: "cta-title",
      segment: "ctas_forms",
    },
    {
      category: [
        "Schlüsselbegriffe → Montage- und Befestigungsmaterial",
        "Interessensgebiete → Lead Generierung",
      ],
      old: "Branchenrelevante News",
      new: "Branchenrelevante Inhalte",
      element: "cta-feature1",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Call-to-Actions → Mehr erfahren",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Bessere Ergebnisse erzielen",
      new: "Jeder Besucher sieht passende Produkte und Lösungen",
      element: "cta-feature1",
    },
    {
      category: [
        "Schlüsselbegriffe → Produktinnovation",
        "USP → Qualität/Premium-Angebot",
      ],
      old: "Verhaltensbasierte Anpassungen",
      new: "Automatische Produktempfehlungen",
      element: "cta-feature2",
    },
    {
      category: [
        "Nutzerstatus → wiederkehrend",
        "Call-to-Actions → Online-Shop besuchen",
      ],
      old: "Überschriften, CTAs und Bilder",
      new: "Basierend auf Suchverhalten und bisherigen Käufen",
      element: "cta-feature2",
    },
    {
      category: [
        "Call-to-Actions → Jetzt registrieren",
        "Konversionsphase → Entscheidungsphase",
      ],
      old: "Dynamischer Social Proof",
      new: "Dynamische CTA-Anpassungen",
      element: "cta-feature3",
    },
    {
      category: [
        "Letzte Interaktion → Anfrage",
        "Konversionsphase → Entscheidungsphase",
      ],
      old: "– Ihr Unternehmen glänzt mit Rezensionen, Studien und Erfolgsstorys",
      new: "Führt Kunden gezielt zum Kauf oder Kontaktformular",
      element: "cta-feature3",
    },
    {
      category: [
        "Schlüsselbegriffe → Digitalisierung",
        "USP → Expertise/Wissen",
      ],
      old: "KI-gestützte Empfehlungen",
      new: "KI-gestützte Personalisierung",
      element: "cta-feature4",
    },
    {
      category: [
        "Schlüsselbegriffe → Digitalisierung, E-Procurement",
        "Interessensgebiete → Lead Generierung",
      ],
      old: "– Ihre Web-Inhalte werden ausgewertet und Sie erhalten Optimierungsvorschläge",
      new: "Echtzeit-Optimierung für mehr Conversions",
      element: "cta-feature4",
    },
    {
      category:
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      new: "Besucher mit Laptop",
      element: "cta-image",
      segment: "images",
    },
  ],
  testimonials: [
    {
      category: "Land → Deutschland",
      old: "Susan Wright, Referenzkunde",
      new: "Johanna Leitner<br/>Digital Sales Manager - Makita",
      element: "testimonial-author",
      segment: "ctas_forms",
    },
    {
      category: "Tonalität → Seriös, Vertrauenswürdig, Fachkundig",
      new: "Professionelle Person im Messekontext",
      element: "testimonial-image",
      segment: "images",
    },
    {
      category: [
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Mit Webmetic konnten wir unsere Vertriebspipeline deutlich optimieren. Die Identifikation relevanter Unternehmen ermöglicht es uns, gezielter auf potenzielle Kunden zuzugehen und die Abschlussrate signifikant zu steigern.",
      new: "Seit der Einführung von Webmetic sehen wir einen deutlichen Anstieg an qualifizierten Produktanfragen. Unsere Industrie-Kunden finden nun genau die Lösungen, die für sie relevant sind!",
      element: "testimonials-testimonial",
    },
  ],
  faq: [],
  contact: [
    {
      category: "Firmenname → Adolf Würth GmbH & Co. KG",
      new: "Adolf Würth GmbH & Co. KG",
      element: "form-company",
      segment: "ctas_forms",
    },
    {
      category: "E-Mail → info@wuerth.de",
      new: "@wuerth.de",
      element: "form-email",
      segment: "ctas_forms",
    },
    {
      category: "Website → messe-muenchen.de",
      new: "https://wuerth.de/",
      element: "form-website",
      segment: "ctas_forms",
    },
  ],
  other: [
    {
      category: [
        "Sprach-Stil → Faktisch/technisch",
        "Primäre Zielgruppe → Handwerk und Industrie (Gewerbetreibende)",
      ],
      old: "Steigern Sie Ihre Website-Performance mit intelligenten Landing Pages",
      new: "Maximieren Sie Ihre Messeerfolge mit unserer Personalisierung und Unternehmensidentifikation.",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Tonalität → Seriös, Vertrauenswürdig, Fachkundig",
        "Hauptbranche → Herstellung und Vertrieb von Montage- und Befestigungsmaterial",
      ],
      old: "Wir helfen Ihnen, mehr aus Ihrer Website herauszuholen",
      new: "Steigern Sie Kundenbindung durch maßgeschneiderte Events und zielgerichtetes Marketing.",
      segment: "kommunikation_werte",
    },
    {
      category: ["Anrede → Formal"],
      old: "Buch dir deine Demo",
      new: "Sichern Sie sich Ihren Demo-Termin",
      segment: "kommunikation_werte",
    },
  ],
};
