import { apiHandlers } from "@/api";
import { HubSpotAuthParams, HubSpotAuthResponse } from "@/api/types";
import { LEXWARE_AUTH_CONFIRM } from "@/constants";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import analytics from "@/analytics";

export const useConfirmLexWareQuery = (
  params: HubSpotAuthParams,
): UseQueryResult<HubSpotAuthResponse> => {
  return useQuery<HubSpotAuthResponse>({
    queryKey: [LEXWARE_AUTH_CONFIRM, params.code, params.error],
    queryFn: async () => {
      const response =
        await apiHandlers.integrations.lexWare.confirmAuthorization(params);

      // Track Lexware integration added
      if (response.data) {
        analytics.track("integration_lexoffice_added", {
          integration_date: new Date().toISOString(),
        });
      }

      return response.data;
    },
    enabled: !!params,
  });
};
