import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { cn } from "@/lib/utils.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTList: FC<RTElementProps> = ({node, className}: RTElementProps) => {
  const ListTag = node.tag ?? 'ul'
  const listClass = ListTag == 'ol' ? 'list-decimal' : 'list-disc'
  return (
    <ListTag className={cn(listClass, 'mt-6 mb-6 pl-7', className)}>
      <RTChildren children={node.children} />
    </ListTag>
  )
}
