import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTTableRow: FC<RTElementProps> = ({node}: RTElementProps) => {
  return (
    <tr className='border-b border-neutral-100 last:border-b-0 first:border-b-0'>
      <RTChildren children={node.children} />
    </tr>
  )
}
