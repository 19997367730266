import image from "@/pages/smartLandingPage/freelance/images/testimonials.jpeg";
import { forwardRef } from "react";

export const Testimonials = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="testimonials" className="w-full bg-platin" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] items-center gap-24 px-4 py-8 lg:grid lg:grid-cols-2">
        <div className="px-16 text-black sm:text-lg">
          <h1
            id="testimonials-title"
            className="lg:p-r-4 mb-8 text-3xl font-semibold tracking-tight text-black md:text-4xl"
          >
            Was unsere Kunden sagen
          </h1>
          <div id="testimonials-testimonial" className="flex gap-0 pb-8">
            <p>"</p>
            <p>
              Dank Webmetic erhalten wir nun viel gezieltere Bewerbungen. Unsere
              Vermittlungen laufen schneller, und die Qualität der Matches ist
              deutlich gestiegen.“
            </p>
          </div>
          <div id="testimonial-author">
            <p className="">Julia Maier</p>
            <p className="text-sm">
              Head of Talent Acquisition - Upwork Deutschland
            </p>
            <br />
            <p className="text-xs">(Musterdarstellung von Testimonial)</p>
          </div>
        </div>
        <img
          id="testimonial-image"
          className="mx-auto mb-4 hidden rounded-l-2xl sm:flex"
          src={image}
          alt="table professor illustration"
        />
      </div>
    </section>
  );
});
