import { useParams, useSearchParams } from "react-router-dom";

import useUser from "@/hooks/useUser.ts";
import NotFoundPage from "@/pages/notFound/index.jsx";
import { CompanyDetailsComponent } from "./company-details-component.tsx";

function CompanyDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const user = useUser();

  const company_id = params.company_details_id || "";
  const from_date = searchParams.get("from_date") || "-30 days";
  const to_date = searchParams.get("to_date") || "now";
  const domain = params.domain || "";

  if (!user?.website.includes(domain)) {
    return <NotFoundPage />;
  }

  return (
    <div className="overflow-y-scroll p-0 dark:bg-black">
      <CompanyDetailsComponent
        company_id={company_id}
        from_date={from_date && from_date}
        to_date={to_date && to_date}
      />
    </div>
  );
}

export default CompanyDetails;
