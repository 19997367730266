import { forwardRef } from "react";

export const Features = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="features" className="bg-platin" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-center sm:py-16">
        <h2
          id="features-title"
          className="mx-auto mb-12 max-w-[40rem] text-4xl font-extrabold tracking-tight text-neutral-900"
        >
          Wie statische Landing Pages Ihrem Unternehmen schaden
        </h2>
        <div
          id="feature-list"
          className="space-y-8 md:grid md:grid-cols-2 md:gap-5 md:space-y-0 lg:grid-cols-3"
        >
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature1"
          >
            <p className="text-base">01</p>
            <h3 className="text-3xl font-normal">
              Generisch konvertiert nicht
            </h3>
            <p className="text-base font-light">
              Ihre Interessenten kommen aus unterschiedlichen Ländern und
              Branchen und haben ganz individuelle Bedürfnisse - die eine
              einzige Landing Page gar nicht abdecken kann.
            </p>
            <p className="text-base font-light">
              <span className="underline">Resultat</span>: Wenig Relevanz, hohe
              Absprungraten und verlorene Neukunden
            </p>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature2"
          >
            <p className="text-base">02</p>
            <h3 className="text-3xl font-normal">Potenzial bleibt ungenutzt</h3>
            <p className="text-base font-light">
              Jeder Klick kostet Ihr Unternehmen bares Geld, aber Ihre
              Conversion-Rates bleiben stets im niedrigen einstelligen Bereich.
            </p>
            <p className="text-base font-light">
              <span className="underline">Resultat</span>: Ohne zielgerichtetes
              Messaging bleibt Ihr Marketing-ROI weit unter seinen Möglichkeiten
            </p>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature3"
          >
            <p className="text-base">03</p>
            <h3 className="text-3xl font-normal">Die Konkurrenz freut sich</h3>
            <p className="text-base font-light">
              Die personalisierte Web-Erfahrung wird zum Standard - wenn Sie
              stehen bleiben, zieht die Konkurrenz dynamisch an Ihnen vorbei.
            </p>
            <p className="text-base font-light">
              <span className="underline">Resultat</span>: Liefern Sie Nutzern
              keine maßgeschneiderten Inhalte, verlieren Sie Ihre potenziellen
              Kunden
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});
