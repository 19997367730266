import { deleteData, getData, postData, putData } from "./crudHandlers";
import {
  CompanySessionsParams,
  IntegrationAuthLinkResponse,
  LexWareSettingsDataResponse,
  LinkCompanyHubSpotData,
  SyncSegmentHubSpotData,
} from "./types";

import { CompaniesDetails, CompaniesDetailsSession } from "@/types";
import { CreateSegmentData, Segment } from "@/types/segment";
import {
  AxiosResponseSuccess,
  CompanyDetailsParams,
  CreateSegmentResponse,
  HubSpotAuthParams,
  HubSpotAuthResponse,
  HubSpotMessageResponse,
  HubSpotSettingsDataResponse,
  LoginRequestData,
  SearchCompanyParams,
  SearchCompanyResponseData,
  SearchSegmentParams,
  SearchedCompaniesListParams,
  SegmentListParams,
  SegmentSearchCompany,
  SettingsHubSpotProps,
  UpdateSegmentProps,
  apiPaths,
} from ".";

export const apiHandlers = {
  auth: {
    login: (data: LoginRequestData): AxiosResponseSuccess<unknown> => {
      return postData<unknown>(apiPaths.auth.login(), { data });
    },
  },
  company: {
    multiSearch: (
      params: SearchCompanyParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(
        apiPaths.company.multiSearch(),
        {
          params,
        },
      );
    },
    search: (
      params: SearchCompanyParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(apiPaths.company.search(), {
        params,
      });
    },
    details: (params: CompanyDetailsParams) => {
      return getData<CompaniesDetails>(apiPaths.company.details(), {
        params,
      });
    },
    sessions: (params: CompanySessionsParams) => {
      return getData<{
        results: CompaniesDetailsSession[];
        pagination: { total: number };
      }>(apiPaths.company.sessions(params.company_id), {
        params,
      });
    },
  },
  segment: {
    getSegments: (
      params: SegmentListParams,
    ): AxiosResponseSuccess<Segment[]> => {
      return getData(apiPaths.segment.getSegments(), {
        params,
      });
    },
    getSegmentById: (segment_id: string): AxiosResponseSuccess<Segment> => {
      return getData(apiPaths.segment.getSegmentById(segment_id));
    },
    searchSegmentCompany: (
      data: SegmentSearchCompany,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return postData(apiPaths.company.search(), {
        data,
      });
    },
    createSegment: (
      data: CreateSegmentData,
    ): AxiosResponseSuccess<CreateSegmentResponse> => {
      return postData(apiPaths.segment.createSegment(), {
        data,
      });
    },
    updateSegment: ({
      id,
      data,
    }: UpdateSegmentProps): AxiosResponseSuccess<CreateSegmentData> => {
      return putData(apiPaths.segment.updateSegment(id), {
        data,
      });
    },
    deleteSegment: (id: string): AxiosResponseSuccess<string> => {
      return deleteData(apiPaths.segment.deleteSegment(id));
    },
    segmentSearch: (
      segment_id: string,
      params: SearchSegmentParams,
    ): AxiosResponseSuccess<SearchCompanyResponseData> => {
      return getData<SearchCompanyResponseData>(
        apiPaths.segment.segmentSearch(segment_id),
        {
          params,
        },
      );
    },
  },
  exports: {
    exportFile: (params: SearchedCompaniesListParams) => {
      return getData<string>(apiPaths.exports.exportSearchedCompaniesList(), {
        params,
      });
    },
  },
  integrations: {
    hubspot: {
      getAuthUrl: (): AxiosResponseSuccess<IntegrationAuthLinkResponse> => {
        return getData<IntegrationAuthLinkResponse>(
          apiPaths.integrations.hubspot.authUrl(),
        );
      },
      confirmAuthorization: (
        params: HubSpotAuthParams,
      ): AxiosResponseSuccess<HubSpotAuthResponse> => {
        return getData(apiPaths.integrations.hubspot.confirm(), { params });
      },
      getSettings: (): AxiosResponseSuccess<HubSpotSettingsDataResponse> => {
        return getData<HubSpotSettingsDataResponse>(
          apiPaths.integrations.hubspot.getSettings(),
        );
      },
      settings: (
        data: SettingsHubSpotProps,
      ): AxiosResponseSuccess<HubSpotMessageResponse> => {
        return postData(apiPaths.integrations.hubspot.settings(), {
          data,
        });
      },
      syncCompany: (
        data: LinkCompanyHubSpotData,
      ): AxiosResponseSuccess<HubSpotMessageResponse> => {
        return postData(apiPaths.integrations.hubspot.syncCompany(), {
          data,
        });
      },
      disable: (data: {}) => {
        return postData(apiPaths.integrations.hubspot.disableIntegration(), {
          data,
        });
      },
      syncSegment: (
        data: SyncSegmentHubSpotData,
      ): AxiosResponseSuccess<HubSpotMessageResponse> => {
        return postData(apiPaths.integrations.hubspot.syncSegment(), {
          data,
        });
      },
    },
    lexWare: {
      getAuthUrl: (): AxiosResponseSuccess<IntegrationAuthLinkResponse> => {
        return getData<IntegrationAuthLinkResponse>(
          apiPaths.integrations.lexWare.authUrl(),
        );
      },
      confirmAuthorization: (
        params: HubSpotAuthParams,
      ): AxiosResponseSuccess<HubSpotAuthResponse> => {
        return getData(apiPaths.integrations.lexWare.confirm(), { params });
      },
      getSettings: (): AxiosResponseSuccess<LexWareSettingsDataResponse> => {
        return getData<LexWareSettingsDataResponse>(
          apiPaths.integrations.lexWare.getSettings(),
        );
      },
      syncCompany: (
        data: LinkCompanyHubSpotData,
      ): AxiosResponseSuccess<HubSpotMessageResponse> => {
        return postData(apiPaths.integrations.lexWare.syncCompany(), {
          data,
        });
      },
      disable: (data: {}) => {
        return postData(apiPaths.integrations.lexWare.disableIntegration(), {
          data,
        });
      },
      syncSegment: (
        data: SyncSegmentHubSpotData,
      ): AxiosResponseSuccess<HubSpotMessageResponse> => {
        return postData(apiPaths.integrations.lexWare.syncSegment(), {
          data,
        });
      },
    },
  },
} as const;
