import { cn } from "@/lib/utils.ts";
import { Badge } from "@/pages/smartLandingPage/drawer/Badge";
import { useState } from "react";
import { ContextsType } from "@/pages/smartLandingPage/drawer/types";

const contextCategories: { [k: string]: string } = {
  unternehmensbasis: "🏢 Unternehmensbasis",
  kontakt_standort: "📍 Kontakt & Standort",
  management_rechtliches: "⚖️ Management & Rechtliches",
  geschaeftstaetigkeit: "💼 Geschäftstätigkeit",
  online_praesenz: "🌐 Online-Präsenz",
  marke_kommunikation: "📣 Marke & Kommunikation",
  zielgruppen_marketing: "🎯 Zielgruppen & Marketing",
  nutzerdaten: "🧑‍💻 Nutzerdaten",
};

const ContextTabButton = ({
  text,
  num,
  active,
  onClick,
}: {
  text: string;
  num: number;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <li
      onClick={onClick}
      className={cn(
        "flex w-full cursor-pointer justify-between gap-1 rounded-lg px-2 py-1 text-base font-medium transition-colors duration-200",
        active
          ? "bg-platin text-black"
          : "bg-graphite text-white hover:bg-opacity-90",
      )}
    >
      {text} <Badge content={"" + num} />
    </li>
  );
};

export const Context = ({ context }: { context: ContextsType }) => {
  const [active, setActive] = useState<keyof ContextsType>("unternehmensbasis");
  const [isChanging, setIsChanging] = useState(false);
  const selectedContext = context[active];

  const handleTabClick = (key: keyof ContextsType) => {
    if (key === active) return;

    setIsChanging(true);
    setTimeout(() => {
      setActive(key);
      setTimeout(() => {
        setIsChanging(false);
      }, 50);
    }, 200);
  };

  return (
    <section>
      <ul className="flex flex-col items-start gap-2">
        {Object.keys(contextCategories).map((key, index) => {
          if (!(key in context)) return null;
          const count = context[key as keyof ContextsType].length;
          if (count === 0) return null;
          return (
            <ContextTabButton
              key={index}
              text={contextCategories[key]}
              num={count}
              active={active === key}
              onClick={() => handleTabClick(key as keyof ContextsType)}
            />
          );
        })}
      </ul>

      <div
        className={cn(
          "transition-opacity duration-200",
          isChanging ? "opacity-0" : "opacity-100",
        )}
      >
        {selectedContext.length > 0 && (
          <h3 className="mb-2 mt-4 text-base font-medium text-white">
            ✅ Erfasste Daten:
          </h3>
        )}
        <ul className="text-sm text-black">
          {selectedContext.map((item, index) => (
            <li
              key={index}
              className="mb-2 flex flex-wrap items-start rounded-lg bg-platin px-2 py-1 font-normal transition-colors hover:bg-platin/90"
              aria-label={`${item.type}: ${item.html ? "HTML content" : item.data}`}
            >
              <span className="ml-1 mr-1 font-bold">{item.type}:</span>
              {item.html ? (
                <div
                  className="ml-1 max-w-full overflow-hidden text-ellipsis bg-white p-4 md:max-w-[120px] lg:max-w-[140px]"
                  dangerouslySetInnerHTML={{ __html: item.data }}
                />
              ) : (
                <span className="ml-1 max-w-full overflow-hidden text-ellipsis md:max-w-[250px] lg:max-w-[350px]">
                  {item.data}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
