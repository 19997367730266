import { RTElementProps } from "@/components/content/RichText/types.ts"
import { FC } from "react"


const NodeFormat = {
  IS_BOLD: 1,
  IS_ITALIC: 1 << 1,
  IS_STRIKETHROUGH: 1 << 2,
  IS_UNDERLINE: 1 << 3,
  IS_CODE: 1 << 4,
  IS_SUBSCRIPT: 1 << 5,
  IS_SUPERSCRIPT: 1 << 6,
  IS_HIGHLIGHT: 1 << 7,
}

interface FormattedTextProps {
  text: string
  format: number | undefined
}

const FormattedText: FC<FormattedTextProps> = ({text, format}: FormattedTextProps) => {
  let Text = <span>{text}</span>
  if (!format) {
    return Text
  }

  if (format & NodeFormat.IS_BOLD) {
    Text = <strong>{Text}</strong>
  }
  if (format & NodeFormat.IS_ITALIC) {
    Text = <em>{Text}</em>
  }
  if (format & NodeFormat.IS_STRIKETHROUGH) {
    Text = <span className="line-through">{Text}</span>
  }
  if (format & NodeFormat.IS_UNDERLINE) {
    Text = <span className="underline">{Text}</span>
  }
  if (format & NodeFormat.IS_CODE) {
    return <>{text}</>
  }
  if (format & NodeFormat.IS_SUBSCRIPT) {
    Text = <sub>{Text}</sub>
  }
  if (format & NodeFormat.IS_SUPERSCRIPT) {
    Text = <sup>{Text}</sup>
  }

  return Text
}

export const RTText: FC<RTElementProps> = ({node}: RTElementProps) => {
  if (!node.text){
    return null
  }
  return (
    <>
      <FormattedText text={node.text} format={node.format as number} />
    </>
  )
}
