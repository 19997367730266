/**
 * Simplified attribution utility
 * Tracks traffic sources with priority-based attribution
 */

// Enable debug mode in development or when explicitly enabled
const isDebug =
  import.meta.env.VITE_ENV === "development" ||
  import.meta.env.VITE_ATTRIBUTION_DEBUG === "true";

// Define interfaces for type safety
interface SourceData {
  source: string;
  medium: string;
  priority: number;
  [key: string]: any; // Allow additional properties
}

interface UtmParams {
  [key: string]: string; // Allow any string keys
}

interface AnalyticsStorage {
  getItem: (key: string) => any;
  setItem: (key: string, value: any, options?: object) => void;
  removeItem: (key: string, options?: object) => void;
}

interface Analytics {
  storage: AnalyticsStorage;
  page: (name?: string | null, properties?: object) => void;
}

// Storage keys
export const STORAGE_KEYS = {
  SOURCE: "attribution_source",
  UTM: "attribution_utm",
  FIRST_TOUCH: "attribution_first_touch",
  VISIT_COUNT: "attribution_visits",
  TOUCHPOINTS: "attribution_touchpoints", // New: store all touchpoints with timestamps
};

// Define attribution priority levels for clarity - evenly spread values
const PRIORITY = {
  CUSTOM_SOURCE: 80, // Custom source parameter (sn)
  LEAD_GEN: 70, // Lead generation source (lgs)
  PARTNER: 60, // Partner parameter (pn)
  AD_PLATFORM: 50, // Ad platforms (Google, Microsoft, LinkedIn)
  PAID_UTM: 40, // UTM with paid medium
  STANDARD_UTM: 30, // Standard UTM parameters
  REFERRAL: 20, // Referral traffic
  DIRECT: 10, // Direct traffic
};

// Ad platform patterns (kept for backward compatibility)
const URL_PATTERNS: Record<
  string,
  { pattern: RegExp; priority: number; medium: string }
> = {
  google_ads: {
    pattern: /gclid=/i,
    priority: PRIORITY.AD_PLATFORM,
    medium: "cpc",
  },
  microsoft_ads: {
    pattern: /msclkid=/i,
    priority: PRIORITY.AD_PLATFORM,
    medium: "cpc",
  },
  linkedin_ads: {
    pattern: /li_fat_id=/i,
    priority: PRIORITY.AD_PLATFORM,
    medium: "social",
  },
  facebook_ads: {
    pattern: /fbclid=/i,
    priority: PRIORITY.AD_PLATFORM,
    medium: "social",
  },
};

// Referrer patterns (B2B focused)
const REFERRER_PATTERNS: Record<
  string,
  { pattern: RegExp; priority: number; medium: string }
> = {
  // Search engines
  google: { pattern: /google\.com/i, priority: 60, medium: "organic" },
  bing: { pattern: /bing\.com/i, priority: 60, medium: "organic" },

  // Social
  linkedin: {
    pattern: /linkedin\.com/i,
    priority: PRIORITY.REFERRAL,
    medium: "social",
  },
  xing: {
    pattern: /xing\.com/i,
    priority: PRIORITY.REFERRAL,
    medium: "social",
  },
  twitter: {
    pattern: /twitter\.com|x\.com/i,
    priority: PRIORITY.REFERRAL,
    medium: "social",
  },

  // Other common B2B referrers can be added here
};

/**
 * Gets storage options for consistent expiration - 1 year (365 days)
 */
const getStorageOptions = (
  days = 365,
): { expires: number; storage: string } => ({
  expires: days * 24 * 60 * 60 * 1000,
  storage: "localStorage",
});

/**
 * Detects source from URL parameters
 */
function detectUrlSource(url: string): SourceData | null {
  // Check all explicit parameters
  const urlParams = new URLSearchParams(url);
  const explicitSource = urlParams.get("sn");
  const partnerCode = urlParams.get("pn");
  const leadGenSource = urlParams.get("lgs");

  // Debug output only in development mode
  if (isDebug && (explicitSource || partnerCode || leadGenSource)) {
    console.log("Attribution params found:", {
      url,
      sn: explicitSource,
      pn: partnerCode,
      lgs: leadGenSource,
    });
  }

  // Source parameter has highest priority
  if (explicitSource) {
    // Create source data with source name and optional additional parameters
    const sourceData: SourceData = {
      source: explicitSource,
      medium: "campaign",
      priority: PRIORITY.CUSTOM_SOURCE,
    };

    // Add partner information if available
    if (partnerCode) {
      sourceData.partner = partnerCode;
    }

    // Add lead generation source if available
    if (leadGenSource) {
      sourceData.lead_gen = leadGenSource;
    }

    return sourceData;
  }

  // Lead generation source has second priority
  if (leadGenSource) {
    // Debug output for lead gen source prioritization
    if (isDebug) {
      console.log("Using lead generation source as primary source:", {
        lgs: leadGenSource,
      });
    }

    return {
      // Use the lgs value directly as the source name
      source: leadGenSource,
      medium: "lead_generation",
      priority: PRIORITY.LEAD_GEN,
      // Add partner if available
      ...(partnerCode ? { partner: partnerCode } : {}),
    };
  }

  // Partner has third priority
  if (partnerCode) {
    return {
      source: `partner_${partnerCode}`,
      medium: "partner",
      priority: PRIORITY.PARTNER,
      partner: partnerCode,
    };
  }

  // Check for UTM source
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  if (utmSource) {
    let priority = PRIORITY.STANDARD_UTM;

    // Adjust priority based on medium
    if (
      utmMedium &&
      (utmMedium.includes("cpc") ||
        utmMedium.includes("ppc") ||
        utmMedium.includes("paid"))
    ) {
      priority = PRIORITY.PAID_UTM;
    }

    return {
      source: utmSource,
      medium: utmMedium || "unknown",
      priority: priority,
    };
  }

  // Check for ad platform parameters (using already defined urlParams)

  // Simple parameter-based checks for ad platforms
  if (urlParams.has("gclid")) {
    const gclidValue = urlParams.get("gclid");
    return {
      source: "google_ads",
      medium: "cpc",
      priority: PRIORITY.AD_PLATFORM,
      gclid: gclidValue, // Store the actual gclid value
    };
  }

  if (urlParams.has("msclkid")) {
    const msclkidValue = urlParams.get("msclkid");
    return {
      source: "microsoft_ads",
      medium: "cpc",
      priority: PRIORITY.AD_PLATFORM,
      msclkid: msclkidValue,
    };
  }

  if (urlParams.has("fbclid")) {
    const fbclidValue = urlParams.get("fbclid");
    return {
      source: "facebook_ads",
      medium: "social",
      priority: PRIORITY.AD_PLATFORM,
      fbclid: fbclidValue,
    };
  }

  if (urlParams.has("li_fat_id")) {
    const liIdValue = urlParams.get("li_fat_id");
    return {
      source: "linkedin_ads",
      medium: "social",
      priority: PRIORITY.AD_PLATFORM,
      li_fat_id: liIdValue,
    };
  }

  if (urlParams.has("dclid")) {
    const dclidValue = urlParams.get("dclid");
    return {
      source: "google_display",
      medium: "display",
      priority: PRIORITY.PAID_UTM,
      dclid: dclidValue,
    };
  }

  // Fall back to regex pattern matching for other cases
  for (const [source, data] of Object.entries(URL_PATTERNS)) {
    if (data.pattern.test(url)) {
      return {
        source: source,
        medium: data.medium,
        priority: data.priority,
      };
    }
  }

  return null;
}

/**
 * Detects source from referrer
 */
function detectReferrerSource(referrer: string): SourceData | null {
  if (!referrer) return null;

  // Check for known referrers
  for (const [source, data] of Object.entries(REFERRER_PATTERNS)) {
    if (data.pattern.test(referrer)) {
      return {
        source: `${source}_referral`,
        medium: data.medium,
        priority: data.priority,
      };
    }
  }

  // Unknown referrer
  try {
    const domain = new URL(referrer).hostname;
    return {
      source: `referral_${domain}`,
      medium: "referral",
      priority: 50,
    };
  } catch (e) {
    return null;
  }
}

/**
 * Collects tracking parameters from URL
 * Includes UTM parameters and custom source/partner parameters
 */
function collectUtmParams(url: string): UtmParams | null {
  const params = new URLSearchParams(url);
  const utmParams: UtmParams = {};
  let hasTrackingParams = false;

  // Standard UTM parameters
  [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ].forEach((param) => {
    const value = params.get(param);
    if (value) {
      utmParams[param] = value;
      hasTrackingParams = true;
    }
  });

  // Collect custom utm_ parameters
  for (const [key, value] of params.entries()) {
    if (key.startsWith("utm_") && !utmParams[key]) {
      utmParams[key] = value;
      hasTrackingParams = true;
    }
  }

  // Collect custom source/partner/lead-gen parameters
  const sourceParam = params.get("sn");
  if (sourceParam) {
    utmParams.source_name = sourceParam;
    hasTrackingParams = true;
  }

  const partnerParam = params.get("pn");
  if (partnerParam) {
    utmParams.partner_name = partnerParam;
    hasTrackingParams = true;
  }

  const leadGenParam = params.get("lgs");
  if (leadGenParam) {
    utmParams.lead_gen_source = leadGenParam;
    hasTrackingParams = true;

    // Debug output for lead gen source
    if (isDebug) {
      console.log("Lead generation source found in collectUtmParams:", {
        lgs: leadGenParam,
      });
    }
  }

  // Ad platform click IDs
  const adPlatformParams = ["gclid", "msclkid", "fbclid", "li_fat_id", "dclid"];

  adPlatformParams.forEach((param) => {
    const value = params.get(param);
    if (value) {
      utmParams[param] = value;
      hasTrackingParams = true;

      // Also set the source type based on the click ID
      // Only set these if explicit UTM parameters aren't present
      // to ensure UTM parameters take precedence
      if (param === "gclid" && !utmParams.utm_source) {
        utmParams.utm_source = "google_ads";
        utmParams.utm_medium = "cpc";
        utmParams.ad_platform = "google";
      } else if (param === "msclkid" && !utmParams.utm_source) {
        utmParams.utm_source = "microsoft_ads";
        utmParams.utm_medium = "cpc";
        utmParams.ad_platform = "microsoft";
      } else if (param === "fbclid" && !utmParams.utm_source) {
        utmParams.utm_source = "facebook_ads";
        utmParams.utm_medium = "social";
        utmParams.ad_platform = "facebook";
      } else if (param === "li_fat_id" && !utmParams.utm_source) {
        utmParams.utm_source = "linkedin_ads";
        utmParams.utm_medium = "social";
        utmParams.ad_platform = "linkedin";
      } else if (param === "dclid" && !utmParams.utm_source) {
        utmParams.utm_source = "google_display";
        utmParams.utm_medium = "display";
        utmParams.ad_platform = "google_display";
      }
    }
  });

  if (hasTrackingParams) {
    utmParams.landing_page = window.location.pathname;
    utmParams.timestamp = new Date().toISOString();
    return utmParams;
  }

  return null;
}

/**
 * Main attribution function
 * Identifies traffic source and stores in analytics
 */
export function trackAttribution(analytics: Analytics): {
  source: SourceData;
  utmParams: UtmParams | null;
} {
  try {
    // Get current URL search and referrer
    const urlSearch = window.location.search;
    const referrer = document.referrer;

    // Get UTM parameters first (we'll need this for both source detection and storage)
    const utmParams = collectUtmParams(urlSearch);

    // Collect all possible sources with priorities
    const sources: SourceData[] = [];

    // URL parameters (highest priority)
    const urlSource = detectUrlSource(urlSearch);
    if (urlSource) {
      // Add UTM params to the source if available
      if (utmParams) {
        if (utmParams.utm_campaign) urlSource.campaign = utmParams.utm_campaign;
        if (utmParams.utm_content) urlSource.content = utmParams.utm_content;
        if (utmParams.utm_term) urlSource.term = utmParams.utm_term;
      }
      sources.push(urlSource);
    }

    // Referrer-based sources (medium priority)
    const referrerSource = detectReferrerSource(referrer);
    if (referrerSource) sources.push(referrerSource);

    // Direct traffic (lowest priority)
    sources.push({
      source: "direct",
      medium: "direct",
      priority: PRIORITY.DIRECT,
    });

    // Get the highest priority source
    const bestSource = sources.sort((a, b) => b.priority - a.priority)[0];

    // Debug logging when parameters are present
    if (urlSearch && isDebug) {
      console.group("🔍 Attribution Source Selection");
      console.log("URL Search:", urlSearch);
      console.log("Available Sources:", sources);
      console.log("Selected Source:", bestSource);
      console.log("UTM Parameters:", utmParams);
      console.groupEnd();
    }

    // Store attribution data
    updateAttributionStorage(analytics, bestSource, utmParams);

    return {
      source: bestSource,
      utmParams,
    };
  } catch (error) {
    console.error("Error tracking attribution:", error);
    return {
      source: { source: "direct", medium: "direct", priority: PRIORITY.DIRECT },
      utmParams: null,
    };
  }
}

/**
 * Update attribution storage with new data
 */
function updateAttributionStorage(
  analytics: Analytics,
  sourceData: SourceData,
  utmParams: UtmParams | null,
): void {
  const { storage } = analytics;
  const options = getStorageOptions(); // 1 year
  const timestamp = new Date().toISOString();
  const currentPath = window.location.pathname;
  const referrer = document.referrer;

  // Store source with priority-based override
  const currentSource = storage.getItem(
    STORAGE_KEYS.SOURCE,
  ) as SourceData | null;
  if (!currentSource || sourceData.priority > (currentSource.priority || 0)) {
    storage.setItem(STORAGE_KEYS.SOURCE, sourceData, options);
  }

  // Store UTM parameters if available
  if (utmParams) {
    // Get existing UTM parameters
    const existingUtm =
      (storage.getItem(STORAGE_KEYS.UTM) as UtmParams[]) || [];

    // Store up to 3 sets of UTM parameters (latest first)
    const updatedUtm = [utmParams, ...existingUtm].slice(0, 3);
    storage.setItem(STORAGE_KEYS.UTM, updatedUtm, options);
  }

  // Store first touch data if not already present
  if (!storage.getItem(STORAGE_KEYS.FIRST_TOUCH)) {
    const firstTouch = {
      source: sourceData.source,
      medium: sourceData.medium,
      landing_page: currentPath,
      timestamp: timestamp,
    };

    storage.setItem(STORAGE_KEYS.FIRST_TOUCH, firstTouch, options);
  }

  // Record this touchpoint in the attribution history
  const touchpoint = {
    // Attribution data
    source: sourceData.source,
    medium: sourceData.medium,
    priority: sourceData.priority,

    // UTM parameters (if any)
    utm_params: utmParams,

    // Context data
    timestamp: timestamp,
    landing_page: currentPath,
    referrer: referrer || "direct",
    visit_type: utmParams ? "attributed" : "direct",

    // Include any additional properties from sourceData
    ...Object.entries(sourceData)
      .filter(([key]) => !["source", "medium", "priority"].includes(key))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
  };

  // Get existing touchpoints and add the new one at the beginning
  const existingTouchpoints =
    (storage.getItem(STORAGE_KEYS.TOUCHPOINTS) as Array<any>) || [];

  // Store up to 50 touchpoints - latest first
  const updatedTouchpoints = [touchpoint, ...existingTouchpoints].slice(0, 50);
  storage.setItem(STORAGE_KEYS.TOUCHPOINTS, updatedTouchpoints, options);

  // Increment visit count
  const visitCount = parseInt(
    (storage.getItem(STORAGE_KEYS.VISIT_COUNT) as string) || "0",
  );
  storage.setItem(STORAGE_KEYS.VISIT_COUNT, visitCount + 1, options);
}

/**
 * Get attribution data for analytics
 */
export function getAttributionData(analytics: Analytics): {
  currentSource: SourceData | null;
  firstTouch: any;
  utmParams: UtmParams;
  visitCount: number;
  touchpoints: Array<any>;
} {
  const { storage } = analytics;

  return {
    currentSource: storage.getItem(STORAGE_KEYS.SOURCE) as SourceData | null,
    firstTouch: storage.getItem(STORAGE_KEYS.FIRST_TOUCH),
    utmParams: (storage.getItem(STORAGE_KEYS.UTM) as UtmParams[])?.[0] || {},
    visitCount: parseInt(
      (storage.getItem(STORAGE_KEYS.VISIT_COUNT) as string) || "1",
    ),
    touchpoints:
      (storage.getItem(STORAGE_KEYS.TOUCHPOINTS) as Array<any>) || [],
  };
}

/**
 * Add attribution data to page view
 */
export function trackPageWithAttribution(
  analytics: Analytics,
  additionalData: Record<string, any> = {},
): void {
  const attributionData = getAttributionData(analytics);

  // Call page() with null for name (first argument) and properties as second argument
  analytics.page(null, {
    ...additionalData,
    ...attributionData.utmParams,
    attributionSource: attributionData.currentSource?.source,
    attributionMedium: attributionData.currentSource?.medium,
    firstTouchSource: attributionData.firstTouch?.source,
    firstTouchMedium: attributionData.firstTouch?.medium,
    firstTouchDate: attributionData.firstTouch?.timestamp,
    attributionCount: attributionData.visitCount,
    referrer: document.referrer || "direct",
  });
}
