// src/analytics.js
import Analytics from "analytics";
import originalSource from "analytics-plugin-original-source";
import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import customerIO from "@analytics/customerio";
import mixpanelPlugin from "@analytics/mixpanel";
import amplitudePlugin from "@analytics/amplitude";

// Create analytics instance with multiple destinations
const isDebug = import.meta.env.VITE_ENV === "development";

// Debug environment variables in development mode
// if (isDebug) {
//   console.group("🔍 Analytics Environment Variables");
//   console.log("VITE_ENV:", import.meta.env.VITE_ENV);
//   console.log("VITE_GA4_ID:", import.meta.env.VITE_GA4_ID);
//   console.log("VITE_GTAG_ID:", import.meta.env.VITE_GTAG_ID);
//   console.log("VITE_CIO_SITE:", import.meta.env.VITE_CIO_SITE);
//   console.log("VITE_MIXPANEL_ID:", import.meta.env.VITE_MIXPANEL_ID);
//   console.log("VITE_AMPLITUDE_ID:", import.meta.env.VITE_AMPLITUDE_ID);
//   console.groupEnd();
// }

const analytics = Analytics({
  app: "webmetic",
  debug: isDebug, // Enable debug mode for the entire analytics instance
  plugins: [
    // Google Analytics (GA4)
    googleAnalytics({
      measurementIds: [import.meta.env.VITE_GA4_ID],
    }),

    // Google Tag Manager
    googleTagManager({
      containerId: import.meta.env.VITE_GTAG_ID,
      debug: isDebug,
    }),

    // Customer.io
    customerIO({
      siteId: import.meta.env.VITE_CIO_SITE,
    }),

    // Mixpanel
    mixpanelPlugin({
      token: import.meta.env.VITE_MIXPANEL_ID,
    }),
    // amplitudePlugin({
    //   apiKey: import.meta.env.VITE_AMPLITUDE_ID,
    //   // See options at https://bit.ly/3dRdZnE
    //   options: {
    //     trackingOptions: {
    //       ip_address: false,
    //     },
    //   },
    // }),
    originalSource({
      // You can customize storage key names if needed
      originalSourceKey: "__user_original_source",
      originalLandingPageKey: "__user_original_landing_page",
      // Default is cookie storage, but you can change to localStorage
      storage: "localStorage",
    }),
  ],
});

if (isDebug) {
  window.analytics = analytics;
}

export default analytics;
