import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { CircleUser } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { DarkThemeToggle } from "flowbite-react";
import { drawers, menuItems } from "../../utils";
import { useDropdownMenu } from "@/routes/companies/hooks";
import analytics from "@/analytics";

import arrow from "../../../../../assets/icons/arrow-down.svg";

function HeaderDropdownMenu() {
  const {
    openDrawer,
    openedDropdowns,
    toggleDropdown,
    handleLogout,
    toggleDrawer,
    handleSpecialNavigation,
  } = useDropdownMenu();
  const handleLogoutWithReset = () => {
    // Reset analytics before logout
    analytics.reset();

    // Call the original logout handler
    handleLogout();
  };
  return (
    <DropdownMenu>
      <div className="mr-2 hidden sm:block">
        <DarkThemeToggle />
      </div>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="icon"
          className="rounded-full dark:bg-gray-500 dark:hover:bg-gray-400"
        >
          <CircleUser className="h-5 w-5" />
          <span className="sr-only">Account Menü</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400"
      >
        <DropdownMenuLabel>Mein Konto</DropdownMenuLabel>
        <DropdownMenuSeparator className="dark:bg-gray-600" />
        {menuItems.map(({ key, label, badge, options }) => (
          <div key={key}>
            <DropdownMenuItem
              onClick={
                options
                  ? (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleDropdown(key);
                    }
                  : () => toggleDrawer(key)
              }
            >
              {label}
              {options && (
                <img
                  src={arrow}
                  className={`h-[18px] ${openedDropdowns.includes(key) ? "rotate-180" : ""}`}
                />
              )}
              {badge && (
                <span className="me-2 ml-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                  {badge}
                </span>
              )}
            </DropdownMenuItem>

            {options &&
              options?.length > 0 &&
              openedDropdowns.includes(key) &&
              options.map((el) => (
                <DropdownMenuItem
                  key={el.key}
                  onClick={() => handleSpecialNavigation(el.key)}
                  className="ml-[12px]"
                >
                  {el.label}
                </DropdownMenuItem>
              ))}
          </div>
        ))}

        <DropdownMenuSeparator className="dark:bg-gray-600" />
        <DropdownMenuItem onClick={handleLogoutWithReset}>
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>

      {Object.entries(drawers).map(([key, Component]) => (
        <Component
          key={key}
          open={openDrawer === key}
          onClose={() => toggleDrawer(key)}
        />
      ))}
    </DropdownMenu>
  );
}

export default HeaderDropdownMenu;
