import { AxiosRequestConfig } from "axios";

import { makeHttpRequest } from "./httpClient";

import { AxiosResponseSuccess } from "./types";
import { makeCmsHttpRequest } from "@/api/cmsHttpClient.ts";

export enum HTTP_CLIENT {
  Api,
  Cms
}

function getRequestFunction(client?: HTTP_CLIENT) {
  switch (client) {
    case HTTP_CLIENT.Cms:
      return makeCmsHttpRequest;
    default:
      return makeHttpRequest;
  }
}

export function getData<SuccessPayload>(
  url: string,
  config?: AxiosRequestConfig,
  client?: HTTP_CLIENT
): AxiosResponseSuccess<SuccessPayload> {
  const makeHttpRequestFunction = getRequestFunction(client)
  return makeHttpRequestFunction<SuccessPayload>({ url, method: "GET", ...config });
}

export function postData<SuccessPayload>(
  url: string,
  config?: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: "POST", ...config });
}

export function putData<SuccessPayload>(
  url: string,
  config?: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: "PUT", ...config });
}

export function patchData<SuccessPayload>(
  url: string,
  config?: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: "PATCH", ...config });
}

export function deleteData<SuccessPayload>(
  url: string,
  config?: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: "DELETE", ...config });
}
