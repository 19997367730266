import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";
import { useAuth } from "../../provider/authProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import SHA256 from "crypto-js/sha256";
import SuccessModal from "./SuccessModal";
import validator from "validator";
import analytics from "@/analytics";
import { getAttributionData } from "@/utils/attribution";
import LogoWhite from "../../assets/ci/webmetic-logo-white.svg";
import LogoBlack from "../../assets/ci/webmetic-logo-black.svg";
import { captureException } from "@sentry/react";

// We'll use attribution data from localStorage instead of cookies

const RegisterModal = () => {
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [touched, setTouched] = useState({ website: false, email: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isWebsiteValid, setIsWebsiteValid] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  // Get tracking data from attribution system
  const getTrackingData = () => {
    // Get detailed attribution data from storage
    const attributionData = getAttributionData(analytics);
    const utmParams = attributionData.utmParams || {};

    // Check URL for lgs parameter directly (as a fallback)
    const urlParams = new URLSearchParams(window.location.search);
    const lgsParam = urlParams.get("lgs");

    // Check if we have an lgs parameter in URL that's not in attribution storage yet
    if (lgsParam && !utmParams.lead_gen_source) {
      utmParams.lead_gen_source = lgsParam;
    }

    return {
      // Source and partner information (from attribution system)
      source: attributionData.currentSource?.source || "direct",
      partner: attributionData.currentSource?.partner || "none",
      // No need for special flag for lead gen sources

      // Detailed attribution data
      firstTouch: attributionData.firstTouch || {},
      currentSource: attributionData.currentSource || {},
      utmParams: utmParams,
      visitCount: attributionData.visitCount || 1,
      referrer: document.referrer || "direct",

      // Attribution history touchpoints
      touchpoints: attributionData.touchpoints || [],
    };
  };

  const handleClose = () => {
    history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search,
    );
  };
  // Initialize tracking and set initial website
  useEffect(() => {
    // Ensure attribution data is fully loaded
    const preloadAttributionData = async () => {
      // Wait for analytics to be ready
      if (typeof analytics.once === "function") {
        analytics.once("ready", () => {
          // This will initialize the attribution data if not already done
          getAttributionData(analytics);
        });
      }
    };

    preloadAttributionData();

    // Set initial website from URL params
    const initialWebsite = searchParams.get("initialWebsite");
    setWebsite(initialWebsite ?? "");
  }, [searchParams]);

  const { isAuthenticated } = useAuth();

  const validateWebsite = (value) => {
    if (!value.trim()) {
      return "Website ist erforderlich";
    }

    // Remove protocol and www if present
    let websiteToCheck = value.trim().toLowerCase();
    websiteToCheck = websiteToCheck.replace(/^(https?:\/\/)?(www\.)?/, "");

    // Regex for website validation
    const websiteRegex =
      /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(\.[a-z]{2,63})?$/;

    if (!websiteRegex.test(websiteToCheck)) {
      return "Ungültige Website";
    }
    return "";
  };

  const validateEmail = (value) => {
    if (!value.trim()) {
      return "E-Mail ist erforderlich";
    }
    if (!validator.isEmail(value)) {
      return "Bitte geben Sie eine gültige E-Mail-Adresse ein!";
    }
    return "";
  };

  const handleWebsiteChange = (e) => {
    const value = e.target.value;
    setWebsite(value);
    setIsWebsiteValid(validateWebsite(value) === "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value) === "");
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    if (field === "website") {
      setWebsiteError(validateWebsite(website));
    } else if (field === "email") {
      setEmailError(validateEmail(email));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");
    setWebsiteError("");
    setEmailError("");

    const websiteValidationResult = validateWebsite(website);
    const emailValidationResult = validateEmail(email);

    setWebsiteError(websiteValidationResult);
    setEmailError(emailValidationResult);
    setTouched({ website: true, email: true });

    if (!websiteValidationResult && !emailValidationResult) {
      setIsSubmitting(true);

      try {
        // Get tracking and attribution data
        const trackingData = getTrackingData();

        // Prepare to submit registration data
        const response = await axios.post(
          `${import.meta.env.VITE_APP_API}/api/auth/user_signup`,
          {
            website,
            email,
            source: trackingData.source,
            partner: trackingData.partner,
            // Include leadGenSource directly from UTM params if available
            lead_gen_source: trackingData.utmParams.lead_gen_source,
            // Add attribution data
            attribution: {
              // Source, partner, and lead gen from URL parameters (sn, pn, lgs)
              sourceName: trackingData.utmParams.source_name,
              partnerName: trackingData.utmParams.partner_name,
              lgs: trackingData.utmParams.lead_gen_source,
              // First touch data
              firstTouchSource: trackingData.firstTouch?.source,
              firstTouchMedium: trackingData.firstTouch?.medium,
              firstTouchDate: trackingData.firstTouch?.timestamp,
              firstTouchLandingPage: trackingData.firstTouch?.landing_page,
              // Last touch data
              lastTouchSource: trackingData.currentSource?.source,
              lastTouchMedium: trackingData.currentSource?.medium,
              // Partner from attribution source if available
              partnerFromSource: trackingData.currentSource?.partner,
              // Additional attribution data
              visitCount: trackingData.visitCount,
              referrer: trackingData.referrer,
              utmParams: trackingData.utmParams,
              // Complete attribution history with timestamps
              touchpoints: trackingData.touchpoints || [],
            },
          },
        );

        if (response.data.code === 200) {
          const accountId = response.data.data;
          const userId = SHA256(email.toLowerCase()).toString();
          analytics.identify(userId, {});
          analytics.track("sign_up", {
            method: "email",
            account_id: accountId,
            // Source and partner tracking
            source: trackingData.source,
            partner: trackingData.partner,
            source_name: trackingData.utmParams.source_name,
            partner_name: trackingData.utmParams.partner_name,
            lead_gen_source: trackingData.utmParams.lead_gen_source,
            // Include attribution data in sign_up event
            firstTouchSource: trackingData.firstTouch?.source,
            firstTouchMedium: trackingData.firstTouch?.medium,
            firstTouchDate: trackingData.firstTouch?.timestamp,
            lastTouchSource: trackingData.currentSource?.source,
            lastTouchMedium: trackingData.currentSource?.medium,
            visitCount: trackingData.visitCount,
            referrer: trackingData.referrer,
            attribution_history: trackingData.touchpoints || [],
            ...trackingData.utmParams, // Spread UTM parameters
          });
          setShowSuccessModal(true);
        } else if (response.data.code === 409) {
          setEmailError("Ein Konto mit dieser E-Mail existiert bereits.");
          setTouched({ ...touched, email: true });
          analytics.track("error", {
            error: "signup_error",
            error_type: "email_duplicate",
            error_message: "Account with this email already exists",
            email,
            website,
          });
        } else {
          setGeneralError(
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
          );
          analytics.track("error", {
            error: "signup_error",
            error_type: "unexpected_error",
            error_message: "Unexpected error occurred during registration",
            email,
            website,
          });
        }
      } catch (error) {
        console.error("Network or server error:", error);
        captureException(error, { extra: { email, website } });

        setGeneralError(
          "Ein Verbindungsfehler ist aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
        );

        // Track network or server error
        analytics.track("error", {
          error: "signup_error",
          error_type: "network_server_error",
          error_message: error.message || "Network or server error occurred",
          email,
          website,
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      const { source, partner } = getTrackingData();

      // Track validation errors
      analytics.track("error", {
        error: "signup_error",
        error_type: "validation_error",
        error_message: "Form validation failed",
        website_error: websiteValidationResult,
        email_error: emailValidationResult,
        email,
        website,
      });
    }
  };

  return (
    <section className="w-full bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <div className="mb-6 flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={LogoBlack}
              className="mr-3 h-6 dark:hidden sm:h-9"
              alt="Webmetic Logo Light"
            />
            <img
              src={LogoWhite}
              className="mr-3 hidden h-6 dark:block sm:h-9"
              alt="Webmetic Logo Dark"
            />
          </Link>
        </div>

        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="p-6 sm:p-8">
            <h1 className="pb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Kostenlos testen!
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label
                  htmlFor="website"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Website
                </label>
                <input
                  type="text"
                  name="website"
                  id="website"
                  value={website}
                  onChange={handleWebsiteChange}
                  onBlur={() => handleBlur("website")}
                  className={`form-input transition-all duration-200 ${
                    websiteError && touched.website
                      ? "form-input-error"
                      : isWebsiteValid && touched.website
                        ? "form-input-success"
                        : ""
                  }`}
                  placeholder="beispiel.de"
                />
                <div className="min-h-[20px]">
                  {websiteError && touched.website && (
                    <p className="form-message-error transition-all duration-200">
                      <span className="form-message-highligh">Fehler:</span>{" "}
                      {websiteError}
                    </p>
                  )}
                  {isWebsiteValid && touched.website && !websiteError && (
                    <p className="form-message-success transition-all duration-200">
                      Die Website-URL wurde erfolgreich validiert.
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-1">
                <label htmlFor="email" className="form-label">
                  E-Mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => handleBlur("email")}
                  className={`form-input transition-all duration-200 ${
                    emailError && touched.email
                      ? "form-input-error"
                      : isEmailValid && touched.email
                        ? "form-input-success"
                        : ""
                  }`}
                  placeholder="name@beispiel.de"
                />
                <div className="min-h-[20px]">
                  {emailError && touched.email && (
                    <p className="form-message-error transition-all duration-200">
                      <span className="form-message-highlight">Fehler:</span>{" "}
                      {emailError}
                    </p>
                  )}
                  {isEmailValid && touched.email && !emailError && (
                    <p className="form-message-success transition-all duration-200">
                      Die E-Mail-Adresse ist gültig.
                    </p>
                  )}
                </div>
              </div>
              {generalError && (
                <p className="form-message-error transition-all duration-200">
                  <span className="form-message-highlight">Fehler:</span>{" "}
                  {generalError}
                </p>
              )}
              <p className="text-sm text-gray-600 dark:text-white">
                Es gelten unsere{" "}
                <Link
                  to="/agb"
                  className="text-gray-600 underline decoration-gray-500 dark:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AGB
                </Link>
                .
              </p>
              <button
                type="submit"
                className="btn my-4 w-full py-2.5 text-center"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Wird gesendet..."
                  : "Jetzt 14 Tage unverbindlich testen"}
              </button>
              <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
                Du hast bereits ein Konto?{" "}
                <Link
                  to={!isAuthenticated ? "/login" : "/dashboard"}
                  className="hover:text-primary-700 font-medium text-primary-600 underline hover:no-underline dark:text-primary-500"
                >
                  Jetzt einloggen!
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      <SuccessModal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
    </section>
  );
};

export default RegisterModal;
