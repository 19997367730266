import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Typography,
  IconButton,
  Tooltip,
  Input,
  Button,
} from "@material-tailwind/react";
import { captureException } from "@sentry/react";
import analytics from "@/analytics";

function UsersDrawer({ open, onClose }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [message, setMessage] = useState({ text: "", type: "" });

  const getRoleDisplay = (role) => {
    return role === "admin" ? "Admin" : "Nutzer";
  };

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open]);

  const fetchUsers = async () => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API}/api/account/users_list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      setMessage({
        text: "Nutzen konnten nicht geladen werden.",
        type: "error",
      });
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_API}/api/account/user_add`,
        { ...newUser, role: "user" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      // Track user_added event
      analytics.track("user_added", {
        user_email_domain: newUser.email.split("@")[1] || "",
        user_role: "user",
        inviter_role: "admin", // Assuming the current user is an admin since they can add users
        has_first_name: !!newUser.first_name,
        has_last_name: !!newUser.last_name,
      });

      setNewUser({ first_name: "", last_name: "", email: "" });
      fetchUsers();
      setMessage({
        text: "Benutzer wurde hinzugefügt und das Passwort an die E-Mail versendet.",
        type: "success",
      });
    } catch (err) {
      console.error("Error adding user:", err);
      if (
        err.response &&
        err.response.data &&
        err.response.data.detail === "A user with this email already exists"
      ) {
        setMessage({
          text: "Diese E-Mail-Adresse ist bereits registriert. Bitte verwenden Sie eine andere E-Mail-Adresse.",
          type: "error",
        });
      } else {
        setMessage({
          text: "Benutzer konnte nicht hinzugefügt werden. Bitte melden Sie sich bei ys@webmetic.de",
          type: "error",
        });
      }

      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async (email, role) => {
    if (role === "admin") {
      setMessage({
        text: "Admin users können nicht gelöscht werden.",
        type: "error",
      });
      return;
    }
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      await axios.delete(
        `${import.meta.env.VITE_APP_API}/api/account/user_remove`,
        {
          data: { email },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      // Track user_removed event
      analytics.track("user_removed", {
        user_email_domain: email.split("@")[1] || "",
        user_role: role,
        removal_reason: "admin_action", // The admin is removing the user
        remover_role: "admin", // Assuming the current user is an admin
      });

      fetchUsers();
      setMessage({ text: "Benutzer wurde gelöscht.", type: "success" });
    } catch (err) {
      console.error("Error deleting user:", err);
      setMessage({
        text: "Benutzer konnte nicht gelöscht werden. Bitte melde dich bei ys@webmetic.de",
        type: "error",
      });
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (email) => {
    setIsLoading(true);
    setMessage({ text: "", type: "" });
    try {
      await axios.post(
        `${import.meta.env.VITE_APP_API}/api/auth/user_password_reset`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setMessage({
        text: "Das Passwort wurde neu generiert und versendet.",
        type: "success",
      });
    } catch (err) {
      console.error("Error resetting password:", err);
      setMessage({
        text: "Passwort konnte nicht neu erstellt werden. Bitte melde dich bei ys@webmetic.de",
        type: "error",
      });
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      className="h-full overflow-y-auto border-l drop-shadow-lg dark:border-l-gray-600 dark:bg-gray-800 md:w-full lg:w-3/4 2xl:w-2/3"
      size={10000}
      overlay={true}
      overlayProps={{
        className: "fixed inset-0 bg-black bg-opacity-60",
      }}
    >
      <div className="-mt-[1px] flex items-center justify-between border-b px-4 py-3 dark:border-b-gray-600">
        <Typography
          variant="h4"
          color="blue-gray"
          className="dark:text-green-400"
        >
          Benutzer
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Benutzerverwaltung
        </h2>
        <p className="mb-6 text-gray-500 dark:text-gray-400">
          Mit dieser Benutzerverwaltung können Sie neue Zugänge einrichten,
          Passwörter bei Bedarf zurücksetzen und nicht mehr genutzte Konten
          löschen.
        </p>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                Vorname
              </th>
              <th scope="col" className="px-4 py-3">
                Nachname
              </th>
              <th scope="col" className="px-4 py-3">
                E-Mail
              </th>
              <th scope="col" className="px-4 py-3">
                Rolle
              </th>
              <th scope="col" className="px-4 py-3">
                Optionen
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                key={index}
                className={`hover:bg-gray-100 dark:hover:bg-gray-700 ${
                  index === users.length - 1
                    ? ""
                    : "border-b dark:border-gray-600"
                }`}
              >
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <div className="flex items-center space-x-1.5">
                    {user.first_name}
                  </div>
                </td>
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <div className="flex items-center space-x-1.5">
                    {user.last_name}
                  </div>
                </td>
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <div className="flex items-center space-x-1.5">
                    {user.email}
                  </div>
                </td>
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <div className="flex items-center space-x-1.5">
                    {getRoleDisplay(user.role)}
                  </div>
                </td>
                <td className="whitespace-nowrap px-4 py-3 font-medium text-gray-900 dark:text-white">
                  <Tooltip content="Passwort zurücksetzen">
                    <button
                      onClick={() => resetPassword(user.email)}
                      className="mr-2 inline-flex items-center rounded-lg p-1.5 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-800 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-gray-100"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 48 48"
                        version="1.1"
                        viewBox="0 0 48 48"
                        xmlSpace="preserve"
                        className="h-6 w-6 text-gray-800 dark:text-white"
                      >
                        <circle
                          cx="32"
                          cy="16"
                          r="3"
                          fill="currentColor"
                        ></circle>
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          d="M30 6.5c-6.351 0-11.5 5.149-11.5 11.5 0 1.066.156 2.093.428 3.072L6.5 33.5v8h8v-4h5v-5h5v-4.399A11.444 11.444 0 0030 29.5c6.351 0 11.5-5.149 11.5-11.5S36.351 6.5 30 6.5z"
                        ></path>
                      </svg>
                    </button>
                  </Tooltip>
                  {user.role !== "admin" && (
                    <Tooltip content="Benutzer löschen">
                      <button
                        onClick={() => deleteUser(user.email, user.role)}
                        className="inline-flex items-center rounded-lg p-1.5 text-center text-sm font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-800 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-gray-100"
                      >
                        <svg
                          className="h-6 w-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 p-4">
        <h3 className="mb-4 text-xl font-semibold dark:text-white">
          Neuen Benutzer hinzufügen
        </h3>
        <form onSubmit={addUser} className="space-y-4">
          <div className="flex space-x-4">
            <div className="flex-1">
              <label
                htmlFor="first_name"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Vorname
              </label>
              <input
                type="text"
                id="first_name"
                value={newUser.first_name}
                onChange={(e) =>
                  setNewUser({ ...newUser, first_name: e.target.value })
                }
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
                placeholder="Max"
                required
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="last_name"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Nachname
              </label>
              <input
                type="text"
                id="last_name"
                value={newUser.last_name}
                onChange={(e) =>
                  setNewUser({ ...newUser, last_name: e.target.value })
                }
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
                placeholder="Mustermann"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              E-Mail
            </label>
            <input
              type="email"
              id="email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light dark:focus:border-primary-500 dark:focus:ring-primary-500"
              placeholder="name@beispiel.de"
              required
            />
          </div>
          <button className="btn w-full" type="submit" disabled={isLoading}>
            {isLoading ? "Benutzer wird hinzugefügt..." : "Hinzufügen"}
          </button>
        </form>
      </div>
      {message.text && (
        <div
          className={`m-4 rounded-lg p-4 ${
            message.type === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {message.text}
        </div>
      )}
    </Drawer>
  );
}

export default UsersDrawer;
