import { BlockProps } from "@/components/content/Blocks/types.ts"
import { FC } from "react"
import {
  ContactSectionWithAddressLocationBlock, DefaultRelatedArticlesBlock, FaqAsAccordionBlock, GridLayoutCleanBlock,
  ImagesWithHeadingAndDescriptionBlock, ImageWithCtaButtonBlock, VisualImageWithHeadingBlock
} from "@/components/content/Blocks/Blocks";

const blocks: { [k: string]: FC<BlockProps> } = {
  'content--images-with-heading-and-description': ImagesWithHeadingAndDescriptionBlock,
  'contact--contact-section-with-address-location': ContactSectionWithAddressLocationBlock,
  'hero--visual-image-with-heading': VisualImageWithHeadingBlock,
  'team--grid-layout-clean': GridLayoutCleanBlock,
  'faq--faq-as-accordion': FaqAsAccordionBlock,
  'related-articles--default-related-articles': DefaultRelatedArticlesBlock,
  'cta--image-with-cta-button': ImageWithCtaButtonBlock
}

export const BlockElement = ({ block }: BlockProps) => {
  const BlockComponent = blocks[block.blockType]
  if (BlockComponent) {
    return <BlockComponent block={block} />
  }
  console.warn('UNKNOWN BLOCK', block.blockType)

  return null
}
