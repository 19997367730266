import { cn } from "@/lib/utils.ts";

export const Badge = ({content, className = ''}: {content: string, className?: string}) => {
  return (
    <div className={cn(
      "bg-energetic-mint-500 text-black text-xs h-6 w-6 rounded-full flex items-center justify-center",
      className
    )}>
      {content}
    </div>
  )
}
