export const Customer5 = () => {
  return (
    <svg width="277" height="120" viewBox="0 0 277 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="277" height="120" fill="#FAFAFA"/>
      <path d="M54.1184 55.2412H63.6358V64.7586C58.3854 64.7586 54.1184 60.4916 54.1184 55.2412Z" fill="#A3A3A3"/>
      <path d="M63.6358 64.7588V74.2762H54.1184C54.1184 69.0099 58.3854 64.7588 63.6358 64.7588Z" fill="#D4D4D4"/>
      <path d="M73.1536 55.2412H82.6709V64.7586C77.4205 64.7586 73.1536 60.4916 73.1536 55.2412Z" fill="#D4D4D4"/>
      <path d="M73.1529 74.2762H63.6355V64.7588C68.8859 64.7588 73.1529 69.0099 73.1529 74.2762Z" fill="#D4D4D4"/>
      <path d="M82.6709 45.7246V55.242H73.1536C73.1536 49.9757 77.4205 45.7246 82.6709 45.7246Z" fill="#D4D4D4"/>
      <path d="M73.1529 55.242H63.6355V45.7246C68.8859 45.7246 73.1529 49.9757 73.1529 55.242Z" fill="#A3A3A3"/>
      <path d="M63.6355 64.7586V55.2412H73.1529C73.1529 60.4916 68.8859 64.7586 63.6355 64.7586Z" fill="#A3A3A3"/>
      <path d="M82.6709 64.7588V74.2762H73.1536C73.1536 69.0099 77.4205 64.7588 82.6709 64.7588Z" fill="#A3A3A3"/>
      <path d="M63.6358 45.7246V55.242H54.1184C54.1184 49.9757 58.3854 45.7246 63.6358 45.7246Z" fill="#A3A3A3"/>
      <path d="M107.836 67.9008C112.355 67.9008 115.035 65.1053 115.035 60.4702C115.035 55.8496 112.355 53.0686 107.937 53.0686H102.81V67.9008H107.836ZM105.497 65.576V55.3933H107.785C110.798 55.3933 112.37 57.0736 112.37 60.4702C112.37 63.8813 110.798 65.576 107.706 65.576H105.497Z" fill="#737373"/>
      <path d="M122.193 68.1181C124.785 68.1181 126.567 66.8507 127.031 64.917L124.583 64.6418C124.228 65.5833 123.359 66.0758 122.229 66.0758C120.534 66.0758 119.412 64.9604 119.39 63.0557H127.139V62.2518C127.139 58.3482 124.793 56.6318 122.055 56.6318C118.868 56.6318 116.79 58.971 116.79 62.4039C116.79 65.8947 118.84 68.1181 122.193 68.1181ZM119.397 61.2886C119.477 59.8691 120.527 58.6741 122.091 58.6741C123.598 58.6741 124.612 59.7749 124.626 61.2886H119.397Z" fill="#737373"/>
      <path d="M137.945 59.717C137.583 57.834 136.077 56.6318 133.47 56.6318C130.79 56.6318 128.965 57.9499 128.972 60.0067C128.965 61.629 129.964 62.7008 132.101 63.1426L133.998 63.5409C135.02 63.7655 135.498 64.1783 135.498 64.8084C135.498 65.5688 134.672 66.1409 133.426 66.1409C132.224 66.1409 131.442 65.6195 131.217 64.62L128.661 64.8663C128.987 66.9086 130.703 68.1181 133.433 68.1181C136.215 68.1181 138.177 66.6769 138.184 64.5694C138.177 62.9833 137.156 62.0128 135.056 61.5565L133.158 61.151C132.028 60.8975 131.579 60.5064 131.587 59.8618C131.579 59.1086 132.412 58.5872 133.506 58.5872C134.715 58.5872 135.353 59.2462 135.555 59.9777L137.945 59.717Z" fill="#737373"/>
      <path d="M140.142 67.9008H142.764V56.7766H140.142V67.9008ZM141.46 55.1978C142.293 55.1978 142.974 54.5605 142.974 53.7783C142.974 52.9889 142.293 52.3516 141.46 52.3516C140.62 52.3516 139.94 52.9889 139.94 53.7783C139.94 54.5605 140.62 55.1978 141.46 55.1978Z" fill="#737373"/>
      <path d="M150.094 72.3041C153.099 72.3041 155.316 70.9281 155.316 68.0819V56.7766H152.73V58.6162H152.585C152.187 57.8123 151.354 56.6318 149.391 56.6318C146.82 56.6318 144.8 58.6451 144.8 62.3025C144.8 65.9309 146.82 67.7415 149.384 67.7415C151.289 67.7415 152.18 66.7203 152.585 65.9019H152.716V68.0095C152.716 69.6462 151.629 70.3198 150.137 70.3198C148.559 70.3198 147.863 69.5738 147.545 68.922L145.184 69.4941C145.662 71.0512 147.262 72.3041 150.094 72.3041ZM150.116 65.6847C148.385 65.6847 147.472 64.3376 147.472 62.288C147.472 60.2674 148.37 58.7827 150.116 58.7827C151.803 58.7827 152.73 60.1805 152.73 62.288C152.73 64.41 151.789 65.6847 150.116 65.6847Z" fill="#737373"/>
      <path d="M160.42 61.3827C160.42 59.7749 161.391 58.8479 162.774 58.8479C164.128 58.8479 164.939 59.7387 164.939 61.2234V67.9008H167.561V60.8178C167.568 58.1527 166.048 56.6318 163.752 56.6318C162.086 56.6318 160.942 57.4284 160.435 58.6669H160.304V56.7766H157.799V67.9008H160.42V61.3827Z" fill="#737373"/>
      <path d="M174.947 68.1181C177.54 68.1181 179.322 66.8507 179.785 64.917L177.337 64.6418C176.982 65.5833 176.113 66.0758 174.983 66.0758C173.289 66.0758 172.166 64.9604 172.144 63.0557H179.894V62.2518C179.894 58.3482 177.547 56.6318 174.81 56.6318C171.623 56.6318 169.544 58.971 169.544 62.4039C169.544 65.8947 171.594 68.1181 174.947 68.1181ZM172.152 61.2886C172.231 59.8691 173.281 58.6741 174.846 58.6741C176.352 58.6741 177.366 59.7749 177.381 61.2886H172.152Z" fill="#737373"/>
      <path d="M181.908 67.9008H184.529V61.361C184.529 59.9488 185.594 58.9493 187.035 58.9493C187.477 58.9493 188.027 59.029 188.252 59.1014V56.6897C188.013 56.6463 187.6 56.6173 187.311 56.6173C186.036 56.6173 184.971 57.3415 184.566 58.6307H184.45V56.7766H181.908V67.9008Z" fill="#737373"/>
      <path d="M198.582 59.717C198.22 57.834 196.713 56.6318 194.106 56.6318C191.427 56.6318 189.602 57.9499 189.609 60.0067C189.602 61.629 190.601 62.7008 192.737 63.1426L194.635 63.5409C195.656 63.7655 196.134 64.1783 196.134 64.8084C196.134 65.5688 195.308 66.1409 194.063 66.1409C192.861 66.1409 192.078 65.6195 191.854 64.62L189.297 64.8663C189.623 66.9086 191.34 68.1181 194.07 68.1181C196.851 68.1181 198.814 66.6769 198.821 64.5694C198.814 62.9833 197.793 62.0128 195.692 61.5565L193.795 61.151C192.665 60.8975 192.216 60.5064 192.223 59.8618C192.216 59.1086 193.049 58.5872 194.142 58.5872C195.352 58.5872 195.989 59.2462 196.192 59.9777L198.582 59.717Z" fill="#737373"/>
      <path d="M203.401 61.3827C203.401 59.7749 204.4 58.8479 205.805 58.8479C207.181 58.8479 207.992 59.7242 207.992 61.2234V67.9008H210.614V60.8178C210.614 58.1309 209.093 56.6318 206.783 56.6318C205.074 56.6318 203.987 57.4067 203.473 58.6669H203.343V53.0686H200.779V67.9008H203.401V61.3827Z" fill="#737373"/>
      <path d="M213.082 67.9008H215.704V56.7766H213.082V67.9008ZM214.401 55.1978C215.233 55.1978 215.914 54.5605 215.914 53.7783C215.914 52.9889 215.233 52.3516 214.401 52.3516C213.56 52.3516 212.88 52.9889 212.88 53.7783C212.88 54.5605 213.56 55.1978 214.401 55.1978Z" fill="#737373"/>
      <path d="M218.196 72.0724H220.818V66.1482H220.927C221.339 66.9593 222.201 68.0964 224.113 68.0964C226.735 68.0964 228.698 66.0178 228.698 62.3532C228.698 58.6451 226.677 56.6318 224.106 56.6318C222.143 56.6318 221.325 57.8123 220.927 58.6162H220.774V56.7766H218.196V72.0724ZM220.767 62.3387C220.767 60.1805 221.694 58.7827 223.382 58.7827C225.127 58.7827 226.025 60.2674 226.025 62.3387C226.025 64.4245 225.113 65.9454 223.382 65.9454C221.709 65.9454 220.767 64.4969 220.767 62.3387Z" fill="#737373"/>
    </svg>

  )
}
