import { forwardRef } from "react";

export const Features = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="features" className="bg-gray-50" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-center sm:py-16">
        <h2
          id="features-title"
          className="mx-auto mb-12 max-w-[40rem] text-4xl font-extrabold tracking-tight text-neutral-900"
        >
          Wie statische Landing Pages Ihr
          <br /> Wachstum bremsen
        </h2>
        <div
          id="feature-list"
          className="space-y-8 md:grid md:grid-cols-2 md:gap-5 md:space-y-0 lg:grid-cols-3"
        >
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature1"
          >
            <p className="text-base">01</p>
            <h3 className="text-3xl font-normal">Niedrige Conversion-Rate</h3>
            <p className="text-base font-light">
              Ein Bauleiter mit Großbestellung sieht dieselben Produkte wie ein
              Handwerksbetrieb, der nur ein einzelnes Werkzeug braucht.
            </p>
            <div>
              <p className="text-base font-light">Resultat:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Industrie-Kunden finden keine passenden Produktlösungen →
                  springen ab
                </li>
                <li className="mb-3.5">
                  Handwerker erhalten zu viele irrelevante Optionen → brechen
                  Kaufprozess ab
                </li>
                <li>
                  Potenzielle Großkunden werden nicht gezielt angesprochen →
                  gehen zur Konkurrenz
                </li>
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature2"
          >
            <p className="text-base">02</p>
            <h3 className="text-3xl font-normal">
              Ineffiziente Marketing-Ausgaben
            </h3>
            <p className="text-base font-light">
              Würth investiert hohe Summen in Google Ads und B2B-Marketing, doch
              wenn alle auf dieselbe Seite geleitet werden, verpufft das
              Potenzial.
            </p>
            <div>
              <p className="text-base font-light">Resultat:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Hohe Werbekosten, aber niedrige Conversion-Rate
                </li>
                <li className="mb-3.5">
                  Interessierte Besucher springen ab, weil Inhalte nicht
                  spezifisch genug sind
                </li>
                <li>
                  Insights über Besucherverhalten bleiben ungenutzt, statt zur
                  Optimierung des digitalen Auftritts beizutragen
                </li>
              </ul>
            </div>
          </div>
          <div
            className="flex flex-col gap-3.5 rounded-lg bg-graphite p-8 text-left text-white"
            id="feature3"
          >
            <p className="text-base">03</p>
            <h3 className="text-3xl font-normal">
              Verlorene Geschäftsabschlüsse
            </h3>
            <p className="text-base font-light">
              Die Kombination aus dem riesigen Sortiment und der vielfältigen
              Kundschaft birgt immense Chancen - kann aber ohne smartes Konzept
              zur Herausforderung werden.
            </p>
            <div>
              <p className="text-base font-light">Ergebnis:</p>
              <ul className="list-disc pl-4">
                <li className="mb-3.5">
                  Irrelevante Angebote verlängern die Suche
                  <br />→ Erhöhtes Frustpotenzial und weniger Bestellungen
                </li>
                <li className="mb-3.5">
                  Komplizierte Kaufprozesse bergen das Risiko von Abbrüchen
                </li>
                <li>
                  Wettbewerber mit besseren digitalen Erlebnissen gewinnen
                  wertvolle Kunden
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
