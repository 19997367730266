import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTTableCell: FC<RTElementProps> = ({node}: RTElementProps) => {
  const CellTag = (node.headerState ?? 0) > 0 ? 'th' : 'td'
  const childClasses = 'text-base m-0 p-3'
  const backgroundClass = CellTag == 'th' ? 'bg-grey-50 dark:bg-gray-700' : ''
  return (
    <CellTag className={backgroundClass}>
      <RTChildren children={node.children} className={childClasses}/>
    </CellTag>
  )
}
