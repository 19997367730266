import { Button, Modal } from "flowbite-react";

export interface InfoModalProps {
  show: boolean;
  onClose: () => void;
  message: string;
}

export const InfoModal = ({ show, onClose, message }: InfoModalProps) => {
  return (
    <Modal show={show} onClose={onClose} position="center" dismissible>
      <Modal.Header className="">Info</Modal.Header>
      <Modal.Body>
        <p className="text-lg">{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onClose}
          className="hover:bg-energetic-mint-400 bg-energetic-mint-500 text-black"
        >
          Fenster schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
