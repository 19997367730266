import {
  AdsClick,
  CurrencyExchange,
  AccountCircle,
  SignalCellularAlt,
} from "@/pages/smartLandingPage/webmetic/benefit_icons";
import { ArrowRight } from "@/assets/icons";
import { forwardRef } from "react";

const benefits = [
  {
    icon: AdsClick,
    title: "Höhere Relevanz",
    description:
      "Jeder Freelancer sieht nur die Projekte, die zu seinen Skills und Erfahrungen passen",
    result: "Gezieltere, schneller Vermittlungen",
  },
  {
    icon: CurrencyExchange,
    title: "Effizientes Matching",
    description: "Mehr qualifizierte Bewerbungen erhöhen die Trefferchancen.",
    result: "Weniger Abmeldungen und höhere Kundenzufriedenheit",
  },
  {
    icon: SignalCellularAlt,
    title: "Langfristige Erfolge",
    description:
      "Effizientes Matching führt zu erfolgreichen Projekten und wiederkehrenden Aufträgen.",
    result: "Stärkere Kundenbindung und Plattform-Wachstum",
  },
  {
    icon: AccountCircle,
    title: "Zielführendes Reporting",
    description:
      "Echtzeit-Analysen zeigen, welche Angebote und Freelancer am besten performen.",
    result: "Bessere Entscheidungen und höhere Vermittlungsraten",
  },
];
export const Benefits = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="benefits" className="bg-pulse text-white" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-left sm:py-16">
        <p id="benefits-intro" className="mb-6 text-lg text-energetic-mint-500">
          IHRE VORTEILE
        </p>
        <h2
          id="benefits-title"
          className="mb-6 max-w-3xl text-4xl font-semibold tracking-tight text-white"
        >
          Mehr Matches - schnellere Vermittlungen
        </h2>
        <p id="benefits-text" className="mb-12">
          Maximieren Sie jetzt den Erfolg von&nbsp;
          <a
            className="underline"
            href="https://www.freelance.de/"
            target="_blank"
          >
            freelance.de
          </a>
          &nbsp; - vom ersten Blick bis zum letzten Klick
        </p>
        <div
          id="benefits-list"
          className="mb-6 md:grid md:grid-cols-2 md:gap-5 lg:flex lg:flex-nowrap"
        >
          {benefits.map((benefit, index) => (
            <div
              className="flex flex-col gap-5 rounded-md bg-graphite p-8 text-left text-white lg:min-w-[27%]"
              key={index}
              id={`benefit${index + 1}`}
            >
              <benefit.icon></benefit.icon>
              <h3 className="text-xl font-semibold">{benefit.title}</h3>
              <p className="pr-4 text-sm font-normal">{benefit.description}</p>
              <p className="text-sm font-bold">
                <ArrowRight className="ml-0 mr-1 inline-block h-4 w-4" />
                {benefit.result}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});
