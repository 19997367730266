import { ContextsType } from "@/pages/smartLandingPage/drawer/types";

export const webmeticContext: ContextsType = {
  unternehmensbasis: [],
  kontakt_standort: [],
  management_rechtliches: [],
  geschaeftstaetigkeit: [],
  online_praesenz: [],
  marke_kommunikation: [],
  zielgruppen_marketing: [],
  nutzerdaten: [],
};
