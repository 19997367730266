import { useEffect, RefObject } from "react";

export const useActiveSection = (
  sectionRefs: RefObject<HTMLElement>[],
  setActiveSection: (id: string) => void
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 }
    );

    sectionRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, [sectionRefs, setActiveSection]);
};
