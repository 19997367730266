import { RTElementProps } from "@/components/content/RichText/types.ts"
import { FC } from "react"
import { cn } from "@/lib/utils.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTHeading: FC<RTElementProps> = ({node}: RTElementProps) => {
  const HeadingTag = node.tag ?? 'h1'
  const headingClass = `${HeadingTag}-style`
  const marginClass = node.tag == 'h2' || node.tag == 'h3' ? 'mb-6' : 'mb-4'
  return (
    <HeadingTag className={cn(headingClass, marginClass)}>
      <RTChildren children={node.children} />
    </HeadingTag>
  )
}
