import { Loader2 } from "lucide-react";

interface ConfirmationProps {
  error: boolean;
  isLoading: boolean;
  handleClick: () => void;
}
export const Confirmation = ({
  error,
  isLoading,
  handleClick,
}: ConfirmationProps) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-10">
      <p className="flex flex-col items-center justify-center gap-2">
        <h1 className="dark:text-white">BESTÄTIGUNGSSEITE</h1>
        <button
          className="btn flex items-center gap-3"
          type="submit"
          onClick={handleClick}
        >
          {isLoading && (
            <span className="flex items-center gap-3">
              <Loader2 className="animate-spin" />
              <span>Verbinden</span>
            </span>
          )}
          {error && !isLoading && <span>Irgendwas ist schiefgelaufen</span>}
          {!isLoading && !error && <span>Verbindung bestätigen</span>}
        </button>
      </p>
    </div>
  );
};
