import { apiHandlers } from "@/api";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useSegmentMutationsProps {
  disableLexwareMutation: UseMutateFunction<
    AxiosResponse<unknown, any>,
    Error,
    {},
    unknown
  >;
  isPending: boolean;
}

export const useDisableLexwareMutation = (): useSegmentMutationsProps => {
  const { mutate: disableLexwareMutation, isPending } = useMutation({
    mutationFn: apiHandlers.integrations.lexWare.disable,
  });

  return {
    disableLexwareMutation,
    isPending,
  };
};
