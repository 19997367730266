import { SyncSegmentHubSpotData, apiHandlers } from "@/api";
import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useSyncSegmentMutationsProps {
  syncSegmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    SyncSegmentHubSpotData,
    unknown
  >;
  unlinkSegmentMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    SyncSegmentHubSpotData,
    unknown
  >;
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  isUnlinking: boolean;
  isUnlinkError: boolean;
}

export const useSyncSegmentMutation = (): useSyncSegmentMutationsProps => {
  const {
    mutateAsync: syncSegmentMutation,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: apiHandlers.integrations.hubspot.syncSegment,
  });

  const {
    mutateAsync: unlinkSegmentMutation,
    isPending: isUnlinking,
    isError: isUnlinkError,
  } = useMutation({
    mutationFn: apiHandlers.integrations.hubspot.syncSegment,
  });

  return {
    syncSegmentMutation,
    isPending: isPending || isUnlinking,
    isError,
    isSuccess,
    unlinkSegmentMutation,
    isUnlinking,
    isUnlinkError,
  };
};
