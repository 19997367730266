import { IconButton } from "@/components";
import { Loader2 } from "lucide-react";
import { MouseEvent } from "react";
import tick from "../../../../assets/icons/tick.svg";

export const IntegrationButton = ({
  isEnabled,
  isPending,
  isSegmentLinked,
  onSync,
  onUnlink,
  iconSrc,
}: {
  isEnabled: boolean;
  isPending: boolean;
  isSegmentLinked: boolean;
  onSync: () => void;
  onUnlink: () => void;
  iconSrc: string;
}) => {
  return isEnabled ? (
    <div className="relative">
      <IconButton
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          if (isSegmentLinked) {
            onUnlink();
          } else {
            onSync();
          }
        }}
        icon={<img src={iconSrc} className="h-[18px] w-[18px] rounded-full" />}
      />
      {isPending && (
        <div className="absolute right-[4px] top-[4px] rounded-full bg-white">
          <Loader2 className="h-[8px] w-[8px] animate-spin" />
        </div>
      )}
      {isSegmentLinked && !isPending && (
        <div className="absolute right-[4px] top-[4px] rounded-full bg-white">
          <img src={tick} alt="Tick" className="h-[10px] w-[10px]" />
        </div>
      )}
    </div>
  ) : null;
};
