import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTCodeParagraph: FC<RTElementProps> = ({node}: RTElementProps) => {
  return (
    <pre className='overflow-auto my-8 px-6 py-4'>
      <code>
        <RTChildren children={node.children} />
      </code>
    </pre>
  )
}
