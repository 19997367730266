import { forwardRef } from "react";

const stats = [
  {
    title: "+202%²",
    description: "höhere Engagement-Rate",
    source:
      "https://blog.hubspot.com/marketing/personalized-calls-to-action-convert-better-data",
    sourceText:
      "HubSpot (2022): Personalisierte CTAs auf dynamischen Landingpages erzielen 202% höhere Conversion-Raten",
  },
  {
    title: "2x³",
    description: "mehr Conversions",
    source: "https://makewebbetter.com/blog/hubspot-marketing-statistics/",
    sourceText:
      "Marketo (2021): The Impact of Personalization on Conversion Rates",
  },
  {
    title: "30%⁴",
    description: "niedrigere Absprungrate",
    source: "https://www.exovia.de/journal/landing-page/",
    sourceText:
      "Exovia (2021): E-Commerce-Fallstudie - Reduktion der Absprungrate um 20% durch klarere Botschaften",
  },
  {
    title: "25%⁵",
    description: "höhere Marketing-ROI",
    source: "https://www.exovia.de/journal/landing-page/",
    sourceText:
      "Exovia (2021): Dienstleistungsbranche-Fallstudie - 25% höherer ROI durch bessere Lead-Generierung",
  },
];

export const Stats = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="stats" className="flex w-full bg-platin" ref={ref}>
      <div className="w-1/2">
        <div className="ml-auto w-full max-w-[35rem] py-8 lg:py-20">
          <div className="">
            <h1
              id="stats-title"
              className="mb-4 max-w-2xl text-4xl font-extrabold leading-none tracking-tight"
            >
              Mehr Wachstum durch Personalisierung
            </h1>
            <p
              id="stats-description"
              className="mb-10 text-lg font-normal text-black"
            >
              Statische Landing Pages erzielen eine durchschnittliche
              Conversion-Rate von 2-5%. Mit dynamischen, personalisierten
              Pages&nbsp;
              <span className="font-bold">
                steigern Sie diese Rate um ganze 30-50%!¹
              </span>
            </p>
            <div id="stats-list" className="grid grid-cols-2 gap-5">
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className="rounded-lg bg-white px-5 py-4"
                  id={`stat${index + 1}`}
                >
                  <h3 className="text-[2rem] font-semibold leading-10 text-energetic-mint-500">
                    {stat.title}
                  </h3>
                  <p className="text-sm font-normal text-black">
                    {stat.description}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-8 text-xs text-gray-600">
              <p>
                ¹{" "}
                <a
                  href="https://landingi.com/de/landing-page/optimierungsfallstudien/"
                  className="underline hover:text-gray-800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Landingi (2022) & Exovia (2021): Conversion-Rate-Steigerungen
                  von 35% bis 79,3% durch dynamische Inhalte und A/B-Testing
                </a>
              </p>
              {stats.map((stat, index) => (
                <p key={`source-${index}`} className="mt-1">
                  {stat.title.slice(-1)}{" "}
                  <a
                    href={stat.source}
                    className="underline hover:text-gray-800"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {stat.sourceText}
                  </a>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 py-4 pl-8 pr-2.5">
        <div className="h-full w-full bg-[url(./assets/images/webmetic-dotgrid.svg)] bg-cover bg-no-repeat"></div>
      </div>
    </section>
  );
});
