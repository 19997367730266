import { BlockProps } from "@/components/content/Blocks/types.ts";
import { GridLayoutClean, Images144X144 } from "@/types";
import { getCmsImageUrl } from "@/utils/cms.ts";

// Flowbite block https://flowbite.com/blocks/marketing/team/#grid-layout-clean

// Extract the TeamMember type from GridLayoutClean
type TeamMember = NonNullable<
  NonNullable<GridLayoutClean["team-members"]>[number]
>;

const TeamMemberCard = ({ teamMember }: { teamMember: TeamMember }) => {
  const avatar: Images144X144 = teamMember.avatar as Images144X144;
  return (
    <div className="text-center text-gray-500 dark:text-gray-400">
      {avatar.sizes?.avatar && <img
        className="mx-auto mb-4 h-36 w-36 rounded-full"
        src={getCmsImageUrl(avatar.sizes.avatar.url)}
        alt={avatar.alt ?? ''}
      />}
      <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {teamMember.name}
      </h3>
      <p>{teamMember.position}</p>
    </div>
  );
};

export const GridLayoutCleanBlock = ({ block }: BlockProps) => {
  const blockFields: GridLayoutClean = block as GridLayoutClean;

  return (
    <section className="bg-platin dark:bg-graphite">
      <div className="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-6 lg:py-16">
        <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-black dark:text-white subheading-text">
            {blockFields.title}
          </h2>
          <p className="font-light text-black dark:text-white sm:text-xl body-text">
            {blockFields.text}
          </p>
        </div>
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-16">
          {blockFields["team-members"] && blockFields["team-members"].map((teamMember, index) => (
            <TeamMemberCard teamMember={teamMember} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};
