import analytics from "@/analytics";
import { trackAttribution, getAttributionData } from "@/utils/attribution";
import { cleanUrlParameters } from "@/utils/cleanUrlParameters";

// Type definitions for the analytics module
interface EventProperties {
  [key: string]: any;
}

/**
 * Sets up attribution tracking with URL parameter cleaning
 * This function tracks attribution data and then cleans the URL parameters
 */
export const setupAttributionTracking = (): void => {
  // Create a variable to prevent duplicate events
  let attributionTracked = false;

  const trackAttributionOnce = (trigger: string) => {
    // Skip if we've already tracked attribution
    if (attributionTracked) return;

    // Track attribution with your detailed logic
    // Cast analytics to any to avoid TypeScript errors with the Analytics interface
    const attributionResult = trackAttribution(analytics as any);

    // Get the full attribution data
    const attributionData = getAttributionData(analytics as any);

    // Create the event properties object
    const eventProperties: EventProperties = {
      path: window.location.pathname,
      url: window.location.href,
      source: attributionResult.source.source,
      medium: attributionResult.source.medium,
      triggered_by: trigger,

      // Add the additional fields
      attributionSource: attributionData.currentSource?.source,
      attributionMedium: attributionData.currentSource?.medium,
      firstTouchSource: attributionData.firstTouch?.source,
      firstTouchMedium: attributionData.firstTouch?.medium,
      firstTouchDate: attributionData.firstTouch?.timestamp,
      attributionCount: attributionData.visitCount,
    };

    // Flatten UTM parameters if they exist
    if (attributionResult.utmParams) {
      // Add each UTM parameter as a top-level property
      Object.keys(attributionResult.utmParams).forEach((key) => {
        eventProperties[key] = attributionResult.utmParams?.[key];
      });
    }

    // Send the attribution event
    analytics.track("attribution_data", eventProperties);

    // Mark that attribution has been tracked
    attributionTracked = true;
    
    // Clean URL parameters after tracking
    cleanUrlParameters();
  };

  const initAttributionTracking = () => {
    // Add a session marker to avoid duplicate attribution in the same session
    const SESSION_MARKER_KEY = "attribution_tracked_session";
    const currentSessionId = sessionStorage.getItem(SESSION_MARKER_KEY);
    
    // Process URL parameters properly using URLSearchParams
    const urlParams = new URLSearchParams(window.location.search);
    
    // Check for any tracking parameters
    const hasAttributionParams = 
      // Check for UTM parameters
      Array.from(urlParams.keys()).some(key => key.startsWith("utm_")) ||
      // Check for ad platform parameters
      urlParams.has("gclid") ||
      urlParams.has("msclkid") ||
      urlParams.has("li_fat_id") ||
      urlParams.has("fbclid") ||
      urlParams.has("dclid") ||
      // Check for marketing platform parameters
      Array.from(urlParams.keys()).some(key => key.startsWith("hsa_")) ||
      urlParams.has("ef_id") ||
      // Check for custom parameters
      urlParams.has("sn") ||
      urlParams.has("pn") ||
      urlParams.has("lgs");
      
    // Debug code removed for production

    // Generate a unique ID for this session if we don't have one
    if (!currentSessionId) {
      const sessionId = Math.random().toString(36).substring(2, 15);
      sessionStorage.setItem(SESSION_MARKER_KEY, sessionId);
      
      if (hasAttributionParams) {
        // Only track URL parameters on the first pageview of a session
        trackAttributionOnce("url_params");
        return; // Exit early after tracking
      }

      // If no URL parameters, check if this is first visit
      const attributionData = getAttributionData(analytics as any);
      if (!attributionData.firstTouch) {
        trackAttributionOnce("first_visit");
      }
    } else {
      // For subsequent page views in the same session:
      // 1. We don't track attribution again (to avoid new direct visits)
      // 2. We still clean the URL if parameters are present
      if (hasAttributionParams) {
        cleanUrlParameters();
      }
    }
  };

  // Only run attribution tracking once Analytics is ready
  // Debug logging removed for production
  
  analytics.once("ready", initAttributionTracking);
};