export const SEARCH_COMPANY = "searchCompany";
export const COMPANY_LIST = "companyList";
export const COMPANY_DETAILS = "companyDetails";
export const COMPANY_SESSIONS = "companySessions";

export const SEGMENTS_LIST = "segmentsList";
export const SEGMENT_BY_ID = "segmentById";
export const SEGMENT_SEARCH = "segmentSearch";
export const EXPORT_FILE = "exportSearchedList";

export const HUBSPOT_AUTH_LINK = "hubspotAuthLink";
export const HUBSPOT_AUTH_CONFIRM = "hubspotAuthConfirm";
export const HUBSPOT_SETTINGS = "hubSpotSettings";

export const LEXWARE_AUTH_LINK = "lexWareAuthLink";
export const LEXWARE_AUTH_CONFIRM = "lexWareAuthConfirm";
export const LEXWARE_SETTINGS = "lexWareSettings";
