import axios, { AxiosRequestConfig } from "axios";

import { AxiosResponseSuccess } from "./types";

function createHttpClient() {
  const baseUrl = import.meta.env.VITE_APP_CMS || "";

  return axios.create({
    baseURL: baseUrl + '/api/',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const cmsHttpClient = createHttpClient();

export async function makeCmsHttpRequest<SuccessPayload>(
  config: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  const headersToSend: AxiosRequestConfig["headers"] = { ...config.headers };

  return cmsHttpClient.request<SuccessPayload>({
    ...config,
    headers: {
      ...headersToSend,
    },
  });
}
