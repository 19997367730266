import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { cn } from "@/lib/utils.ts"
import { Quote } from "@/assets/icons"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTQuote: FC<RTElementProps> = ({node}: RTElementProps) => {
  const paragraphClasses = [
    `before:content-['"']`,
    `after:content-['"']`,
    'mt-3 mb-6 italic text-xl font-bold'
  ]
  return (
    <blockquote className='mt-8'>
      <Quote />
      <p className={cn(paragraphClasses)}>
        <RTChildren children={node.children} />
      </p>
    </blockquote>
  )
}
