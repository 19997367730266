import { LEXWARE_SETTINGS } from "./../../../constants/queryKeys";
import { apiHandlers } from "@/api";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useLexWareSettings = (
  enabled: boolean,
): UseQueryResult<any, Error> => {
  return useQuery({
    queryKey: [LEXWARE_SETTINGS],
    queryFn: () => {
      return apiHandlers.integrations.lexWare.getSettings().then((res) => {
        return res.data;
      });
    },

    enabled,
    refetchOnWindowFocus: false,
  });
};
