import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Intercom from "@intercom/messenger-js-sdk";
import { verifyMe } from "@/helpers/verify-me.ts";
import { captureException } from "@sentry/react";
import Analytics from "@/analytics";
import { setupAttributionTracking } from "@/utils/attributionTracking";

const Layout = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  // Initialize Intercom
  useEffect(() => {
    try {
      const decodedToken = verifyMe();
      const intercomSettings = {
        app_id: "z2jxudhe",
      };

      if (decodedToken) {
        intercomSettings.user_id = decodedToken.account_id;
        intercomSettings.name = decodedToken.first_name;
        intercomSettings.email = decodedToken.email;
      }

      Intercom(intercomSettings);
    } catch (error) {
      captureException(error);
      console.error("Error initializing Intercom:", error);
    }
  }, []);

  // Set up the campaign event listener - this runs once on component mount
  useEffect(() => {
    // Use the shared attribution tracking module
    setupAttributionTracking();
  }, []);

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
    // Let Customer.io handle the automatic page tracking
  }, [pathname]);

  return (
    <div className="flex min-h-screen w-full flex-col overflow-y-auto overflow-x-hidden">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
