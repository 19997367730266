import { FC } from "react"
import { RTElementProps } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTTable: FC<RTElementProps> = ({ node }: RTElementProps) => {
  return (
    <table className='w-full mb-8 mt-8 text-left'>
      <tbody>
        <RTChildren children={node.children} />
      </tbody>
    </table>
  )
}
