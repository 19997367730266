import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { cmsHandlers } from "@/api/cmsHandlers.ts"
import { Page } from "@/types"

export const useGetPageQuery = (page: string): UseQueryResult<Page, Error> => {
  return useQuery<Page, Error>({
    queryKey: ['cms', 'page', page],
    queryFn: async () => {
      const response = await cmsHandlers.pages.get(page)
      return response.data.docs[0]
    },
  });
};

