import { SearchCompanyResponseData } from "@/api";
import { PAGINATION_LIMIT } from "@/constants";
import { useSegmentManagement, useSegmentMutations } from "@/hooks";
import {
  CompaniesFilterDate,
  ContextPagination,
  LocalCompaniesDetails,
  QueryGroup,
} from "@/types";
import { Segment } from "@/types/segment";
import { getDateRange } from "@/utils";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { CompanyDetailsContext } from "..";

export interface CompanyFilterContextProps {
  isOpenFilterDrawer: boolean;
  showFilterFieldsList: boolean;
  isOpenSaveModal: boolean;
  domain: string;
  filtersForm: UseFormReturn<FormValues, any, undefined> | undefined;
  fields: FieldArrayWithId<FormValues, "query", "id">[];
  selectedCompany: LocalCompaniesDetails | null;
  ids: {
    groupId: string;
    filterId: string;
  };
  isEditMode: boolean;
  segmentToEdit: Segment | null;
  setIds: Dispatch<
    SetStateAction<{
      groupId: string;
      filterId: string;
    }>
  >;
  setDomain: Dispatch<SetStateAction<string>>;
  handleSelectSegmentToEdit: (segment: Segment) => void;
  appendGroup: UseFieldArrayAppend<FormValues, "query">;
  setIsOpenFilterDrawer: Dispatch<SetStateAction<boolean>>;
  setShowFilterFieldsList: Dispatch<SetStateAction<boolean>>;
  setIsOpenSaveModal: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  setSelectedCompany: (company: LocalCompaniesDetails | null) => void;
  handleSubmit: (values: FormValues) => Promise<void>;
  fetchNextPage: (pagination: ContextPagination) => void;
  companyList: LocalCompaniesDetails[];
  pagination: {
    current: number;
    isLast: boolean;
    total: number;
  };
}

export const CompanyFilterContext = createContext<CompanyFilterContextProps>({
  isOpenFilterDrawer: false,
  showFilterFieldsList: false,
  isOpenSaveModal: false,
  filtersForm: undefined,
  fields: [],
  ids: { groupId: "", filterId: "" },
  selectedCompany: null,
  isEditMode: false,
  segmentToEdit: null,
  domain: "",
  companyList: [],
  setDomain: () => {},
  handleSelectSegmentToEdit: () => {},
  setIds: () => {},
  appendGroup: () => {},
  setIsOpenFilterDrawer: () => {},
  setShowFilterFieldsList: () => {},
  setIsOpenSaveModal: () => {},
  setSelectedCompany: () => {},
  handleClose: () => {},
  handleSubmit: async () => {},
  fetchNextPage: () => {},
  pagination: {
    current: 0,
    isLast: false,
    total: 0,
  },
});

type CompanyFilterProviderProps = {
  children: ReactNode;
};

type FormValues = {
  label: string;
  query: QueryGroup[];
  domain: string;
  period: CompaniesFilterDate;
};

export const CompanyFilterProvider = ({
  children,
}: CompanyFilterProviderProps) => {
  const { searchSegmentCompany } = useSegmentMutations();
  const { selectedCompany, setSelectedCompany } = useContext(
    CompanyDetailsContext,
  );

  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false);
  const [showFilterFieldsList, setShowFilterFieldsList] = useState(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [segmentToEdit, setSegmentToEdit] = useState<Segment | null>(null);
  const [domain, setDomain] = useState("");
  const [companyList, setCompanyList] = useState<LocalCompaniesDetails[]>([]);
  const [pagination, setPagination] = useState<ContextPagination>({
    current: 1,
    isLast: false,
    total: 0,
  });

  const [ids, setIds] = useState({
    groupId: "0",
    filterId: "0",
  });

  const { handleSaveSegment } = useSegmentManagement();

  const handleSelectSegmentToEdit = (segment: Segment) => {
    filtersForm.reset({
      label: segment.label || "",
      query: segment.query || [],
      domain,
      period: segment.period,
    });
    setSegmentToEdit(segment);
    setIsOpenFilterDrawer(true);
    setIsEditMode(true);
  };

  const filtersForm = useForm<FormValues>({
    defaultValues: {
      label: "",
      query: [],
      domain: domain,
      period: {},
    },
  });

  const { fields, append: appendGroup } = useFieldArray({
    control: filtersForm?.control,
    name: "query",
  });

  const period = filtersForm?.watch("period");
  const filters = filtersForm?.watch("query");
  const periodRange = period && getDateRange(period);

  const handleClose = () => {
    setIsOpenSaveModal(false);
    setIsOpenFilterDrawer(false);
    setSelectedCompany(null);
    setShowFilterFieldsList(false);
    setIds({
      groupId: "0",
      filterId: "0",
    });
    setIsEditMode(false);
    setSegmentToEdit(null);

    filtersForm.reset({
      label: "",
      query: [],
      domain: domain,
      period: {},
    });
  };

  const handleSubmit = async (values: FormValues) => {
    const hasDomain = values.domain;

    if (!hasDomain) {
      values.domain = domain;
    }

    await handleSaveSegment(values, isEditMode, segmentToEdit);
    handleClose();
  };

  const fetchNextPage = (pagination: ContextPagination) => {
    if (pagination.isLast) return;

    searchSegmentCompany(
      {
        query: filters || [],
        domain: domain || "",
        from_date: (periodRange?.from || "") as string,
        to_date: (periodRange?.to || "") as string,
        page_size: PAGINATION_LIMIT,
        page: pagination.current + 1,
      },
      {
        onSuccess: ({ data }: { data: SearchCompanyResponseData }) => {
          setCompanyList((prevList: LocalCompaniesDetails[]) => {
            return [...prevList, ...data.results];
          });
          setPagination((prev) => ({
            current: prev.current + 1,
            isLast: data.pagination.is_last,
            total: data.pagination.total,
          }));
        },
      },
    );
  };

  useEffect(() => {
    if (isOpenFilterDrawer) {
      setPagination({
        current: 0,
        isLast: false,
        total: 0,
      });

      fetchNextPage({
        current: 0,
        isLast: false,
        total: 0,
      });

      setCompanyList([]);
      setSelectedCompany(null);
    }
  }, [
    JSON.stringify(filters ?? [[]]),
    JSON.stringify(period),
    domain,
    isOpenFilterDrawer,
    isEditMode,
  ]);

  return (
    <CompanyFilterContext.Provider
      value={{
        isOpenFilterDrawer,
        showFilterFieldsList,
        filtersForm,
        fields,
        ids,
        isOpenSaveModal,
        selectedCompany,
        isEditMode,
        segmentToEdit,
        domain,
        companyList,
        pagination,
        setDomain,
        handleSelectSegmentToEdit,
        setIds,
        appendGroup,
        setIsOpenSaveModal,
        setIsOpenFilterDrawer,
        setShowFilterFieldsList,
        handleClose,
        handleSubmit,
        setSelectedCompany,
        fetchNextPage,
      }}
    >
      {children}
    </CompanyFilterContext.Provider>
  );
};
