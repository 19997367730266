import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useDropdownMenu = () => {
  const [openDrawer, setOpenDrawer] = useState<string | null>(null);
  const [openedDropdowns, setOpenDropdowns] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (key: string) => {
    setOpenDrawer((prev) => (prev === key ? null : key));
  };

  const toggleDropdown = (key: string) => {
    setOpenDropdowns((prev) =>
      prev.includes(key) ? prev.filter((el) => el !== key) : [...prev, key],
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleSpecialNavigation = (key: string) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete(key.toLowerCase());
    navigate(`${location.pathname}?${updatedParams.toString()}`, {
      replace: true,
    });

    setOpenDrawer(key);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("hubspot")) setOpenDrawer("hubspot");
    if (params.get("lexware")) setOpenDrawer("lexWare");
  }, [location]);

  return {
    openDrawer,
    openedDropdowns,
    toggleDrawer,
    toggleDropdown,
    handleLogout,
    handleSpecialNavigation,
  };
};
