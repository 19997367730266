import LogoBlack from "@/assets/ci/webmetic-logo-black.svg";
import LogoWhite from "@/assets/ci/webmetic-logo-white.svg";
import { PieIcon } from "@/assets/icons/PieIcon.tsx";
import { PlusIcon } from "@/assets/icons/PlusIcon.tsx";
import { IconButton } from "@/components/index.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { GERMAN_LABELS } from "@/constants/germanLabels.ts";
import { useCompaniesFilterView } from "@/hooks/index.ts";
import { useCompanyFilterContext } from "@/hooks/useCompanyFilterContext.ts";
import NotFoundPage from "@/pages/notFound/index.jsx";
import clsx from "clsx";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import DateFilterDialog from "./components/date-filter-dialog";
import { SegmentCard } from "./components/segment-card.tsx";
import SegmentsDrawer from "./components/segments-drawer/index.tsx";

function CompaniesFilterView() {
  const {
    user,
    periodList,
    params,
    segmentsList,
    dateRange,
    selectedItem,
    setDateRange,
    resetSelectedCompany,
    handleDeleteSegment,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
  } = useCompaniesFilterView();

  const domain = params.domain || "";

  const { setIsOpenFilterDrawer, handleSelectSegmentToEdit } =
    useCompanyFilterContext();

  useEffect(() => {
    document.documentElement.setAttribute("data-route", "dashboard");
    return () => document.documentElement.removeAttribute("data-route");
  }, []);

  if (!user?.website.includes(domain)) {
    return <NotFoundPage />;
  }

  return (
    <>
      <div className="hidden xl:flex">
        <div className="flex w-72 grow flex-col border-r bg-muted/40 dark:border-gray-600">
          <div className="sticky top-0 flex h-16 shrink-0 items-center border-b bg-background px-6 dark:border-gray-600 dark:bg-gray-800">
            <img
              src={LogoBlack}
              className="mr-3 mt-1 h-7 dark:hidden sm:h-9"
              alt="Webmetic Logo"
            />
            <img
              src={LogoWhite}
              className="mr-3 mt-1 hidden h-7 dark:block sm:h-9"
              alt="Webmetic Logo"
            />
          </div>
          {!!user?.website.length && (
            <div className="sticky top-16 border-b bg-background p-2 dark:border-gray-600 dark:bg-gray-900">
              {/* <div className="h-[2px]"></div> */}
              <Select
                value={params.domain}
                onValueChange={resetSelectedCompany}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Domain" />
                </SelectTrigger>
                <SelectContent className="dark:border-gray-600 dark:bg-gray-700">
                  {user.website.map((v) => (
                    <SelectItem
                      key={v}
                      value={v}
                      className="dark:text-white dark:hover:bg-gray-500 dark:focus:bg-gray-500"
                    >
                      {v}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {/* Period list */}
          <nav className="flex flex-1 flex-col overflow-y-auto bg-white px-6 dark:bg-gray-900">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {periodList.map((item) =>
                    Number(item.label) && !item.companies ? null : (
                      <li key={item.label}>
                        <button
                          type="button"
                          onClick={handleSelectDateRangeOption(item)}
                          className={clsx(
                            "group flex w-full justify-between gap-x-3 rounded-md px-2 py-1 text-sm font-semibold leading-6 transition-all",
                            selectedItem?.label === item.label
                              ? "bg-muted text-primary hover:text-primary dark:bg-gray-700 dark:text-white"
                              : "text-muted-foreground hover:text-primary dark:hover:text-white",
                          )}
                        >
                          <span className="truncate capitalize">
                            {GERMAN_LABELS[item.label] || item.label}
                          </span>
                          <span
                            className={clsx(
                              "inline-flex min-h-6 min-w-6 shrink-0 items-center justify-center rounded-lg border px-2 py-1 text-xs font-medium text-muted-foreground group-hover:text-primary dark:border-gray-600 dark:group-hover:text-white",
                              selectedItem?.label === item.label &&
                                "dark:border-white dark:text-white",
                            )}
                          >
                            {item.companies}
                          </span>
                        </button>
                      </li>
                    ),
                  )}

                  <li className="px-2">
                    <DateFilterDialog
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      onSubmit={handleUpdateSearchParams}
                      trigger={
                        <button
                          type="button"
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border px-[4px] py-2 text-sm font-semibold leading-6 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white",
                          )}
                        >
                          <span className="capitalize">Zeitraum festlegen</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Datum manuell auswählen
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>
              </li>
              <li>
                <div className="flex flex-col gap-2 pb-2 pt-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1">
                      <PieIcon />
                      <span className="dark:text-[rgb(167 229 128)] font-semibold leading-6 text-green-900 dark:text-[#a7e580]">
                        Segmente
                      </span>
                    </div>
                    <div className="relative flex items-center gap-[6px]">
                      <div className="group">
                        <IconButton
                          onClick={() => setIsOpenFilterDrawer(true)}
                          icon={<PlusIcon />}
                        />

                        <div
                          role="tooltip"
                          className="duration-600 invisible absolute right-[-40px] top-[-45px] z-10 mt-2 w-[60px] -translate-x-1/2 transform rounded-lg bg-gray-900 px-2 py-1 text-center text-[9px] font-medium leading-3 text-white opacity-0 transition-opacity delay-500 group-hover:visible group-hover:opacity-100 dark:bg-gray-700"
                        >
                          Segment erstellen
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    {segmentsList?.map((segment) => (
                      <SegmentCard
                        key={segment.id}
                        segment={segment}
                        onDelete={handleDeleteSegment}
                        handleSetEditableSegment={handleSelectSegmentToEdit}
                      />
                    ))}
                  </div>
                </div>

                <ul role="list" className="-mx-2 my-2 space-y-1">
                  <li>
                    <SegmentsDrawer
                      trigger={
                        !segmentsList?.length && (
                          <button
                            type="button"
                            onClick={() => setIsOpenFilterDrawer(true)}
                            className={clsx(
                              "text-muted-foreground hover:text-primary",
                              "group flex w-full flex-col gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )}
                          >
                            <span className="btn">
                              Neues Segment hinzufügen.
                            </span>
                          </button>
                        )
                      }
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CompaniesFilterView;
