import { LinkCompanyHubSpotData, apiHandlers } from "@/api";
import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface useLinkCompanyMutationsProps {
  linkCompanyLexWareMutation: UseMutateAsyncFunction<
    AxiosResponse<unknown, any>,
    Error,
    LinkCompanyHubSpotData,
    unknown
  >;
  isPending: boolean;
  isError: boolean;
}

export const useLinkCompanyLexwareMutation =
  (): useLinkCompanyMutationsProps => {
    const {
      mutateAsync: linkCompanyLexWareMutation,
      isPending,
      isError,
    } = useMutation({
      mutationFn: apiHandlers.integrations.lexWare.syncCompany,
    });

    return {
      linkCompanyLexWareMutation,
      isPending,
      isError,
    };
  };
