import { Button, CustomFlowbiteTheme, Navbar } from "flowbite-react";
import LogoBlack from "@/assets/ci/webmetic-logo-black.svg";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import analytics from "@/analytics";

const buttonTheme: CustomFlowbiteTheme["button"] = {
  color: {
    indigo1:
      "border border-transparent bg-indigo-50 text-indigo-500 enabled:hover:bg-indigo-100",
    indigo2:
      "border border-transparent bg-indigo-500 text-white enabled:hover:bg-indigo-600",
    mint1:
      "border border-transparent bg-energetic-mint-500 text-black enabled:hover:bg-energetic-mint-400",
    mint2:
      "border-2 border-energetic-mint-500 bg-white text-black enabled:hover:bg-platin",
  },
  pill: {
    off: "rounded-md",
  },
};

const navbarTheme: CustomFlowbiteTheme["navbar"] = {
  root: {
    base: "bg-white py-2.5",
    inner: {
      base: "flex flex-wrap items-center justify-between",
    },
  },
};

export const SLPHeader = () => {
  const navigate = useNavigate();

  // Track register button click
  const trackRegisterClicked = useCallback(() => {
    analytics.track("smartpage_register_clicked", {
      source: "smartpage_header",
    });
  }, []);

  // Track demo button click
  const trackDemoClicked = useCallback(() => {
    analytics.track("smartpage_demo_clicked", {
      source: "smartpage_header",
    });
  }, []);

  // Handle register button click with tracking
  const handleRegisterClick = useCallback(() => {
    trackRegisterClicked();
    navigate("/register");
  }, [trackRegisterClicked, navigate]);

  // Handle demo button click with tracking
  const handleDemoClick = useCallback(() => {
    trackDemoClicked();
  }, [trackDemoClicked]);

  return (
    <Navbar fluid theme={navbarTheme} className="mx-auto w-full max-w-[70rem]">
      <Navbar.Brand href="/">
        <img src={LogoBlack} className="mr-3 h-6 sm:h-8" alt="Webmetic Logo" />
      </Navbar.Brand>
      <div className="flex gap-4 md:order-2">
        <Button color="mint2" theme={buttonTheme} onClick={handleRegisterClick}>
          Registrieren
        </Button>
        <a
          href="https://calendly.com/webmetic/kurze-besprechung"
          target="_blank"
          onClick={handleDemoClick}
        >
          <Button color="mint1" theme={buttonTheme}>
            Demo vereinbaren
          </Button>
        </a>
      </div>
      <Navbar.Toggle />
    </Navbar>
  );
};
