import { apiHandlers } from "@/api";
import { HUBSPOT_AUTH_LINK } from "@/constants";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useGetHubspotAuthLinkQuery = (
  isAuthenticated: boolean,
): UseQueryResult<any> => {
  return useQuery<any>({
    queryKey: [HUBSPOT_AUTH_LINK],
    queryFn: isAuthenticated
      ? async () => {
          const response = await apiHandlers.integrations.hubspot.getAuthUrl();
          return response.data;
        }
      : () => {},
  });
};
