export const SendIcon = () => {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.5V0.5L15.423 7L0 13.5ZM1 12L12.85 7L1 2V5.8845L5.84625 7L1 8.1155V12Z"
        fill="black"
      />
    </svg>
  )
}
