import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Layout from "../components/Layout";
import analytics from "@/analytics";

const AppointmentConfirmation = () => {
  const [appointmentData, setAppointmentData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventTypeName = params.get("event_type_name");
    const eventStartTime = params.get("event_start_time");
    const inviteeFullName = params.get("invitee_full_name");

    if (eventTypeName && eventStartTime && inviteeFullName) {
      const appointmentInfo = {
        eventTypeName,
        eventStartTime,
        inviteeFullName,
      };
      setAppointmentData(appointmentInfo);

      // Track the appointment confirmation
      analytics.track("appointment_confirmed", {
        source: "confirmation_page",
        event_name: eventTypeName,
        name: inviteeFullName,
      });
    }
  }, [location]);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("de-DE", options);
  };

  return (
    <Layout>
      <div className="flex min-h-screen items-start justify-center pt-20">
        <div className="w-full max-w-md rounded-lg bg-white px-4 py-8 shadow-lg dark:bg-gray-800">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-green-900 sm:h-16 sm:w-16">
            <svg
              className="h-8 w-8 text-green-700 dark:text-green-300 sm:h-12 sm:w-12"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 11.917 9.724 16.5 19 7.5"
              />
            </svg>
          </div>
          <h2 className="my-4 text-center text-xl font-semibold text-gray-900 dark:text-white">
            {appointmentData
              ? "Ihr Termin ist bestätigt!"
              : "Terminbestätigung"}
          </h2>
          {appointmentData ? (
            <div className="space-y-4 text-center">
              <p className="text-gray-600 dark:text-gray-400">
                <span className="font-semibold">Termin:</span>{" "}
                {appointmentData.eventTypeName} <br />
                <span className="font-semibold">Startzeit:</span>{" "}
                {formatDate(appointmentData.eventStartTime)}
                <br />
                <span className="font-semibold">Name:</span>{" "}
                {appointmentData.inviteeFullName}
              </p>
            </div>
          ) : (
            <p className="text-center text-gray-500 dark:text-gray-400">
              Hiermit bestätigen wir Ihnen den Temrin.
            </p>
          )}
          <div className="mt-4 items-center justify-center space-x-0 space-y-4 border-t border-gray-200 pt-4 dark:border-gray-700 sm:flex sm:space-x-4 sm:space-y-0 md:mt-5 md:pt-5">
            <Link to="/" className="btn">
              Zur Website
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AppointmentConfirmation;
