import { FC } from "react"
import { RTElementProps, RTLinkFields } from "@/components/content/RichText/types.ts"
import { RTChildren } from "@/components/content/RichText/elements/RTChildren.tsx"

export const RTLink: FC<RTElementProps> = ({node}: RTElementProps) => {
  const linkProps = node.fields as RTLinkFields
  const target = linkProps.newTab ? '_blank' : '_self'
  return (
    <a href={linkProps.url} target={target} className="text-blue-500">
      <RTChildren children={node.children} />
    </a>
  )
}
