import { Button, CustomFlowbiteTheme, Dropdown } from "flowbite-react";
import { ChevronSort } from "@/assets/icons";
import { Visitor, visitors } from "@/pages/smartLandingPage/visitors";

const dropdownTheme: CustomFlowbiteTheme["dropdown"] = {
  arrowIcon: "hidden",
  floating: {
    item: {
      base: "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-white hover:bg-carbon focus:bg-carbon focus:outline-none",
    },
    style: {
      light: "border border-white bg-graphite text-white",
      auto: "border border-white bg-graphite text-white dark:border-none dark:bg-gray-700 dark:text-white",
    },
  },
};

interface HeaderProps {
  selectedVisitor: Visitor;
  onSelectVisitor: (visitor: Visitor) => void;
}

export const SLPVisitorHeader = ({
  selectedVisitor,
  onSelectVisitor,
}: HeaderProps) => {
  return (
    <header className="w-full bg-graphite">
      <nav className="mx-auto max-w-[70rem] py-2.5">
        <div className="mx-auto flex flex-wrap items-center justify-between text-white">
          <p className="text-sm font-bold">
            Webmetic Live-Demo: KI-gestützte Personalisierung in Aktion
          </p>
          <div className="flex items-center gap-5">
            <p className="text-xs">Unternehmensperspektive wählen:</p>
            <Dropdown
              theme={dropdownTheme}
              renderTrigger={() => (
                <Button className="rounded border-white bg-transparent text-white focus:ring-0">
                  <div className="flex items-center gap-4">
                    {selectedVisitor.label}
                    <ChevronSort />
                  </div>
                </Button>
              )}
            >
              {visitors.map((visitor, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => onSelectVisitor(visitor)}
                >
                  {visitor.label}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
      </nav>
    </header>
  );
};
