import React from "react";

export const LexWareWelcome = ({ handleClick }) => {
  return (
    <>
      <div className="border-b p-4 dark:border-b-gray-600">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Lexware-Verbindung
        </h2>
        <p className="text-gray-500 dark:text-gray-400">
          Verbinden Sie Lexware mit dieser Integration ganz einfach mit Ihrem
          Webmetic-Konto.
        </p>
      </div>
      <div className="border-b p-4 dark:border-b-gray-600">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Wie funktioniert es
        </h2>
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          Nach der Verbindung mit Lexware wird auf der Unternehmensprofilseite
          eine Schaltfläche angezeigt, über die Sie das Unternehmen mit Lexware
          verknüpfen können. Es gibt zwei Möglichkeiten:
        </p>
        <ul className="list-disc px-6 text-gray-500 dark:text-gray-400">
          <li>Link zu einem bestehenden Unternehmen in Lexware. </li>
          <li>Erstellen Sie in Lexware ein neues Unternehmen.</li>
        </ul>
      </div>
      <div className="p-4">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Website-Besuche synchronisieren
        </h2>
        <p className="text-gray-500 dark:text-gray-400">
          Sobald ein Unternehmen verknüpft ist, werden die Website-Besuche
          automatisch als Notizen mit Lexware synchronisiert. Auf diese Weise
          haben Sie auch Einblicke in Lexware, wenn jemand Ihre Website besucht
          hat.
        </p>
      </div>
      <div className="flex justify-center p-4">
        <button className="btn" type="submit" onClick={(e) => handleClick(e)}>
          Mit Lexware verbinden
        </button>
      </div>
    </>
  );
};
