import { SLPHeader } from "@/pages/smartLandingPage/SLPHeader.tsx";
import { Hero } from "@/pages/smartLandingPage/webmetic/Hero";
import { Customers } from "@/pages/smartLandingPage/webmetic/Customers";
import { Features } from "@/pages/smartLandingPage/webmetic/Features";
import { Stats } from "@/pages/smartLandingPage/webmetic/Stats";
import { HowItWorks } from "@/pages/smartLandingPage/webmetic/HowItWorks";
import { Benefits } from "@/pages/smartLandingPage/webmetic/Benefits";
import { CTA } from "@/pages/smartLandingPage/webmetic/CTA";
import { Testimonials } from "@/pages/smartLandingPage/webmetic/Testimonials";
import { FAQ } from "@/pages/smartLandingPage/webmetic/FAQ";
import { Contact } from "@/pages/smartLandingPage/Contact";
import Footer from "@/pages/components/Footer";
import { useRef } from "react";
import { DLPProps } from "@/pages/smartLandingPage/types";
import { useActiveSection } from "@/pages/smartLandingPage/useSetActiveSection";

export const SLPWebmetic = ({ scrolledToSection }: DLPProps) => {
  const heroRef = useRef(null);
  const customersRef = useRef(null);
  const featuresRef = useRef(null);
  const statsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const benefitsRef = useRef(null);
  const ctaRef = useRef(null);
  const testimonialsRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);
  const sections = [
    heroRef,
    customersRef,
    featuresRef,
    statsRef,
    howItWorksRef,
    benefitsRef,
    ctaRef,
    testimonialsRef,
    faqRef,
    contactRef,
  ];

  useActiveSection(sections, scrolledToSection);

  return (
    <>
      <SLPHeader />
      <Hero ref={heroRef} />
      <Customers ref={customersRef} />
      <Features ref={featuresRef} />
      <Stats ref={statsRef} />
      <HowItWorks ref={howItWorksRef} />
      <Benefits ref={benefitsRef} />
      <CTA ref={ctaRef} />
      <Testimonials ref={testimonialsRef} />
      <FAQ ref={faqRef} />
      <Contact ref={contactRef} />
      <Footer />
    </>
  );
};
