import { SLPVisitorHeader } from "./SLPVisitorHeader.tsx";
import { SLPWebmetic } from "./SLPWebmetic.tsx";
import { SLPDrawer } from "@/pages/smartLandingPage/SLPDrawer.tsx";
import { FC, useEffect, useRef, useState } from "react";
import { Visitor, visitors } from "@/pages/smartLandingPage/visitors";
import { SLPMesseMuenchen } from "@/pages/smartLandingPage/SLPMesseMuenchen.tsx";
import { DLPProps } from "@/pages/smartLandingPage/types";
import { SLPWuerth } from "@/pages/smartLandingPage/SLPWuerth.tsx";
import { SLPFreelance } from "@/pages/smartLandingPage/SLPFreelance.tsx";
import WelcomeModal from "@/pages/smartLandingPage/WelcomeModal";
import { setupAttributionTracking } from "@/utils/attributionTracking";

const visitorPages: { [k: string]: FC<DLPProps> } = {
  webmetic: SLPWebmetic,
  "messe-muenchen": SLPMesseMuenchen,
  wuerth: SLPWuerth,
  freelance: SLPFreelance,
  kuka: SLPWebmetic,
  shopware: SLPWebmetic,
};

export const SmartLandingPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [visitor, setVisitor] = useState<Visitor>(visitors[0]);
  const [activeSection, setActiveSection] = useState<null | string>(null);
  const [personalizationClosed, setPersonalizationClosed] = useState(true);
  const Page = visitorPages[visitor.value];

  const [hasScrolled, setHasScrolled] = useState(false);
  
  // Track attribution and clean URL parameters when component mounts
  useEffect(() => {
    // Use the shared attribution tracking module
    setupAttributionTracking();
  }, []);

  useEffect(() => {
    if (!personalizationClosed) return;
    setHasScrolled(false);
    const element = scrollRef.current;
    if (!element) return;
    const handleScroll = () => {
      setHasScrolled(true);
      setPersonalizationClosed(false);
      element.removeEventListener("scroll", handleScroll); // only once
    };

    element.addEventListener("scroll", handleScroll, { passive: true });

    return () => element.removeEventListener("scroll", handleScroll);
  }, [visitor, personalizationClosed]);

  return (
    <div
      className="flex min-h-screen w-full flex-col overflow-y-auto overflow-x-hidden bg-white"
      ref={scrollRef}
    >
      <div className="flex w-full">
        <div className="mr-[21rem] flex-1 flex-col">
          <SLPVisitorHeader
            selectedVisitor={visitor}
            onSelectVisitor={setVisitor}
          />
          <Page scrolledToSection={setActiveSection} />
        </div>
        <SLPDrawer
          visitor={visitor}
          activeSection={activeSection}
          openPersonalization={hasScrolled}
          closedPersonalization={setPersonalizationClosed}
        />
      </div>
      <WelcomeModal />
    </div>
  );
};
