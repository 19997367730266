import {
  AdsClick,
  CurrencyExchange,
  AccountCircle,
  SignalCellularAlt,
} from "@/pages/smartLandingPage/webmetic/benefit_icons";
import { ArrowRight } from "@/assets/icons";
import { forwardRef } from "react";

const benefits = [
  {
    icon: AdsClick,
    title: "Ziehen Sie die richtigen Fachbesucher an",
    description:
      "Erreichen Sie Unternehmen und Branchenexperten, die sich für Ihre Messe interessieren – mit Inhalten, die auf ihre Bedürfnisse zugeschnitten sind.",
    result: "Mehr qualifizierte Leads & Registrierungen",
  },
  {
    icon: CurrencyExchange,
    title: "Maximieren Sie Ticketverkäufe",
    description:
      "Zeigen Sie Besuchern exakt die Angebote, die für sie relevant sind – basierend auf Branche, Standort und Interessen.",
    result: "Höhere Conversion-Rates & mehr Ticketbuchungen",
  },
  {
    icon: SignalCellularAlt,
    title: "Gewinnen Sie mehr Aussteller",
    description:
      "Präsentieren Sie gezielt Unternehmen, die von Ihrer Messe profitieren – mit individualisierten Inhalten und Angeboten.",
    result: "Mehr Anfragen & Buchungen für Standflächen",
  },
  {
    icon: AccountCircle,
    title: "Nutzen Sie wertvolle Besucherdaten",
    description:
      "Verstehen Sie, welche Inhalte wirklich funktionieren – durch dynamische Anpassung und Echtzeit-Analysen.",
    result: "Datengestützte Optimierung für nachhaltigen Erfolg",
  },
];
export const Benefits = forwardRef<HTMLElement | null>((_, ref) => {
  return (
    <section id="benefits" className="bg-pulse text-white" ref={ref}>
      <div className="mx-auto w-full max-w-[70rem] py-20 text-left sm:py-16">
        <p id="benefits-intro" className="mb-6 text-lg text-energetic-mint-500">
          IHRE VORTEILE
        </p>
        <h2
          id="benefits-title"
          className="mb-6 max-w-3xl text-4xl font-semibold tracking-tight text-white"
        >
          Messe München 2.0 - mit personalisierter Customer Journey für jeden
          Besucher
        </h2>
        <p id="benefits-text" className="mb-12">
          Vom ersten Klick bis zum Ticket oder der Aussteller-Anmeldung: Die
          dynamischen Landing Pages holen Interessenten genau am richtigen Punkt
          ab und sorgen für Engagement.
        </p>
        <div
          id="benefits-list"
          className="mb-6 md:grid md:grid-cols-2 md:gap-5 lg:flex lg:flex-nowrap"
        >
          {benefits.map((benefit, index) => (
            <div
              className="flex flex-col gap-5 rounded-md bg-graphite p-8 text-left text-white lg:min-w-[27%]"
              key={index}
              id={`benefit${index + 1}`}
            >
              <benefit.icon></benefit.icon>
              <h3 className="text-xl font-semibold">{benefit.title}</h3>
              <p className="pr-4 text-sm font-normal">{benefit.description}</p>
              <p className="text-sm font-bold">
                <ArrowRight className="ml-0 mr-1 inline-block h-4 w-4" />
                {benefit.result}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});
