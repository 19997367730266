import heroImage from "./images/hero.png";
import { Link } from "react-router-dom";
import { forwardRef, useCallback } from "react";
import analytics from "@/analytics";

// Flowbite block https://flowbite.com/blocks/marketing/hero/#visual-image-with-heading

export const Hero = forwardRef<HTMLElement | null>((_, ref) => {
  // Track demo button click
  const trackDemoClicked = useCallback(() => {
    analytics.track("smartpage_demo_clicked", {
      source: "wuerth_hero",
    });
  }, []);

  // Track features button click
  const trackFeaturesClicked = useCallback(() => {
    analytics.track("smartpage_features_clicked", {
      source: "wuerth_hero",
    });
  }, []);

  return (
    <section className="mx-auto w-full max-w-[70rem]" id="hero" ref={ref}>
      <div className="mx-auto grid max-w-screen-xl py-8 lg:grid-cols-12 lg:gap-8 lg:py-16 xl:gap-0">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1
            className="mb-6 max-w-2xl text-[2.75rem] font-semibold leading-none tracking-tight text-neutral-900"
            id="hero-title"
          >
            Mehr Leads, mehr Umsätze - dank smarter, KI-gestützter Landing Pages
          </h1>
          <p
            className="mb-6 max-w-2xl font-normal text-neutral-700 md:text-lg lg:mb-8"
            id="hero-text1"
          >
            Begeistern Sie Ihre Kunden mit individualisierter Ansprache und ganz
            auf sie zugeschnittenen Inhalten.
          </p>
          <p
            className="mb-6 max-w-2xl font-normal text-neutral-700 md:text-lg lg:mb-8"
            id="hero-text2"
          >
            Dank personalisierter, smarter Landing Pages{" "}
            <strong>
              präsentieren Sie Ihre Produkte gezielt für jede Branche – von
              Elektrohandwerk über Maschinenbau bis hin zu Bauunternehmen.
            </strong>{" "}
            So erreichen Sie die richtigen Entscheider zur richtigen Zeit und
            steigern Ihren Umsatz.
          </p>
          <a
            href="https://calendly.com/webmetic/kurze-besprechung"
            target="_blank"
            className="mr-3 inline-flex items-center justify-center rounded-md bg-energetic-mint-500 px-5 py-3 text-center text-base font-medium text-black hover:bg-energetic-mint-400"
            onClick={trackDemoClicked}
          >
            Demo vereinbaren
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <Link
            to="/funktionen"
            className="inline-flex items-center justify-center rounded-md border-2 border-energetic-mint-500 bg-white px-5 py-3 text-center text-base font-medium text-black hover:bg-platin"
            onClick={(e) => {
              e.preventDefault();
              window.open("/funktionen", "_blank", "noopener,noreferrer");
              trackFeaturesClicked();
            }}
          >
            Wie funktioniert Webmetic?
          </Link>
        </div>
        <div className="hidden pl-8 lg:col-span-5 lg:mt-0 lg:flex">
          <img src={heroImage} alt="mockup" id="hero-image" />
        </div>
      </div>
    </section>
  );
});
