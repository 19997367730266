import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

interface ToLink {
  pathname: string;
  search: string;
}

interface CompanyListItemWrapperProps {
  to?: ToLink;
  onClick?: () => void;
  isActive?: boolean;
  children: ReactNode;
}
export const CompanyListItemWrapper = ({
  to,
  onClick,
  isActive,
  children,
}: CompanyListItemWrapperProps) => {
  if (to && !onClick) {
    return (
      <NavLink
        to={to}
        className={({ isActive }) =>
          clsx("group relative", { "is-active": isActive })
        }
      >
        {children}
      </NavLink>
    );
  }

  if (onClick) {
    return (
      <div
        onClick={onClick}
        role="button"
        className={clsx("group relative cursor-pointer", {
          "is-active": isActive,
        })}
      >
        {children}
      </div>
    );
  }

  return <>{children}</>;
};
