import { apiHandlers } from "@/api";
import { HubSpotAuthParams, HubSpotAuthResponse } from "@/api/types";
import { HUBSPOT_AUTH_CONFIRM } from "@/constants";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useConfirmHubspotAuthQuery = (
  params: HubSpotAuthParams,
): UseQueryResult<HubSpotAuthResponse> => {
  return useQuery<HubSpotAuthResponse>({
    queryKey: [HUBSPOT_AUTH_CONFIRM, params.code, params.error],
    queryFn: async () => {
      const response =
        await apiHandlers.integrations.hubspot.confirmAuthorization(params);
      return response.data;
    },
    enabled: !!params,
  });
};
