import { RichTextNode, RTElementProps } from "./types.ts"
import {
  RTHeading,
  RTText,
  RTParagraph,
  RTLink,
  RTImage,
  RTList,
  RTListItem,
  RTQuote,
  RTLineBreak,
  RTTable,
  RTTableRow,
  RTTableCell,
  RTHorizontalRule,
  RTBlock,
} from "@/components/content/RichText/elements"
import { FC } from "react"

const elements: { [k: string]: FC<{node: RichTextNode}> } = {
  heading: RTHeading,
  text: RTText,
  paragraph: RTParagraph,
  link: RTLink,
  upload: RTImage,
  list: RTList,
  listitem: RTListItem,
  quote: RTQuote,
  linebreak: RTLineBreak,
  table: RTTable,
  tablerow: RTTableRow,
  tablecell: RTTableCell,
  horizontalrule: RTHorizontalRule,
  block: RTBlock,
}

export const RichTextElement = ({node, className}: RTElementProps) => {
  const Element: FC<RTElementProps> | null = elements[node.type] ?? null
  if (Element) {
    return <Element node={node} className={className} />
  }
  console.warn('UNKNOWN ELEMENT', node)

  return null
}
