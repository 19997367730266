import { PersonalizationsType } from "@/pages/smartLandingPage/drawer/types";

export const messeMuenchenPersonalizations: PersonalizationsType = {
  hero: [
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "USP → Internationalität/Netzwerk",
      ],
      old: "Mehr Umsätze - dank KI-gestützter smart Landing Pages",
      new: "Mit smarten, KI-gestützten Landing Pages zu mehr Ausstellern und Besuchern",
      element: "hero-title",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Interessensgebiete → Technologiemessen, Veranstaltungsplanung",
      ],
      old: "Ihre Besucher erwarten relevante Inhalte - und sehen doch immer dieselbe generische Seite.",
      new: "Ihre Website-Besucher sind so vielfältig wie Ihre Veranstaltungen - und sehen doch alle dieselbe generische Landing Page.",
      element: "hero-text1",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Interessensgebiete → Technologiemessen, Veranstaltungsplanung",
      ],
      old: "Personalisierte Landing Pages berücksichtigen Branche, Standort und Verhalten Ihrer Besucher.",
      new: "Mit personalisierten Landing Pages passen sich Inhalte dynamisch an Unternehmen und ihre Bedürfnisse und Interessen an – für mehr Anmeldungen, höhere Interaktionen und gesteigerte Ticketverkäufe.",
      element: "hero-text2",
      segment: "fachliche_ausrichtung",
    },
    {
      category: "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
      new: "Messestand mit Besuchern",
      element: "hero-image",
      segment: "images",
    },
  ],
  customers: [
    {
      category: ["Land → Deutschland", "Stadt → München"],
      old: "Our customers worldwide trust us",
      new: "Über 15 Messeveranstalter setzen bereits auf Webmetic",
      element: "customers-title",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category: "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
      new: "Messe-Logos (IFA, ITB, MWC, CES, etc.)",
      element: "customer-logos",
      segment: "images",
    },
  ],
  features: [
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
      ],
      old: "Wie statische Landing Pages Ihrem Unternehmen schaden",
      new: "Wie statische Landing Pages Ihr Wachstum bremsen",
      element: "features-title",
    },
    {
      category: [],
      old: "Generisch konvertiert nicht...",
      new: "Generischer Content konvertiert nicht...",
      element: "feature1",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      ],
      old: "Ihre Interessenten kommen aus unterschiedlichen Ländern und Branchen und haben ganz individuelle Bedürfnisse - die eine einzige Landing Page gar nicht abdecken kann.",
      new: "Ein großer Messeveranstalter sieht dieselbe Startseite wie ein regionaler Handwerksbetrieb – obwohl sie völlig unterschiedliche Interessen haben.",
      element: "feature1",
    },
    {
      category: [
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      ],
      old: "Resultat: Wenig Relevanz, hohe Absprungraten und verlorene Neukunden",
      new: "Resultat:\n\nFachbesucher finden keine relevanten Inhalte\nUnternehmen verpassen potenzielle Leads\nKürzere Verweildauer, weniger Conversions",
      element: "feature1",
    },
    {
      category: [],
      old: "Potenzial bleibt ungenutzt...",
      new: "Ressourcen werden nicht effizient genutzt...",
      element: "feature2",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "USP → Expertise/Wissen",
      ],
      old: "Jeder Klick kostet Ihr Unternehmen bares Geld, aber Ihre Conversion-Rates bleiben stets im niedrigen einstelligen Bereich.",
      new: "Werbekosten zahlen sich nicht aus, wenn die unterschiedlichsten Kampagnen alle zur selben generischen Landing Page führen.",
      element: "feature2",
    },
    {
      category: [
        "Schlüsselbegriffe → Weltleitmessen, Internationales Netzwerk",
      ],
      old: "Resultat: Ohne zielgerichtetes Messaging bleibt Ihr Marketing-ROI weit unter seinen Möglichkeiten",
      new: "Resultat:\n\nHöhere Werbekosten bei geringer Wirkung\nBesucher springen ab, weil Inhalte nicht auf sie zugeschnitten sind\nWertvolle Nutzerdaten bleiben ungenutzt",
      element: "feature2",
    },
    {
      category: [],
      old: "Die Konkurrenz freut sich...",
      new: "Geschäftspotenzial geht verloren...",
      element: "feature3",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "USP → Internationalität/Netzwerk",
      ],
      old: "Die personalisierte Web-Erfahrung wird zum Standard - wenn Sie stehen bleiben, zieht die Konkurrenz dynamisch an Ihnen vorbei.",
      new: "Die Vielfalt an Interessenten und Veranstaltungen kann ohne smartes Konzept mehr Herausforderung als Chance sein.",
      element: "feature3",
    },

    {
      category: [
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      ],
      old: "Resultat: Liefern Sie Nutzern keine maßgeschneiderten Inhalte, verlieren Sie Ihre potenziellen Kunden",
      new: "Resultat:\n\nKäufer kämpfen sich durch irrelevante Inhalte → weniger Verkäufe\nAussteller fühlen sich nicht angesprochen → weniger Buchungen\nKürzere Verweildauer, weniger Conversions",
      element: "feature3",
    },
  ],
  stats: [],
  "how-it-works": [
    {
      category: ["Firmenname → Messe München", "Interaktionsrate → hoch"],
      old: "Gleich ausprobieren",
      new: "Jetzt für Messe München ausprobieren",
      element: "how-it-works-button",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Analysieren",
      new: "Besucherkontext erkennen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Webmetic identifiziert Branche und Standort der Firma und wertet das Nutzerverhalten in Echtzeit aus",
      new: "Automatische Analyse von Branche, Standort und Nutzerverhalten in Echtzeit",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Anpassen",
      new: "Inhalte dynamisch anpassen",
      element: "how-it-works-steps",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Je nach Besucher ändern sich Headlines, CTAs und beliebige weitere Inhalte automatisch",
      new: "Überschriften, Call-to-Actions und Testimonials ändern sich je nach Besuchertyp",
      element: "how-it-works-step",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Akquirieren",
      new: "Relevante Erlebnisse ausspielen",
      element: "how-it-works-step",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Nutzer-Präferenzen → DE, Dark Mode",
      ],
      old: "Mehr Relevanz führt zu mehr Engagement bei Ihren potenziellen Neukunden",
      new: "Mehr Engagement durch Inhalte, die perfekt auf den Besucher zugeschnitten sind",
      element: "how-it-works-step",
    },
  ],
  benefits: [
    {
      category: [
        "Firmenname → Messe München",
        "Konversionsphase → Überlegungsphase",
      ],
      old: "Verbessern Sie Ihre Website",
      new: "Messe München 2.0 - mit personalisierter Customer Journey für jeden Besucher",
      element: "benefits-title",
      segment: "lokalisierung_firmenidentitaet",
    },
    {
      category: [
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
        "Besuchte Unterseiten → Aussteller-Info",
      ],
      old: "Passender Content für jeden Besucher",
      new: "Ziehen Sie die richtigen Fachbesucher an",
      element: "benefit1",
      segment: "zielgruppen",
    },
    {
      category: ["USP → Expertise/Wissen", "Call-to-Actions → Mehr erfahren"],
      old: "Je nach Branche, Interesse und Standort erwarten den User maßgeschneiderte Inhalte.",
      new: "Erreichen Sie Unternehmen und Branchenexperten, die sich für Ihre Messe interessieren – mit Inhalten, die auf ihre Bedürfnisse zugeschnitten sind.",
      element: "benefit1",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Sekundäre Zielgruppe → Allgemeine Öffentlichkeit",
        "Besuchte Unterseiten → Veranstaltungen",
      ],
      old: "Gesteigerte Conversion-Rates",
      new: "Maximieren Sie Ticketverkäufe",
      element: "benefit2",
      segment: "zielgruppen",
    },
    {
      category: [
        "Sekundäre Zielgruppe → Allgemeine Öffentlichkeit",
        "Schlüsselbegriffe → Veranstaltungskalender",
      ],
      old: "CTAs, Angebote und Bilder passen sich automatisch den Nutzerbedürfnissen an und überzeugen durch Relevanz.",
      new: "Zeigen Sie Besuchern exakt die Angebote, die für sie relevant sind – basierend auf Branche, Standort und Interessen.",
      element: "benefit2",
    },
    {
      category: [
        "Sekundäre Zielgruppe → Allgemeine Öffentlichkeit",
        "Interessensgebiete → Technologiemessen",
      ],
      old: "Gesteigerte Anmeldungen und Conversion-Rates",
      new: "Höhere Conversion-Rates & mehr Ticketbuchungen",
      element: "benefit2",
    },
    {
      category: [
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
        "Letzte Interaktion → Formular-Anfrage",
      ],
      old: "Sinnvolles Retargeting und Upsells",
      new: "Gewinnen Sie mehr Aussteller",
      element: "benefit3",
      segment: "zielgruppen",
    },
    {
      category: [
        "USP → Internationalität/Netzwerk",
        "Schlüsselbegriffe → Weltleitmessen, Internationales Netzwerk",
      ],
      old: "Stärkere Kundenbindung & höhere Verkaufszahlen",
      new: "Mehr Anfragen & Buchungen für Standflächen",
      element: "benefit3",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "USP → Nachhaltigkeit/Ethik",
        "Tonalität → Vertrauenswürdig, Seriös",
      ],
      old: "Erfolgsversprechende Entscheidungen und nachhaltiges Wachstum",
      new: "Datengestützte Optimierung für nachhaltigen Erfolg",
      element: "benefit4",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "USP → Nachhaltigkeit/Ethik",
        "Nutzer-Präferenzen → DE, Dark Mode, Newsletter-Abonnent",
      ],
      old: "Lassen Sie das Nutzerverhalten in Echtzeit analysieren und den Inhalt Ihrer Landing Pages dafür perfektionieren.",
      new: "Verstehen Sie, welche Inhalte wirklich funktionieren – durch dynamische Anpassung und Echtzeit-Analysen.",
      element: "benefit4",
    },
  ],
  cta: [
    {
      category: [
        "Call-to-Actions → Mehr erfahren",
        "Nutzerstatus → wiederkehrend",
        "Konversionsphase → Überlegungsphase",
      ],
      old: "Personalisierung, von der Ihre Konkurrenz nur träumen kann",
      new: "Erleben Sie personalisierte Messe-Webseiten in Aktion",
      element: "cta-title",
      segment: "ctas_forms",
    },
    {
      category: [
        "Schlüsselbegriffe → Weltleitmessen, Internationales Netzwerk",
        "Interessensgebiete → Technologiemessen",
      ],
      old: "Branchenrelevante News",
      new: "Branchenbezogene Inhalte",
      element: "cta-feature1",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Call-to-Actions → Ihre Messeteilnahme",
        "Interessensgebiete → Technologiemessen, Veranstaltungsplanung",
      ],
      old: "Bessere Ergebnisse erzielen",
      new: "Jeder Besucher sieht relevante Inhalte, Fachartikel und Programmpunkte – passend zu seiner Branche.",
      element: "cta-feature1",
      segment: "ctas_forms",
    },
    {
      category: [
        "Besuchte Unterseiten → Aussteller-Info",
        "Interessensgebiete → Veranstaltungsplanung",
      ],
      old: "Verhaltensbasierte Anpassungen",
      new: "Verhaltensbasierte Anpassung",
      element: "cta-feature2",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Besuchte Unterseiten → Veranstaltungen",
        "Konversionsphase → Überlegungsphase",
      ],
      old: "Überschriften, CTAs und Bilder",
      new: "Dynamische Überschriften, CTAs und Bilder passen sich automatisch dem Interessenprofil an.",
      element: "cta-feature2",
      segment: "fachliche_ausrichtung",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      ],
      old: "Dynamischer Social Proof",
      new: "Dynamischer Social Proof",
      element: "cta-feature3",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
        "Schlüsselbegriffe → Weltleitmessen",
      ],
      old: "– Ihr Unternehmen glänzt mit Rezensionen, Studien und Erfolgsstorys",
      new: "Zeigen Sie Fachbesuchern Testimonials, Case Studies und Erfolgsgeschichten aus ihre Branche oder von ihrer direkten Konkurrenz.",
      element: "cta-feature3",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Schlüsselbegriffe → Innovation, Veranstaltungskalender",
        "Besuchte Unterseiten → Veranstaltungen, Locations",
      ],
      old: "KI-gestützte Empfehlungen",
      new: "KI-gestützte Vorschläge für relevante Messestände, Vorträge und Networking-Möglichkeiten.",
      element: "cta-feature4",
      segment: "fachliche_ausrichtung",
    },
    {
      category:
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      new: "Messehalle mit internationalen Besuchern",
      element: "cta-image",
      segment: "images",
    },
  ],
  testimonials: [
    {
      category: "Tonalität → Vertrauenswürdig, Seriös",
      new: "Professionelle Person im Messekontext",
      element: "testimonial-image",
      segment: "images",
    },
    {
      category: "Land → Deutschland",
      old: "Susan Wright, Referenzkunde",
      new: "Michael Huber, Head of Digital Marketing - Messe Deutschland",
      element: "testimonial-author",
      segment: "ctas_forms",
    },
    {
      category: [
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller",
      ],
      old: "Mit Webmetic konnten wir unsere Vertriebspipeline deutlich optimieren. Die Identifikation relevanter Unternehmen ermöglicht es uns, gezielter auf potenzielle Kunden zuzugehen und die Abschlussrate signifikant zu steigern.",
      new: "Mit Webmetic konnten wir die Ticketverkäufe und Anfragen für unsere Messen signifikant steigern. Durch personalisierte Landing Pages sehen Besucher jetzt genau die für sie relevanten Events und Aussteller. Unsere Conversion-Rate ist um 32% gestiegen!",
      element: "testimonials-testimonial",
      segment: "fachliche_ausrichtung",
    },
  ],
  faq: [],
  contact: [
    {
      category: "Firmenname → Messe München GmbH",
      new: "Messe München GmbH",
      element: "form-company",
      segment: "ctas_forms",
    },
    {
      category: "E-Mail → info@messe-muenchen.de",
      new: "@messe-muenchen.de",
      element: "form-email",
      segment: "ctas_forms",
    },
    {
      category: "Website → messe-muenchen.de",
      new: "https://messe-muenchen.de",
      element: "form-website",
      segment: "ctas_forms",
    },
  ],
  other: [
    {
      category: [
        "Sprach-Stil → Formal/professionell",
        "Primäre Zielgruppe → Internationale Fachbesucher und Aussteller aus verschiedenen Branchen",
      ],
      old: "Steigern Sie Ihre Website-Performance mit intelligenten Landing Pages",
      new: "Maximieren Sie Ihre Messeerfolge mit unserer Personalisierung und Unternehmensidentifikation.",
      segment: "kommunikation_werte",
    },
    {
      category: [
        "Tonalität → Autoritativ, Vertrauenswürdig",
        "Hauptbranche → Messe-, Ausstellungs- und Kongressveranstalter",
      ],
      old: "Wir helfen Ihnen, mehr aus Ihrer Website herauszuholen",
      new: "Steigern Sie Kundenbindung durch maßgeschneiderte Events und zielgerichtetes Marketing.",
      segment: "kommunikation_werte",
    },
    {
      category: ["Anrede → Formal"],
      old: "Buch dir deine Demo",
      new: "Sichern Sie sich Ihren Demo-Termin",
      segment: "kommunikation_werte",
    },
  ],
};
